import React from "react";

export default function PopupSuccess() {
  return (
    <div className="fixed w-full h-full bg-gray-900 z-50 bg-opacity-60 top-0 left-0 flex items-center justify-center">
      <div className="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto">
        <div className="w-full h-full text-center">
          <div className="flex h-full flex-col justify-between">
            <svg className="h-12 w-12 mt-4 m-auto text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
            <p className="text-gray-800 dark:text-gray-100 text-md py-2 px-6">
              Форма была успешна отправлена
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
