import { useMutation } from "@apollo/client"
import { Popover, Transition } from '@headlessui/react'
import React, { useEffect, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { connect } from "react-redux"
import { Redirect, withRouter } from "react-router"
import { GET_PROFILES, GET_STAGES, UPDATE_PROFILE_TO_BACK_STAGE, UPDATE_PROFILE_TO_NEXT_STAGE, UPDATE_STATUS_FOR_PROFILE } from "../../graphql/Queries"
import { commentInputClose, commentInputOpen, toggleLoader, toggleStatusDropdown } from "../../redux/manager/managerActions"
import { Portal } from '../../services/Portal'
import ReasonRowTwo from './ReasonRowTwo'

function StatusButtonsTwo({loading, data, refetch, statusId, sendTo1C, status, isMiniForm, ...props}) {

  // библиотека popper js для отображения дропдаунов
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
  })

  const commentRef = useRef("")
  const [nextStatus, setNextStatus] = useState()  
  const [previousStatuses, setPreviousStatuses] = useState()  
  const [subsequentStatuses, setSubsequentStatuses] = useState()  
  const [nextStage, setNextStage] = useState()  
  const [backStage, setBackStage] = useState()  
  const [currentStatusTitle, setCurrentStatusTitle] = useState()  
  const [redirect, setRedirect] = useState(false)  

  // проверяю наличие данных и записываю в локальный стейт
  useEffect(() => {
    if (data){
      if (data.Status.traffic_queue?.next_status) {
        setNextStatus(data.Status.traffic_queue.next_status)
      } else setNextStatus()

      if (data.Status.traffic_queue?.previous_statuses) {
        setPreviousStatuses(data.Status.traffic_queue.previous_statuses)
      } else setPreviousStatuses()

      if (data.Status.traffic_queue?.subsequent_statuses) {
      setSubsequentStatuses(data.Status.traffic_queue.subsequent_statuses)
      } else setSubsequentStatuses()

      if (data.Status.traffic_queue?.next_stage) {
        setNextStage(data.Status.traffic_queue.next_stage)
      } else setNextStage()
      if (data.Status.traffic_queue?.back_stage) {
        setBackStage(data.Status.traffic_queue.back_stage)
      } else setBackStage()

      if (data.Status?.title) {
        setCurrentStatusTitle(data.Status.title)
      } else setCurrentStatusTitle()
    }
  }, [data])

  // обновление кнопок при изменении статуса
  useEffect(() => {
    refetch()
  }, [statusId])

  const [updateStatusForProfiles, { loading: statusMutationLoading }] = useMutation(UPDATE_STATUS_FOR_PROFILE)
  const [updateProfilesToNextStage, { loading: stageMutationLoading }] = useMutation(UPDATE_PROFILE_TO_NEXT_STAGE)
  const [updateProfilesToBackStage, { loading: rejectMutationLoading }] = useMutation(UPDATE_PROFILE_TO_BACK_STAGE)

  // перевод анкет в следующий статус
  const nextStatusHandler = async () => {
    props.commentInputClose()
    props.toggleLoader()
    
    // перевод в отказ
    if (props.isReject){
      await updateProfilesToBackStage({ variables: {
          profilesId: props.match.params.id,
          companyId: +localStorage.getItem('company_id'),
          comment: commentRef.current.value
        },
        refetchQueries: [
          {query: GET_STAGES, variables: {companyId: +localStorage.getItem("company_id")}},
          {query: GET_PROFILES, variables: { companyId: +localStorage.getItem('company_id'), statusId}},
        ],
        awaitRefetchQueries: true
      })
      // редирект на главную страницу
      setRedirect(true)
      await props.refetchProfile()
    }
    if (status === "Требует проверки (З)" && !sendTo1C && !isMiniForm) {
      if (!window.confirm("Данные сотрудника не отправлены в 1с. Вы уверены, что хотите продолжить?")) {
        return;
      }
    }
    // перевод в другой этап
    if (props.selectedNextStage) {
      await updateProfilesToNextStage({ variables: {
        profilesId: props.match.params.id,
        companyId: localStorage.getItem('company_id'),
        comment: commentRef.current.value
      },
        refetchQueries: [
          {query: GET_STAGES, variables: {companyId: +localStorage.getItem("company_id")}},
          {query: GET_PROFILES, variables: { companyId: +localStorage.getItem('company_id'), statusId}},
        ],
        awaitRefetchQueries: true
      })
      await props.refetchProfile()
    // перевод в другой статус
    } else if (props.selectedNextStatus) {
      await updateStatusForProfiles({ variables: { 
          profilesId: props.match.params.id,
          companyId: localStorage.getItem('company_id'),
          newStatus: props.selectedNextStatus,
          comment: commentRef.current.value
        }, 
        refetchQueries: [
          {query: GET_STAGES, variables: {companyId: +localStorage.getItem("company_id")}},
          {query: GET_PROFILES, variables: { companyId: +localStorage.getItem('company_id'), statusId}},
        ],
        awaitRefetchQueries: true
      })
      await props.refetchProfile()
    }
  }

  return (
    <>
      { redirect && <Redirect to="/" /> }
      {(!loading && data && data.Status.traffic_queue) && (
        <div className="flex flex-col w-full p-4 rounded-lg items-center border bg-white shadow-xl my-4 z-20">
          {props.isCommentInputOpen 
            ? <ReasonRowTwo complete={nextStatusHandler} commentRef={commentRef} /> 
            : (
              <>
                {backStage && (
                  <button onClick={()=>props.commentInputOpen("Введите причину отказа", "", false, true)} type="button" className="w-full mb-2 py-2 px-4 flex justify-center items-center bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md rounded-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed">
                  {rejectMutationLoading
                      ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 16 16">
                          <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                          <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                        </svg>
                      )
                      : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      )}
                    {backStage.title}
                  </button>
                )}

                <div className="flex shadow-md rounded-lg relative w-full">
                  <button onClick={()=>props.commentInputOpen(`Причина перевода в "${nextStatus?.title ? nextStatus.title : nextStage.title}"`, 
                                                              nextStatus?.status_id ? nextStatus.status_id : nextStage.stage_id, 
                                                              nextStatus?.status_id ? (false) : (true))} 
                    type="button" disabled={(nextStage?.title === undefined) && (nextStatus?.title === undefined) && (previousStatuses?.length === 0)}
                    className="w-11/12 truncate px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold rounded-l-lg whitespace-nowrap border-r border-white disabled:opacity-50 disabled:cursor-not-allowed"  
                  >

                      {statusMutationLoading || stageMutationLoading
                        ? (
                          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                            <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                          </svg>
                        ) 
                        : (
                          <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        )
                      }
                      {nextStatus?.title ? nextStatus.title : nextStage?.title ? nextStage.title : "Некуда переводить"}

                  </button>
                  <Popover as={React.Fragment}>
                    <Popover.Button ref={setReferenceElement} disabled={(nextStage?.title === undefined) && (nextStatus?.title === undefined) && (previousStatuses?.length === 0)} className={`${!props.backStage && "md:w-28"} focus:outline-none py-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold rounded-r-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed`}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                      </svg>
                    </Popover.Button>
                    <Portal>
                      <Transition
                        enter="transition ease-out duration-100"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                        className="absolute z-50 transform"
                      >
                        <Popover.Panel
                          ref={setPopperElement}
                          style={styles.popper}
                          {...attributes.popper}
                        >
                          <div className="ml-2 w-56 rounded-md shadow-2xl bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                              {previousStatuses?.length > 0 && (
                                <>
                                  {previousStatuses.map(previousStatus => (
                                    <div key={previousStatus.status_id} onClick={()=>props.commentInputOpen(`Причина перевода в "${previousStatus.title}"`, previousStatus.status_id)} className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-pointer" role="menuitem">
                                      <span className="flex flex-col">
                                        <span>
                                          {previousStatus.title}
                                        </span>
                                      </span>
                                    </div>
                                  ))}
                                </>
                              )}
                              <div className="block px-4 py-2 text-md bg-gray-100 text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-not-allowed" disabled role="menuitem">
                                <span className="flex flex-col">
                                  <span>
                                    {currentStatusTitle}
                                  </span>
                                </span>
                              </div>
                              {nextStatus && (
                                <div onClick={()=>props.commentInputOpen(`Причина перевода в "${nextStatus.title}"`, nextStatus.status_id)} className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-pointer" role="menuitem">
                                  <span className="flex flex-col">
                                    <span>
                                      {nextStatus.title}
                                    </span>
                                  </span>
                                </div>
                              )}
                              {subsequentStatuses?.length > 0  && (
                                <>
                                  {subsequentStatuses.map(subsequentStatus => (
                                    <div key={subsequentStatus.status_id} onClick={()=>props.commentInputOpen(`Причина перевода в "${subsequentStatus.title}"`, subsequentStatus.status_id)} className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-pointer" role="menuitem">
                                      <span className="flex flex-col">
                                        <span>
                                          {subsequentStatus.title}
                                        </span>
                                      </span>
                                    </div>
                                  ))}
                                </>
                              )}
                              {nextStage && (
                              <>
                                <div onClick={()=>props.commentInputOpen(`Причина перевода в "${nextStage.title}"`, nextStage.stage_id, true)} className="border-t block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-pointer" role="menuitem">
                                  <span className="flex flex-col">
                                    <span>
                                      {nextStage.title}
                                    </span>
                                  </span>
                                </div>
                              </>
                            )}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Portal>
                  </Popover>
                </div>
              </>
            )
          }
        </div>
      )}
    </>
  )
}

const mapDispatchToProps = {
  commentInputOpen, toggleStatusDropdown, commentInputClose, toggleLoader
}
const mapStateToProps = state => ({
  currentStatusId: state.manager.currentStatusId,
  currentStatusProfiles: state.manager.currentStatusProfiles,
  selectedProfilesIds: state.manager.selectedProfilesIds,
  isCommentInputOpen: state.manager.isCommentInputOpen,
  nextStatus: state.manager.nextStatus,
  previousStatuses: state.manager.previousStatuses,
  subsequentStatuses: state.manager.subsequentStatuses,
  nextStage: state.manager.nextStage,
  tableTitle: state.manager.tableTitle,
  isStatusDropdownOpen: state.manager.isStatusDropdownOpen,
  selectedNextStage: state.manager.selectedNextStage,
  selectedNextStatus: state.manager.selectedNextStatus,
  isReject: state.manager.isReject,
  backStage: state.manager.backStage
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StatusButtonsTwo))