import React from 'react'
import { connect } from "react-redux"
import { commentInputClose } from "../redux/manager/managerActions";

function ReasonRow(props) {

  return (
    <>
      <input ref={props.commentRef} type="text" className="w-11/12 md:w-full rounded-lg focus:outline-none focus:ring-1 focus:ring-indigo-500" placeholder={props.commentPlaceholder}/>
      <button onClick={()=>props.commentInputClose()} type="button" className="md:mx-4 md:my-0 my-4 w-11/12 md:w-1/4 py-2 px-4 flex justify-center items-center bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md rounded-lg whitespace-nowrap">
        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        Отменить
      </button>
      <button onClick={()=>props.complete()} type="button" className="w-11/12 md:w-1/4 py-2 px-4 flex justify-center items-center bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md rounded-lg whitespace-nowrap">
        <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        Подтвердить
      </button>
    </>
  )
}

const mapDispatchToProps = {
  commentInputClose
}

const mapStateToProps = state => ({
  commentPlaceholder: state.manager.commentPlaceholder,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReasonRow)
