import React from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { hideSmallAlert } from "../redux/alert/alertActions"

function SmallAlert({left=false, ...props}) {

  // получение стилей в зависимости от типа
  const getBgColor = () => {
    switch (props.type) {
      case "neutral":
        return "bg-blue-500"
      case "danger":
        return "bg-red-500"
      case "success":
        return "bg-green-500"
      default:
        break;
    }
  }
  const getTextColor = () => {
    switch (props.type) {
      case "neutral":
        return "text-blue-500"
      case "danger":
        return "text-red-500"
      case "success":
        return "text-green-500"
      default:
        break;
    }
  }
  const getHoverColor = () => {
    switch (props.type) {
      case "neutral":
        return "hover:bg-blue-600"
      case "danger":
        return "hover:bg-red-700"
      case "success":
        return "hover:bg-green-600"
      default:
        break;
    }
  }

  return (
    // Анимация появления и исчезновения
    <CSSTransition
      in={props.smallAlertVisible}
      timeout={{
        enter: 500,
        exit: 350
      }}
      classNames='alert z-50'
      mountOnEnter
      unmountOnExit
    >
      <div className={`fixed ${left ? "left-6" : "right-6"} bottom-6 z-50`}>
        <div className={`alert ${getBgColor()} w-96 rounded-xl`}>
          <div className="py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-white">
                  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className={`h-6 w-6 ${getTextColor()}`} viewBox="0 0 1792 1792">
                    <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z">
                    </path>
                  </svg>
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  {props.message}
                </p>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button onClick={()=>props.hideSmallAlert()} type="button" className={`-mr-1 flex p-2 rounded-md ${getHoverColor()} focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="h-6 w-6 text-white" viewBox="0 0 1792 1792">
                    <path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z">
                    </path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

const mapDispatchToProps = {
  hideSmallAlert
}
const mapStateToProps = state => ({
  smallAlertVisible: state.alert.smallAlertVisible,
  message: state.alert.message,
  type: state.alert.type,
})
export default connect(mapStateToProps, mapDispatchToProps)(SmallAlert)
