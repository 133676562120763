import { GET_STATUS, sendQuery } from "../../graphql/Queries"
import { CHANGE_ARROWS, CHANGE_COMPANY, CHANGE_STATUS, COMMENT_INPUT_CLOSE, COMMENT_INPUT_OPEN, DOCUMENT_MODAL_TOGGLE, FILE_MODAL_TOGGLE, PATENT_FILE_MODAL_TOGGLE, RERENDER_TABLE, SELECT_PROFILES_ID, SET_TO_DEFAULT_MANAGER, STATUS_DROPDOWN_OPEN, TOGGLE_LIST, TOGGLE_LOADING } from '../types'

// функция для установки текущей компании
export const changeCompany = (company) => {
  // если комания не передана, удаляю данные о текущей компании из локалстореджа
  if (!company) {
    localStorage.removeItem('company_name')
    localStorage.removeItem('company_logo')
    localStorage.removeItem('company_id')
  } else {
    // если данные переданы записываю их в локал сторедж
    localStorage.setItem('company_name', company.name)
    localStorage.setItem('company_logo', company.logo)
    localStorage.setItem('company_id', company.company_id)
  }
  const payload = company ? company : {}
  return { type: CHANGE_COMPANY, payload }
  
}

// функция меняющая текущий статус в таблице
export const changeCurrentStatus = (stageId = "", statusId="", title="Выберите статус", viewOnly=false, currentStatus) => async dispatch => {
  // перерендер таблицы
  if (currentStatus === statusId) {
    return dispatch({type: RERENDER_TABLE})
  }
  // если переданны данные записываю их ы глобальный стор
  if ( stageId && statusId && title ) {
    const res = await sendQuery(GET_STATUS, {
      companyId: +localStorage.getItem('company_id'),
      statusId: +statusId
    })
    if (res.data.Status.traffic_queue) {
      const payload = {
        // currentStatusProfiles: res.data.Status.profiles,
        tableTitle: title,
        currentStatusId: statusId,
        currentStageId: stageId,
        viewOnly: viewOnly,
        nextStatus: res.data.Status.traffic_queue.next_status,
        previousStatuses: res.data.Status.traffic_queue.previous_statuses,
        subsequentStatuses: res.data.Status.traffic_queue.subsequent_statuses,
        nextStage: res.data.Status.traffic_queue.next_stage,
        backStage: res.data.Status.traffic_queue.back_stage,
        selectedNextStage: "",
        selectedNextStatus: "",
      }
      return dispatch({ type: CHANGE_STATUS, payload })
    } else {
      const payload = {
        // currentStatusProfiles: res.data.Status.profiles,
        tableTitle: title,
        currentStatusId: statusId,
        currentStageId: stageId,
        viewOnly: viewOnly,
        selectedNextStage: "",
        selectedNextStatus: "",
      }
      return dispatch({ type: CHANGE_STATUS, payload })
    }
  }
  // если данные не переданы то удаляю данные из глобального стора
  if (statusId === "" && stageId === "" && title === 'Выберите статус') {
    const payload = {
      // currentStatusProfiles: [],
      tableTitle: title,
      currentStatusId: statusId,
      currentStageId: stageId,
      viewOnly: viewOnly,
      nextStatus: {},
      previousStatuses: [],
      subsequentStatuses: [],
      nextStage: {},
      selectedNextStatus: "",
      selectedNextStage: ""
    }
    return dispatch({ type: CHANGE_STATUS, payload })
  }
}

// переклчатель списка компаний
export const toggleShowList = () => {
  return {type: TOGGLE_LIST}
}
// переключатель загрузки
export const toggleLoader = () => {
  return {type: TOGGLE_LOADING}
}
// переключатель мадалки загрузки файла
export const toggleFileModal = (toggle) => {
  const payload = toggle
  return {type: FILE_MODAL_TOGGLE, payload}
}
export const togglePatentFileModal = (toggle) => {
  const payload = toggle
  return {type: PATENT_FILE_MODAL_TOGGLE, payload}
}
// переключатель модалки загрузки документов
export const toggleDocumentModal = (toggle) => {
  const payload = toggle
  return {type: DOCUMENT_MODAL_TOGGLE, payload}
}

// функция записывает в глобальный стор выбранные анкеты
export const selectProfilesId = (ids) => {
  const payload = ids
  return {type: SELECT_PROFILES_ID, payload}
}

// открытие ReasonRow
export const commentInputOpen = (placeholder, nextId, isStage=false, isReject=false) => {
  if (isReject) {
    const payload = {
      isCommentInputOpen: true,
      isStatusDropdownOpen: false,
      commentPlaceholder: placeholder,
      selectedNextStage: "",
      selectedNextStatus: "",
      isReject: true,
    } 
    return {type: COMMENT_INPUT_OPEN, payload}
  }
  if (isStage) {
    const payload = {
      isCommentInputOpen: true,
      isStatusDropdownOpen: false,
      commentPlaceholder: placeholder,
      selectedNextStage: nextId,
      selectedNextStatus: "",
      isReject: false
    } 
    return {type: COMMENT_INPUT_OPEN, payload}
  }
  const payload = {
    isCommentInputOpen: true,
    isStatusDropdownOpen: false,
    commentPlaceholder: placeholder,
    selectedNextStatus: nextId,
    selectedNextStage: "",
    isReject: false
  }
  return {type: COMMENT_INPUT_OPEN, payload}
}
// закрытие ReasonRow
export const commentInputClose = () => {
  return {type: COMMENT_INPUT_CLOSE}
}
// модалка доп статусов
export const toggleStatusDropdown = () => {
  return {type: STATUS_DROPDOWN_OPEN}
}

// переключатель стрелок в списке статусов
export const changeArrowsObj = (arrows) => {
  const payload = {
    arrows
  }
  return {type: CHANGE_ARROWS, payload}
}

// сбрасывание стейта
export const setValuesToDefaultManager = () => {
  const payload = {
    company: {},
    nextStatus: {},
    nextStage: {},
    backStage: {},
    arrows: {},
    currentStatusProfiles: [],
    selectedProfilesIds: [],
    previousStatuses: [],
    subsequentStatuses: [],
    tableTitle: 'Выберите статус',
    currentStatusId: "",
    currentStageId: "",
    commentPlaceholder: "",
    selectedNextStatus: "",
    selectedNextStage: "",
    showList: true,
    viewOnly: false,
    isCommentInputOpen: false,
    isStatusDropdownOpen: false,
    statusLoading: false,
    isReject: false,
    isProfileOpen: false,
    rerenderTable: false,
  }
  return {type: SET_TO_DEFAULT_MANAGER, payload}
}


