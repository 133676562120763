import React, { useState } from 'react'
import { useLazyQuery } from "@apollo/client"
import { CHANGE_RASHOD } from "../../graphql/Queries"

export default function Rashod({ id, rashod }) {
  const [chRashod] = useLazyQuery(CHANGE_RASHOD, {
    variables: {
      id: id,
      status: rashod ? "true" : "false",
    },
    fetchPolicy: "cache-and-network",
  });

  const [localRashod, setLocalRashod] = useState(rashod);

  const handleCheckboxChange = async (event) => {
    const newRashod = event.target.checked;
    setLocalRashod(newRashod);
    chRashod({
      variables: {
        id: id,
        status: newRashod ? "true" : "false",
      },
    });
  };

  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <div className="flex items-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mr-4">
            Расход компании
          </h3>
          <input
            onChange={handleCheckboxChange}
            id="default-checkbox"
            type="checkbox"
            value=""
            checked={localRashod}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
        </div>
      </div>
    </>
  );
}