import { Field, useField, useFormikContext } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../context/FormContext'
import { Error } from './Error'
import { FileInput } from './FileInput'
import { TextInput } from './TextInput'

export default function RvpVng(props) {
  const { changeRvp, changeShowPatent, changeVng, changeStudy } = useContext(FormContext)
  const { values } = useFormikContext()


  const variants = [
    {
      id: '2',
      name: 'РВП',
    },
    {
      id: '3',
      name: 'ВНЖ',
    },
  ];

  if (props.citizenship === 'Республика Армения'
      || props.citizenship === 'Киргизская Республика'
      || props.citizenship === 'Республика Беларусь'
      || props.citizenship === 'Республика Казахстан') {
    variants.push({
      id: '1',
      name: 'Иное',
    });
  }

  const [field, meta] = useField({
    name: 'rvpVng',
    id: 123884,
  })

  useEffect(() => {
    if (values.rvpVng === 'РВП') {
      changeVng(false)
      changeRvp(true)
      changeShowPatent(false)
      changeStudy(false)
    } else if (values.rvpVng === 'Патент') {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(true)
      changeStudy(false)
    } else if (values.rvpVng === 'ВНЖ') {
      changeRvp(false)
      changeVng(true)
      changeShowPatent(false)
      changeStudy(false)
    } else if (values.rvpVng === 'Учёба') {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(false)
      changeStudy(false)
    }
    else {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(false)
      changeStudy(false)
    }
  }, [])

  useEffect(() => {
    if (values.rvpVng === 'РВП') {
      changeVng(false)
      changeRvp(true)
      changeShowPatent(false)
      changeStudy(false)
      values.studyNumber = ''
      values.studyDate = ''
      values.vngNumber = ''
      values.vngDate = ''
      values.vngDateEnd = ''
      values.vngPlace = ''
      values.patentSeries = ''
      values.patentNumber = ''
      values.patentDate = ''
      values.patentPlace = ''
      values.fileInputStudy = undefined
      values.fileInputPatent = undefined
      values.fileInputVng = undefined
    } else if (values.rvpVng === 'ВНЖ') {
      changeRvp(false)
      changeVng(true)
      changeShowPatent(false)
      changeStudy(false)
      values.studyNumber = ''
      values.studyDate = ''
      values.rvpNumber = ''
      values.rvpDate = ''
      values.rvpDateEnd = ''
      values.rvpPlace = ''
      values.patentSeries = ''
      values.patentNumber = ''
      values.patentDate = ''
      values.patentPlace = ''
      values.fileInputStudy = undefined
      values.fileInputPatent = undefined
      values.fileInputRvp = undefined
    } else if (values.rvpVng === 'Патент') {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(true)
      changeStudy(false)
      values.studyNumber = ''
      values.studyDate = ''
      values.vngNumber = ''
      values.vngDate = ''
      values.vngDateEnd = ''
      values.vngPlace = ''
      values.rvpNumber = ''
      values.rvpDate = ''
      values.rvpDateEnd = ''
      values.rvpPlace = ''
      values.fileInputStudy = undefined
      values.fileInputRvp = undefined
      values.fileInputVng = undefined
    } else if (values.rvpVng === 'Учёба') {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(false)
      changeStudy(true)
      values.vngNumber = ''
      values.vngDate = ''
      values.vngDateEnd = ''
      values.vngPlace = ''
      values.rvpNumber = ''
      values.rvpDate = ''
      values.rvpDateEnd = ''
      values.rvpPlace = ''
      values.patentSeries = ''
      values.patentNumber = ''
      values.patentDate = ''
      values.patentPlace = ''
      values.fileInputPatent = undefined
      values.fileInputRvp = undefined
      values.fileInputVng = undefined
    } else {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(false)
      changeStudy(false)
      values.studyNumber = ''
      values.studyDate = ''
      values.vngNumber = ''
      values.vngDate = ''
      values.vngDateEnd = ''
      values.vngPlace = ''
      values.rvpNumber = ''
      values.rvpDate = ''
      values.rvpDateEnd = ''
      values.rvpPlace = ''
      values.patentSeries = ''
      values.patentNumber = ''
      values.patentDate = ''
      values.patentPlace = ''
      values.fileInputStudy = undefined
      values.fileInputPatent = undefined
      values.fileInputRvp = undefined
      values.fileInputVng = undefined
    }
  }, [values])

  const radioChangeHandler = (field, form, variant) => {
    form.setFieldValue(field, variant)
  }

  return (
      <>
        <div className="flex flex-col mb-4">
          <label
              htmlFor="rvpVng"
              className="leading-7 text-md text-gray-900 font-semibold"
          >
            РВП/ВНЖ
          </label>
          <div
              role="group"
              aria-labelledby="my-radio-group"
              className="flex flex-col justify-center mb-2"
          >
            {variants.map((variant, idx) => (
                <label key={variant.id}>
                  <Field name="undefined">
                    {({ form }) => (
                        <input
                            id={'rvpVng'}
                            type="radio"
                            name={'rvpVng'}
                            onChange={() =>
                                radioChangeHandler('rvpVng', form, variant.name)
                            }
                            onBlur={field.onBlur}
                            value={idx}
                            className="h-5 w-5 text-indigo-600 outline-none"
                            checked={values['rvpVng'] === variant.name}
                        />
                    )}
                  </Field>

                  <span className="ml-2 text-gray-900">{variant.name}</span>
                </label>
            ))}
            {meta.touched && meta.error && <Error>{meta.error}</Error>}
          </div>
          {values.rvpVng === 'РВП' ? (
              <>
                <TextInput label="Номер РВП *" name="rvpNumber" type="text" />
                <TextInput
                    label="Дата выдачи РВП на работу *"
                    name="rvpDate"
                    type="date"
                />
                <TextInput
                    label="Дата окончания РВП на работу *"
                    name="rvpDateEnd"
                    type="date"
                />
                <TextInput label="Кем выдан *" name="rvpPlace" type="text" />
                <FileInput
                    label="Прикрепите скан РВП. Лицевую и обратную сторону. *"
                    name="fileInputRvp"
                />
              </>
          ) : values.rvpVng === 'Патент' ? (
              <>
                <TextInput
                    label="Серия патента *"
                    name="patentSeries"
                    type="text"
                />
                <TextInput
                    label="Номер патента *"
                    name="patentNumber"
                    type="text"
                />
                <TextInput
                    label="Дата выдачи патента на работу *"
                    name="patentDate"
                    type="date"
                />
                <TextInput
                    label="Дата окончания патента на работу *"
                    name="patentDateEnd"
                    type="date"
                />
                <TextInput label="Кем выдан *" name="patentPlace" type="text" />
                <FileInput
                    label="Прикрепите скан патента. Лицевую и обратную сторону. *"
                    name="fileInputPatent"
                />
              </>
          ) : values.rvpVng === 'ВНЖ' ? (
              <>
                <TextInput label="Номер ВНЖ *" name="vngNumber" type="text" />
                <TextInput
                    label="Дата выдачи ВНЖ на работу *"
                    name="vngDate"
                    type="date"
                />
                <TextInput
                    label="Дата окончания ВНЖ на работу *"
                    name="vngDateEnd"
                    type="date"
                />
                <TextInput label="Кем выдан *" name="vngPlace" type="text" />
                <FileInput
                    label="Прикрепите скан ВНЖ. Лицевую и обратную сторону. *"
                    name="fileInputVng"
                />
              </>
          ) : values.rvpVng === 'Учёба' ? (
              <>
                <TextInput
                    label="Номер справки *"
                    name="studyNumber"
                    type="text" />
                <TextInput
                    label="Дата выдачи справки *"
                    name="studyDate"
                    type="date"
                />
                <FileInput
                    label="Прикрепите скан справки *"
                    name="fileInputStudy"
                />
              </>
          ) : null}
        </div>
      </>
  )
}
