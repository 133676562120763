import React from 'react'


export default function DogovorInput({dogovorSelect, setDogovorSelect}) {
  return (
    <>
      <div>
        <label className="flex pl-2 pb-1">Основание трудовой деятельности</label>
        <select
          value={dogovorSelect}
          onChange={event => setDogovorSelect(event.target.value)}
          className="form-select rounded-lg w-full flex"
          id="org">
          <option value="1">ЕАЭС</option>
          <option value="2">Переселенец</option>
          <option value="3">Студент</option>
          <option value="4">Беженец</option>
          <option value="5">Временное убежище</option>
          <option value="6">РВП, ВНЖ</option>
          <option value="7">Украина</option>
          <option value="8">Патент</option>
        </select>
      </div>
    </>
  )
}
