import React from 'react'
import { connect } from 'react-redux'
import ButtonRow from './ButtonRow'
import Header from './Header'
import StatusList from './StatusList'
import Table from './table/Table'

function MainLayout(props) {
  // главная страница с таблицей и статусами
  return (
    <>
      <Header />
      <div className="flex-col">
        <section className="grid grid-flow-row grid-cols-4 md:px-12 sm:px-4 gap-4 mb-8 px-2">
          <aside className={`${props.currentStatusId ? "row-start-1" : "row-start-2 "} lg:col-span-1 lg:col-start-4 lg:row-start-1 col-span-4`}>
            <StatusList companyId={Number(localStorage.getItem("company_id"))}/>
          </aside>
          {props.currentStatusId ? (
            <>
              <div className="col-span-4 lg:col-start-1 lg:col-span-3 h-full rounded-lg border bg-white shadow-xl relative z-40">
                <Table tableTitle={props.tableTitle} />
              </div>
              {!props.viewOnly && (
                <div className="bg-white col-span-4 lg:col-span-3 h-auto rounded-lg borde shadow-xl border flex items-center flex-wrap md:flex-nowrap flex-col md:flex-row py-4 sm:px-4 md:px-8 z-50">
                  <ButtonRow />
                </div>
              )}
            </>
          ) : (
            <h1 className="mt-12 mb-4 col-span-4 row-start-1 lg:col-span-3 text-center lg:my-auto flex justify-center text-lg leading-6 font-medium text-gray-900 dark:text-white"> Выберите статус
              <svg xmlns="http://www.w3.org/2000/svg" className="hidden lg:block ml-4 h-6 w-6 bounce-right" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" className="lg:hidden ml-2 h-6 w-6 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 17l-4 4m0 0l-4-4m4 4V3" />
              </svg>
            </h1>
          )}
        </section>
      </div>
    </>
  )
    
  
}

const mapStateToProps = state => ({
  tableTitle: state.manager.tableTitle,
  viewOnly: state.manager.viewOnly,
  currentStatusId: state.manager.currentStatusId,
  nextStatus: state.manager.nextStatus,
  nextStage: state.manager.nextStage,
})

export default connect(mapStateToProps, null)(MainLayout)