import React from 'react'

export default function OneCStatus({ date, title, children }) {
   return (
      <>
         <div className="rounded-lg border bg-white shadow-xl my-4 z-30 ">
            <div>
               <div className="flex-row">
                  <div className="mb-2">
                     <div className={children ? "px-4" : "flex mt-1 px-4"}>
                        {date ? <p className="leading-7 text-sm">{date}</p> : null}
                        {title ? <p className="leading-7 text-md ml-2">{title}</p> : children}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
