import React from 'react'
import { TextInput } from './inputs/TextInput'
import { Checkbox } from './inputs/Checkbox'
import { Select } from './inputs/Select'
import { RadioButton } from './inputs/RadioButton'
import { FileInput } from './inputs/FileInput'
import { Phone } from './inputs/Phone'
import { ConvictionRadio } from './inputs/ConvictionRadio'
import { AdvRadio } from './inputs/AdvRadio'
import { Relatives } from './inputs/Relatives'
import { NumberTT } from './inputs/NumberTT'
import Work from './inputs/Work'
import Education from './inputs/Education'
import RvpVng from './inputs/RvpVng'

const KeysToComponentMap = {
  text: TextInput,
  email: TextInput,
  date: TextInput,
  number: TextInput,
  checkbox: Checkbox,
  select: Select,
  radio: RadioButton,
  file: FileInput,
  tel: Phone,
  conviction: ConvictionRadio,
  adv: AdvRadio,
  relatives: Relatives,
  education: Education,
  work: Work,
  numberTT: NumberTT,
  RvpVng: RvpVng
}

function renderer(config, id, label) {
  if (typeof KeysToComponentMap[config.type] !== "undefined") {
    return React.createElement(
      KeysToComponentMap[config.type],
      {...config,
        key: id,
        label,
      }
    );
  }
}

export default renderer;
