import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_PATENT_PAYMENT_DATA, UPDATE_PATENT_PAYMENT } from "../../graphql/Queries";

Modal.setAppElement('#root');

export function EmptyModalButton({ data }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [monthList, setMonthList] = useState([]);
    const [checkedMonths, setCheckedMonths] = useState(null); // Изменено значение на null
    const url = window.location.pathname;
    const profileId = url.split('/').pop();
    const [getPatentPaymentData, { data: patentPaymentData }] = useLazyQuery(GET_PATENT_PAYMENT_DATA, {
        onCompleted: (data) => {
            setCheckedMonths(data.PatentPayment.data);
        },
    });
    const [resetChecked, setResetChecked] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
        if (data && data.patentDate) {
            const [day, month, year] = data.patentDate.split('.');
            const startDate = `${month}.${year}`;
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };



    const handleCheck = (event) => {
        const { name, checked } = event.target;
        setCheckedMonths((prevState) => ({ ...prevState, [name]: checked }));
        setResetChecked(false);
    };



    const [updatePatentPayment] = useMutation(UPDATE_PATENT_PAYMENT);

    useEffect(() => {
        if (profileId) {
            getPatentPaymentData({ variables: { profile_id: profileId } });
        }
    }, [profileId, getPatentPaymentData]);

    useEffect(() => {
        if (patentPaymentData && patentPaymentData.PatentPayment && patentPaymentData.PatentPayment.data) {
            const parsedData = JSON.parse(patentPaymentData.PatentPayment.data);
            const updatedCheckedMonths = parsedData.data;
            const checked = {};
            for (const [key, value] of Object.entries(updatedCheckedMonths)) {
                checked[key] = value === 'true' || value === true;
            }
            setCheckedMonths(checked);
        }
    }, [patentPaymentData]);
    const months = [ 'января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря' ];
    let sortedMonths = [];
    if (checkedMonths) {
        sortedMonths = Object.keys(checkedMonths).sort((a, b) => {
            const [dayA, monthA, yearA, ] = a.split(' ');
            const [dayB, monthB, yearB, ] = b.split(' ');
            if (yearA !== yearB) {
                return parseInt(yearA) - parseInt(yearB);
            } else {
                return months.indexOf(monthA) - months.indexOf(monthB);
            }
        });
    }
    const handleSubmit = () => {
        const data = JSON.stringify(checkedMonths);
        updatePatentPayment({
            variables: {
                profile_id: profileId,
                data,
            },
        });
        closeModal();
        window.location.reload()
    };

    return (
        <>
            <button
                onClick={openModal}
                type="button"
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg rounded-lg border bg-white shadow-xl z-40 mb-4 w-full"
            >
                История оплат патента
            </button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="История оплат патента"
                style={{
                    content: {
                        width: '400px',
                        height: '700px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: 'none',
                        borderRadius: '15px 15px 15px 15px',
                        overflow: 'hidden',
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    },
                }}
            >
                <div style={{ fontSize: '22px' }}>
                    <h3 style={{ fontWeight: 'bold', marginBottom: '16px', marginTop: '5px' }}>Патент оплачен до:</h3>
                    <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                        {checkedMonths &&
                            sortedMonths.map((month) => {
                                const value = checkedMonths[month];
                                return (
                                    <li key={month} style={{ marginBottom: '9px', display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            name={month}
                                            onChange={handleCheck}
                                            checked={value}
                                            style={{ transform: 'scale(1.6)', marginRight: '18px', marginLeft: '6px'}}
                                        />
                                        <span>{month}</span>
                                    </li>
                                );
                            })}
                    </ul>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <button
                            onClick={handleSubmit}
                            type="button"
                            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg rounded-lg border bg-white shadow-xl z-40 mb-4"
                            style={{ fontSize: '25px' }}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>

            </Modal>

        </>
    );
}

export default EmptyModalButton;
