import { useQuery } from '@apollo/client';
import { Popover, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { ExclamationIcon as ExclamationIconSolid } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { useHistory } from 'react-router-dom';
import { GET_EXPIRING_PATENT_PAYMENT, GET_EXPIRING_PROFILES } from '../graphql/Queries';
import { Portal } from '../services/Portal';
import { Loader } from './Loader';

export default function Expiring() {
  const history = useHistory()
  // библиотека popper js для отображения дропдаунов
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  })

  const { error, loading, data } = useQuery(GET_EXPIRING_PROFILES, {
    variables: { companyId: localStorage.getItem('company_id') },
  })
  if (error) console.log(`Error graphql: ${error}`)

  const { patentError, patentLoading, data: patentResponseData } = useQuery(GET_EXPIRING_PATENT_PAYMENT, {
    variables: { companyId: localStorage.getItem('company_id') },
  })
  if (patentError) {
    console.log(`Error graphql: ${patentError}`);
  }

  const patentData = patentResponseData?.ExpiringPatentPayment;
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 2;
  const currentYear = currentDate.getFullYear();
  const usersWithPendingPayment = [];
  for (const key in patentData) {
    const user = patentData[key];
    const { profile_id, firstName, lastName, patronymic, patentPaymentData } = user;
    let isPaymentPending = false;
    for (const date in patentPaymentData) {
      const [day, month, year] = date.split(' ');
      const dayNumber = parseInt(day);
      const monthNumber = getMonthNumber(month);
      const yearNumber = parseInt(year);
      if (
          (yearNumber < currentYear) ||
          (yearNumber === currentYear && monthNumber < currentMonth) ||
          (yearNumber === currentYear && monthNumber === currentMonth && dayNumber - currentDay <= 2)
      ) {
        if (!patentPaymentData[date]) {
          isPaymentPending = true;
          break;
        }
      }
    }
    if (isPaymentPending) {
      usersWithPendingPayment.push({ profile_id, firstName, lastName, patronymic, isPaymentPending });
    }
  }
  function getMonthNumber(month) {
    const monthNames = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
    return monthNames.indexOf(month) + 1;
  }
  let sortedDocuments = [];
  if (data && data.Expiring_profiles) {
    data.Expiring_profiles.forEach(profile => {
      if (profile.passportExpired !== null && profile.passportExpired <= 60) {
        sortedDocuments.push({
          profile_id: profile.profile_id,
          documentType: "passport",
          days: profile.passportExpired,
          firstName: profile.firstName,
          lastName: profile.lastName,
        });
      }
      if (profile.patentExpired !== null && profile.patentExpired <= 60) {
        sortedDocuments.push({
          profile_id: profile.profile_id,
          documentType: "patent",
          days: profile.patentExpired,
          firstName: profile.firstName,
          lastName: profile.lastName,
        });
      }
      if (profile.registrationExpired !== null && profile.registrationExpired <= 7) {
        sortedDocuments.push({
          profile_id: profile.profile_id,
          documentType: "registration",
          days: profile.registrationExpired,
          firstName: profile.firstName,
          lastName: profile.lastName,
        });
      }
      if (profile.dogovorExpired !== null && profile.dogovorExpired <= 14) {
        sortedDocuments.push({
          profile_id: profile.profile_id,
          documentType: "dogovor",
          days: profile.dogovorExpired,
          firstName: profile.firstName,
          lastName: profile.lastName,
        });
      }
    });

    // Сортируем массив
    sortedDocuments.sort((a, b) => {
      // Первый приоритет - просроченные документы
      if (a.days < 0 && b.days < 0) {
        return a.days - b.days;
      }
      // Второй приоритет - приближающиеся документы
      if (a.days >= 0 && b.days >= 0) {
        return a.days - b.days;
      }
      // Если один из документов просрочен, а другой - нет, то просроченный документ идет первым
      if (a.days < 0 && b.days >= 0) {
        return -1;
      }
      if (a.days >= 0 && b.days < 0) {
        return 1;
      }
    });
  }


  return (
      // компонент из headless Ui
      <Popover className="relative">
        {({open}) => (
            <>
              <div ref={setReferenceElement}>
                <Popover.Button
                    className={`
                ${open ? '' : 'text-opacity-90'}
                relative text-black group bg-orange-700 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <ExclamationIcon className={`${open ? '' : 'text-opacity-70'} h-5 w-5`}/>
                  {((sortedDocuments?.length || 0) + (usersWithPendingPayment?.length || 0)) > 0 && (
                      <div
                          className="origin-top-right -top-1 -right-1 text-2xs absolute rounded-full bg-red-600 text-white w-4 h-4 flex items-center justify-center">
                        {(sortedDocuments?.length || 0) + (usersWithPendingPayment?.length || 0)}
                      </div>
                  )}
                </Popover.Button>
              </div>
              <Portal>
                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                    className="absolute z-50"
                >
                  <Popover.Panel
                      ref={setPopperElement}
                      style={styles.popper}
                      {...attributes.popper}
                  >
                    <div
                        className="w-screen max-w-sm mt-3 notification bg-white overflow-scroll border rounded-lg shadow-lg relative grid gap-8 p-7">
                      {loading ? (
                          <Loader/>
                      ) : (
                          <>
                            {(sortedDocuments?.length > 0 || usersWithPendingPayment?.length > 0) ? (
                                <>
                                  {sortedDocuments.filter((item) => item.days <= 0).length > 0 && (
                                      <p className="text-sm font-semibold">Истёкшие сроки документов:</p>
                                  )}
                                  {sortedDocuments
                                      .filter((item) => item.days <= 0)
                                      .map((item, idx) => (
                                          <div
                                              key={`expiring-row-${idx}`}
                                              onClick={() => history.push(`/user/${item.profile_id}`)}
                                              className="border border-gray-100 py-2 items-center -m-3 rounded-lg cursor-pointer hover:bg-gray-100 transition duration-200 ease-in-out"
                                          >
                                            <div className="ml-4 flex flex-col mx-4">
                                              <div
                                                  className="w-full text-sm font-medium text-gray-900 flex items-center justify-between">
                                                <div>
                                                  {item.lastName} {item.firstName}
                                                </div>
                                              </div>
                                              <div className="text-sm font-light">
                                                {item.documentType === "registration" && (
                                                    <div className="flex items-center">
                                                      <ExclamationIconSolid className="w-5 h-5 mr-1 text-red-500"/>
                                                      Регистрация просрочилась на{" "}
                                                      <p className="ml-1 font-semibold">{Math.abs(item.days)} дня</p>
                                                    </div>
                                                )}
                                                {item.documentType === "patent" && (
                                                    <div className="flex items-center">
                                                      <ExclamationIconSolid className="w-5 h-5 mr-1 text-red-500"/>
                                                      Патент просрочен на{" "}
                                                      <p className="ml-1 font-semibold">{Math.abs(item.days)} дня</p>
                                                    </div>
                                                )}
                                                {item.documentType === "passport" && (
                                                    <div className="flex items-center">
                                                      <ExclamationIconSolid className="w-5 h-5 mr-1 text-red-500"/>
                                                      Паспорт просрочен на{" "}
                                                      <p className="ml-1 font-semibold">{Math.abs(item.days)} дня</p>
                                                    </div>
                                                )}
                                                {item.documentType === "dogovor" && (
                                                   <div className="flex items-center">
                                                     <ExclamationIconSolid className="w-5 h-5 mr-1 text-red-500"/>
                                                     Договор просрочен на{" "}
                                                     <p className="ml-1 font-semibold">{Math.abs(item.days)} дня</p>
                                                   </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                      ))}
                                  {usersWithPendingPayment && usersWithPendingPayment.length > 0 && (
                                      <>
                                        <p className="text-sm font-semibold">Контроль оплаты патента:</p>
                                        {usersWithPendingPayment.map((user, index) => (
                                            <div
                                                key={`pending-payment-row-${index}`}
                                                onClick={() => history.push(`/user/${user.profile_id}`)}
                                                className="border border-gray-100 py-2 items-center -m-3 rounded-lg cursor-pointer hover:bg-gray-100 transition duration-200 ease-in-out"
                                            >
                                              <div className="ml-4 flex flex-col mx-4">
                                                <div
                                                    className="w-full text-sm font-medium text-gray-900 flex items-center justify-between">
                                                  <div>{user.lastName} {user.firstName}</div>
                                                </div>
                                                <div className="text-sm font-light">
                                                  <div className="flex items-center">
                                                    <ExclamationIconSolid className="w-5 h-5 mr-1 text-green-400"/>
                                                    Требуется запросить оплату патента
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                        ))}
                                      </>
                                  )}
                                  {sortedDocuments?.filter((item) => item.days > 0).length > 0 && (
                                      <>
                                        <p className="text-sm font-semibold">Истекающие сроки документов:</p>
                                        {sortedDocuments
                                            .filter((item) => item.days > 0)
                                            .map((item, idx) => (
                                                <div
                                                    key={`expiring-row-${idx}`}
                                                    onClick={() => history.push(`/user/${item.profile_id}`)}
                                                    className="border border-gray-100 py-2 items-center -m-3 rounded-lg cursor-pointer hover:bg-gray-100 transition duration-200 ease-in-out"
                                                >
                                                  <div className="ml-4 flex flex-col mx-4">
                                                    <div
                                                        className="w-full text-sm font-medium text-gray-900 flex items-center justify-between">
                                                      <div>
                                                        {item.lastName} {item.firstName}
                                                      </div>
                                                    </div>
                                                    <div className="text-sm font-light">
                                                      {item.documentType === "registration" && (
                                                          <div className="flex items-center">
                                                            <ExclamationIconSolid
                                                                className="w-5 h-5 mr-1 text-yellow-400"/>
                                                            Регистрация: осталось{" "}
                                                            <p className="ml-1 font-semibold">{item.days} дня</p>
                                                          </div>
                                                      )}
                                                      {item.documentType === "patent" && (
                                                          <div className="flex items-center">
                                                            <ExclamationIconSolid
                                                                className="w-5 h-5 mr-1 text-yellow-400"/>
                                                            Патент: осталось{" "}
                                                            <p className="ml-1 font-semibold">{item.days} дня</p>
                                                          </div>
                                                      )}
                                                      {item.documentType === "passport" && (
                                                          <div className="flex items-center">
                                                            <ExclamationIconSolid
                                                                className="w-5 h-5 mr-1 text-yellow-400"/>
                                                            Паспорт: осталось{" "}
                                                            <p className="ml-1 font-semibold">{item.days} дня</p>
                                                          </div>
                                                      )}
                                                      {item.documentType === "dogovor" && (
                                                         <div className="flex items-center">
                                                           <ExclamationIconSolid
                                                              className="w-5 h-5 mr-1 text-yellow-400"/>
                                                           Договор: осталось{" "}
                                                           <p className="ml-1 font-semibold">{item.days} дня</p>
                                                         </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                            ))}
                                      </>
                                  )}
                                </>
                            ) : (
                                <p className="text-sm">Нет доступных уведомлений</p>
                            )}
                          </>
                      )}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Portal>
            </>
        )}
      </Popover>
  );
}
