import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Listbox, Portal, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import React, {useEffect, useMemo, useState} from 'react';
import { usePopper } from 'react-popper';
import { useParams } from 'react-router-dom';
import { hideSmallAlert, showSmallAlert } from '../../redux/alert/alertActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {  GET_COMPANY, GET_PROFILE, GET_WORKPLACES, UPDATE_ANY_COMPANY } from "../../graphql/Queries";

export function AnyCompany(props) {
    const params = useParams();
    const [referenceElement, setReferenceElement] = useState();
    const [popperElement, setPopperElement] = useState();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
    });
    const [referenceElement1, setReferenceElement1] = useState(null);
    const [popperElement1, setPopperElement1] = useState(null);
    const {styles: styles1, attributes: attributes1} = usePopper(referenceElement1, popperElement1);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedWorkplace, setSelectedWorkplace] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    const { error, data: companyData } = useQuery(GET_COMPANY, {
        fetchPolicy: 'no-cache',
    });
    if (error) console.log(error);

    const [getWorkplaces, { error: workplaceError, data: workplaceData }] = useLazyQuery(GET_WORKPLACES);
    if (workplaceError) console.log('workplaceError', workplaceError);

    const [updateAnyCompany] = useMutation(UPDATE_ANY_COMPANY)
    const updateCompanyV2 = async () => {
        const foundCompany = companies.find((company) => company.name === selectedCompany);
        const foundWork = workplaces.find((workplace) => workplace.title === selectedWorkplace);
        const company_name = localStorage.getItem('company_name');
        await updateAnyCompany({ variables: {
                profileId: +params.id,
                companyId: +foundCompany['company_id'],
                workplaceId: +foundWork['workplace_id'],
                date: selectedDate,
                companyName: company_name,
            },
            refetchQueries: [
                {
                    query: GET_PROFILE, variables: {
                        companyId: +foundCompany['company_id'],
                        profileId: +params.id
                    }
                }
            ],
            awaitRefetchQueries: true
        })
        props.showSmallAlert("Сотрудник переведен", "success");
        setTimeout(() => {
            props.hideSmallAlert();
            window.history.go(-1);
        }, 3000);
    }

    const companies = useMemo(() => {
        if (companyData && companyData.Companies) {
            const currentCompanyName = localStorage.getItem('company_name');
            return companyData.Companies.filter((company) => company.name !== currentCompanyName);
        } else {
            return [];
        }
    }, [companyData]);

    const workplaces = useMemo(() => {
        if (workplaceData && workplaceData.Workplace) {
            return workplaceData.Workplace;
        } else {
            return [];
        }
    }, [workplaceData]);

    const updateCompany = async () => {
        const foundCompany = companies.find((company) => company.name === selectedCompany);
        if (foundCompany){
            getWorkplaces({
                fetchPolicy: 'no-cache',
                variables: {
                    company_id: +foundCompany['company_id']
                }
            })
        }
    };

    useEffect(() => {
        updateCompany();
    }, [selectedCompany]);


    useEffect(() => {
    }, [workplaces])


    return (
        <>
            <div className="flex flex-col w-full p-4 rounded-lg border bg-white shadow-x-1 my-4 z-30">
                <div className="px-1 py-1 flex justify-center sm:px-2">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Сменить компанию</h3>
                    </div>
                </div>
                <div className="rounded-lg border bg-white shadow-x-1 my-1 z-1">
                    <div className="flex-row px-2 py-2" ref={setReferenceElement1}>
                        <Listbox value={selectedCompany} onChange={setSelectedCompany}>
                            <div className="relative">
                                <Listbox.Button className="w-full flex-row px-1 py-1">
                                    <span className="block truncate">{selectedCompany || 'Выберите компанию'}</span>
                                    <span
                                        className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                  </span>
                                </Listbox.Button>
                                <Portal>
                                    <Transition
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        {companies && companies.length > 0 ? (
                                            <Listbox.Options
                                                className="relative py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                ref={setPopperElement1}
                                                style={{...styles1.popper, width: '18.3%'}}
                                                {...attributes1.popper}
                                            >
                                                {companies.map((company, companyIdx) => {
                                                    return (
                                                        <Listbox.Option
                                                            key={companyIdx}
                                                            className={({active}) =>
                                                                `${active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'
                                                                } cursor-pointer select-none relative py-2 pl-10 pr-4`
                                                            }
                                                            value={company.name}
                                                        >
                                                            {({selected, active}) => (
                                                                <>
                                  <span
                                      className={`${
                                          selected ? 'font-medium' : 'font-normal'
                                      } block truncate`}
                                  >
                                    {company.name}
                                  </span>
                                                                    {selected ? (
                                                                        <span
                                                                            className={`${
                                                                                active ? 'text-gray-600' : 'text-gray-600'
                                                                            } absolute inset-y-0 left-0 flex items-center pl-3`}
                                                                        >
                                      <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                                    </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    );
                                                })}
                                            </Listbox.Options>
                                        ) : null}
                                    </Transition>
                                </Portal>
                            </div>
                        </Listbox>
                    </div>
                </div>


                {selectedCompany && (
                    <div className="rounded-lg border bg-white shadow-x-1 my-1 z-2">
                        <div className="flex-row px-2 py-2" ref={setReferenceElement}>
                            <Listbox value={selectedWorkplace} onChange={setSelectedWorkplace}>
                                <div className="relative">
                                    <Listbox.Button className="w-full flex-row px-1 py-1">
                                        <span className="block truncate">{selectedWorkplace || 'Выберите точку'}</span>
                                        <span
                                            className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true"/>
                  </span>
                                    </Listbox.Button>
                                    <Portal>
                                        <Transition
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            {workplaces && workplaces.length > 0 ? (
                                                <Listbox.Options
                                                    className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                    ref={setPopperElement}
                                                    style={{...styles.popper, width: '18.3%'}}
                                                    {...attributes.popper}
                                                >
                                                    {workplaces.map((workplace, key) => {
                                                        return (
                                                            <Listbox.Option
                                                                key={workplace.workplace_id}
                                                                className={({active}) =>
                                                                    `${active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'
                                                                    } cursor-pointer select-none relative py-2 pl-10 pr-4`
                                                                }
                                                                value={workplace.title}
                                                            >
                                                                {({selected, active}) => (
                                                                    <>
                                  <span
                                      className={`${
                                          selected ? 'font-medium' : 'font-normal'
                                      } block truncate`}
                                  >
                                    {workplace.title}
                                  </span>
                                                                        {selected ? (
                                                                            <span
                                                                                className={`${
                                                                                    active ? 'text-gray-600' : 'text-gray-600'
                                                                                } absolute inset-y-0 left-0 flex items-center pl-3`}
                                                                            >
                                      <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                                    </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        );
                                                    })}
                                                </Listbox.Options>
                                            ) : null}
                                        </Transition>
                                    </Portal>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                )}

                <div className="rounded-lg border bg-white shadow-x-1 my-1 z-1">
                    <div className="flex-row px-2 py-2 flex justify-between" ref={setReferenceElement}>
                        <div className="w-full flex items-center">
                            <div className="px-2 w-full">
                                <input
                                    className={`w-full py-2 pl-3 text-gray-700 leading-tight rounded-lg text-center focus:outline-none ${selectedDate ? 'date-input--has-value' : ''}`}
                                    id="date"
                                    type="date"
                                    defaultValue=""
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    required
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <button
                    onClick={updateCompanyV2}
                    type="button"
                    className="w-full mt-2 py-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md rounded-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={!selectedCompany || !selectedWorkplace || !selectedDate}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2 h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>
                    Перевести
                </button>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    showSmallAlert: (message, type) => dispatch(showSmallAlert(message, type)),
    hideSmallAlert: () => dispatch(hideSmallAlert()),
});

export default withRouter(connect(null, mapDispatchToProps)(AnyCompany));