import { connect } from 'react-redux'
import React, { useState } from 'react'
import TrustedManagersInput from "./TrustedManagersInput"
import OmvdInput from './OmvdInput';
import OrgInput from './OrgInput';
import DogovorInput from './DogovorInput';



function GeneratorField({ data, ...props }) {

  const [omvdSelect, setOmvdSelect] = useState('');
  const [orgSelect, setOrgSelect] = useState('1');
  const [trustedManagersSelect, setTrustedManagersSelect] = useState('');
  const [dogovorSelect, setDogovorSelect] = useState('1');

  return (
    <>
      <div className="px-4 pt-2 sm:px-6">
        <TrustedManagersInput trustedManagersSelect={trustedManagersSelect} setTrustedManagersSelect={setTrustedManagersSelect}/>
      </div>
      <div className="px-4 pt-2 sm:px-6">
        <OmvdInput omvdSelect={omvdSelect} setOmvdSelect={setOmvdSelect}/>
      </div>
      <div className="px-4 pt-2 sm:px-6">
        <OrgInput orgSelect={orgSelect} setOrgSelect={setOrgSelect}/>
      </div>
      <div className="px-4 pb-5 pt-2 sm:px-6">
        <DogovorInput dogovorSelect={dogovorSelect} setDogovorSelect={setDogovorSelect}/>
      </div>
      <div className="border-t border-gray-200 sm:px-8 flex flex-col flex-wrap items-center pt-2 md:px-2">
        {data.Employee_profile.generators.map((generator, idx) => (
          <a
            target="_blank"
            key={idx}
            rel="noopener noreferrer"
            href={`/generate?action=${generator.action}&profile_id=${data.Employee_profile.profile_id}&company_id=${localStorage.getItem("company_id")}&Authorization=${`Bearer ${localStorage.getItem("token")}`}&omvd=${omvdSelect}&org=${orgSelect}&trusted_manager=${trustedManagersSelect}&dogovor=${dogovorSelect}`}
            className="mb-2 w-full py-2 px-4 flex justify-center items-center bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold rounded-lg whitespace-nowrap border border-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            {generator.file_name}
          </a>
        ))}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  fields: state.manager.fields
})

export default connect(mapStateToProps, null)(GeneratorField)


