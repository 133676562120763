import { SELECT_FILTERED_ID, SELECT_CANCELED_ID}  from "../types";

const initialState = {
  selectedFilteredIds: [],
  selectedCanceledIds: [],
}

export const reviseReducer = (state = initialState, action) => {
  switch (action.type) {
      case SELECT_FILTERED_ID:
        return {...state, selectedFilteredIds: action.payload}
      case SELECT_CANCELED_ID:
        return {...state, selectedCanceledIds: action.payload}
      default: return state
  }
}