import React, { useState, useEffect, useContext } from "react";
import { Field, useField } from 'formik'
import { Error } from './Error'
import File from "./File"
import { FormContext } from "../context/FormContext"

const fileNames = {
  "fileInputPassport": "Скан паспорта",
  "fileInputRegistration": "Скан регистрации",
  "fileInputMigration": "Скан миграционной карты",
  "fileInputPolice": "Cкан полиса ДМС",
  "fileInputPatent": "Скан патента",
  "fileInputRvp": "Скан РВП",
  "fileInputVng": "Скан ВНЖ",
  "fileInputPhoto": "Фотография",
  "fileInputStudy": "Скан учебной справки"
}

export const FileInput = ({label, ...props}) => {
  const [ localFiles, setLocalFiles ] = useState([])
  const [ failMessage, setFailMessage ] = useState('')
  const [ field, meta ] = useField(props)

  const { setFilesToContext, files, deleteFilesFromContext, uploadFile, fileLoader, addFileInData, removeFileInData } = useContext(FormContext)
  
  const handleFileChange = (e, form, inputName) => {
    setFailMessage('')
    form.setFieldTouched(props.name)
    let newName = ''
    switch (inputName) {
      case "fileInputPassport":
        newName = "Скан паспорта"
        break
      case "fileInputRegistration":
        newName = "Скан регистрации"
        break
      case "fileInputMigration":
        newName = "Скан миграционной карты"
        break
      case "fileInputPolice":
        newName = "Cкан полиса ДМС"
        break
      case "fileInputPatent":
        newName = "Скан патента"
        break
      case "fileInputPhoto":
        newName = "Фотография"
        break
      case "fileInputRvp":
        newName = "Скан РВП"
        break
      case "fileInputVng":
        newName = "Скан ВНЖ"
        break
      case "fileInputStudy":
        newName = "Скан учебной справки"
        break
      default:
        newName = "Скан"
        break
    }
    e.preventDefault();
    let arr = [...localFiles]
    const fileArray = [...e.target.files]
    fileArray.forEach((file, idx) => file["newName"] = `${newName} - ${localFiles.length > 0 ? localFiles.length + 1 : idx + 1}`)

    fileArray.forEach(async file => {
      if (file) {
        let permission = true
        localFiles.forEach(f => {
          if (f.name === file.name) {
            permission = false
          }
        })
        // files.forEach(f => {
        //   if (f.name === file.name) {
        //     permission = false
        //   }
        // })
        if (permission) {
          arr.push(file)
          addFileInData(file);
          setLocalFiles(prev => [...prev, file])
          setFilesToContext([file])
        }
      }
    })
    console.log(arr)
    form.setFieldValue(props.name, arr)
    // fileArray.forEach(f => {
    //   if (f) {
    //     setLocalFiles(prev => [...prev, f])
    //   }
    // })
  }

  const deleteFile = (fileToDelete, form) => {
    let localArr = localFiles.filter(file => file.newName !== fileToDelete)
    setLocalFiles(localArr)
    if (localArr.length === 0) {
      form.setFieldValue(props.name, undefined)
      
    } else {
      form.setFieldValue(props.name, localArr)
    }
    let arr = [...files]
    arr = arr.filter(file => file.newName !== fileToDelete)
    deleteFilesFromContext(arr)
    removeFileInData(fileToDelete)
    // let localArr = localFiles.filter(file => file.name != fileToDelete)
    // setLocalFiles(localArr)
  }

  // useEffect(() => {
  //   setFilesToContext(localFiles)
  // }, [localFiles])
  

  return (
    <div className="flex flex-col mb-4">
      <label className="leading-7 text-md font-semibold text-gray-900" htmlFor={props.name}>
        {label}
      </label>
        <label className="font-semibold w-48 h-16 flex items-center mt-2 py-1 px-4 shadow-md rounded-md bg-green-500 hover:bg-green-600 text-white cursor-pointer" htmlFor={props.name}>
          <svg className="w-6 h-6 mr-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
          </svg>
          <p>Файл</p>
        </label>
        <Field name="undefined">
          {({ form }) => (
            <input
              id={props.name}
              name={props.name}
              type="file"
              onChange={(e)=>handleFileChange(e, form, props.name)}
              onBlur={field.onBlur}
              className="hidden"
              multiple />
          )}
        </Field>
        {files && (
          <>{files.map(file => {
              if (file.newName.includes(fileNames[props.name])) {
                return <File name={file.name} newName={file.newName} key={file.name} onDelete={deleteFile}/>
              } else return null
            }
          )}</>
        )}
        {meta.touched && meta.error && (
          <div className="mt-2"><Error>{meta.error}</Error></div>
        )}
        {fileLoader && (
          <p className="mt-2">Загрузка файлов ...</p>
          )}
        {failMessage && (
          <div className="mt-2"><Error>{failMessage}</Error></div>
        )}
      </div>
  )
}