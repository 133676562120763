import { useQuery } from '@apollo/client'
import React, { useEffect, useMemo } from 'react'
import { GET_OMVD } from '../../graphql/Queries'


export default function OmvdInput({omvdSelect, setOmvdSelect}) {
  let { error, data } = useQuery(GET_OMVD, {
    variables: { 
      companyId: +localStorage.getItem('company_id'),
     },
  })
  if (error) console.log(error)

  const omvds = useMemo(
    () => data ? data?.Omvds : [],
  [data]
)

useEffect(()=>{
  setOmvdSelect(omvds[0]?.id);
}, [omvds])

  return (
    <>
      <div>
        <label className="flex pl-2 pb-1">ОМВД</label>
        <select
          value={omvdSelect}
          onChange={event => setOmvdSelect(event.target.value)}
          className="form-select rounded-lg w-full flex"
          id="trusted_manager">
          {omvds.map((omvd) => (
            <option key={omvd.id.toString()} value={omvd.id.toString()}>{omvd.short_name}</option>
          ))}
        </select>
      </div>
    </>
  )
}
