import React from 'react';
import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import backendService from "../../../services/backendService";

const formatChars = {
   '0': '[0123]',
   '1': '[01]',
   '2': '[12]',
   '3': '[09]',
   '9': '[0123456789]'
};

const EditOtherInformationModal = ({ show, onClose, onSave, data, profileId }) => {
   const [otherInformation, setOtherInformation] = React.useState({});
   const [advList, setAdvList] = React.useState([]);
   const [error, setError] = React.useState(false);
   const focusRef = React.useRef();

   React.useEffect(async () => {
      const getAdv = async () => {
         const res = await backendService.getAdvs();
         setAdvList(res.data)
      }
      try {
         await getAdv();
      } catch (e) {
         console.error(e);
      }
   }, [])

   React.useEffect(() => {
      if (data) {
         setOtherInformation(data);
      }
   }, [data])

   if (!show) {
      return null;
   }
   const onChangeValue = (name, value) => {
      setError(false);
      setOtherInformation(prevState => ({
         ...prevState,
         [name]: value
      }));
   };

   const onSubmit = async () => {
      if (otherInformation?.vacancySource?.startsWith("Другое") && otherInformation?.advertising_comment === '') {
         setError(true);
         focusRef.current.focus();
         return
      } else {
         setOtherInformation(prevState => ({
            ...prevState,
            ['advertising_comment']: ''
         }));
      }
      const data = {
         profileId: profileId,
         otherInformation: JSON.stringify(otherInformation),
      }
      await onSave(data);
      onClose();
   }

   return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
         <div className="bg-white rounded-lg shadow-lg w-1/2 overflow-auto" style={{ height: '90vh' }}>
            <div className="p-4 border-b flex justify-between items-center">
               <h2 className="font-bold text-xl text-black dark:text-white ml-6">Редактирование профиля</h2>
               <button onClick={onClose}
                       className="ml-auto mr-6 bg-gray-100 hover:bg-gray-200 rounded-md p-1 focus:outline-none transition ease-in duration-200">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"/>
                  </svg>
               </button>
            </div>
            <div className="p-4">
               <h2 className={'font-bold text-xl my-4'}>Данные о регистрации</h2>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold"
                         htmlFor="registration_city">Город</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.registration_city}
                        onChange={(e) => onChangeValue("registration_city", e.target.value)}
                     />
                  </div>
               </div>
               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold"
                         htmlFor="registration_house">Дом</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.registration_house}
                        onChange={(e) => onChangeValue("registration_house", e.target.value)}
                     />
                  </div>
               </div>
               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold"
                         htmlFor="registration_housing">Корпус/строение</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.registration_housing}
                        onChange={(e) => onChangeValue("registration_housing", e.target.value)}
                     />
                  </div>
               </div>
               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold"
                         htmlFor="registration_room">Квартира</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.registration_room}
                        onChange={(e) => onChangeValue("registration_room", e.target.value)}
                     />
                  </div>
               </div>
               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold"
                         htmlFor="registration_street">Улица</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.registration_street}
                        onChange={(e) => onChangeValue("registration_street", e.target.value)}
                     />
                  </div>
               </div>

               <h2 className={'font-bold text-xl my-4'}>Общие данные</h2>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="residence_city">Адрес
                     фактического
                     проживания: Город</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.residence_city}
                        onChange={(e) => onChangeValue("residence_city", e.target.value)}
                     />
                  </div>
               </div>
               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="residence_house">Адрес
                     фактического
                     проживания: Дом</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.residence_house}
                        onChange={(e) => onChangeValue("residence_house", e.target.value)}
                     />
                  </div>
               </div>
               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="residence_housing">Адрес
                     фактического
                     проживания: Корпус</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.residence_housing}
                        onChange={(e) => onChangeValue("residence_housing", e.target.value)}
                     />
                  </div>
               </div>
               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="residence_room">Адрес
                     фактического
                     проживания: Квартира</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.residence_room}
                        onChange={(e) => onChangeValue("residence_room", e.target.value)}
                     />
                  </div>
               </div>
               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="residence_street">Адрес
                     фактического
                     проживания: Улица</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.residence_street}
                        onChange={(e) => onChangeValue("residence_street", e.target.value)}
                     />
                  </div>
               </div>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="departmentCode">Код
                     подразделения</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.departmentCode}
                        onChange={(e) => onChangeValue("departmentCode", e.target.value)}
                     />
                  </div>
               </div>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="email">Email</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="email"
                        type="text"
                        value={otherInformation?.email}
                        onChange={(e) => onChangeValue("email", e.target.value)}
                     />
                  </div>
               </div>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="drivingLicens">Водительское
                     удостоверение</label>
                  <input
                     className="ml-2 bg-white rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                     id="drivingLicens"
                     type="checkbox"
                     checked={otherInformation?.drivingLicens}
                     onChange={(e) => onChangeValue("drivingLicens", +e.target.checked)}
                  />
               </div>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="ownCar">Собственная
                     машина</label>
                  <input
                     className="ml-2 bg-white rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                     id="ownCar"
                     type="checkbox"
                     checked={otherInformation?.ownCar}
                     onChange={(e) => onChangeValue("ownCar", +e.target.checked)}
                  />
               </div>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="smoking">Курение</label>
                  <input
                     className="ml-2 bg-white rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                     id="smoking"
                     type="checkbox"
                     checked={otherInformation?.smoking}
                     onChange={(e) => onChangeValue("smoking", +e.target.checked)}
                  />
               </div>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="vacancySource">Откуда
                     узнал</label>
                  <div>
                     <select name="vacancySource"
                             id="vacancySource"
                             value={otherInformation?.vacancySource}
                             onChange={(e) => onChangeValue("vacancySource", e.target.value)}
                     >
                        {advList.length > 0 && advList.map(i => (
                           <option key={i} value={i}>{i}</option>
                        ))}
                     </select>
                     {otherInformation?.vacancySource?.startsWith("Другое") && (
                        <>
                           <label className="ml-4 leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor="advertising_comment">Другое</label>
                           <input
                              className="ml-2 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                              id="link"
                              ref={focusRef}
                              type="text"
                              required={true}
                              value={otherInformation?.advertising_comment}
                              onChange={(e) => onChangeValue("advertising_comment", e.target.value)}
                           />
                           {error && <p className={'text-red-600'}>Обязательное поле</p>}
                        </>
                     )}
                  </div>
               </div>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="familyStatus">Семейное
                     положение</label>
                  <div>
                     <select
                        name="familyStatus"
                        id="familyStatus"
                        value={otherInformation?.familyStatus}
                        onChange={(e) => onChangeValue("familyStatus", +e.target.value)}
                     >
                        <option value="1">Холост / Не замужем</option>
                        <option value="2">Женат / Замужем</option>
                     </select>
                  </div>
               </div>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="sex">Пол</label>
                  <div>
                     <input
                        id="male"
                        type="radio"
                        name="gender"
                        className="mr-2"
                        value={0}
                        checked={otherInformation.sex === 0}
                        onChange={(e) => onChangeValue("sex", +e.target.value)}
                     />
                     <label htmlFor="male">Мужской</label>
                  </div>
                  <div>
                     <input
                        id="female"
                        type="radio"
                        name="gender"
                        className="mr-2"
                        value={1}
                        checked={otherInformation.sex === 1}
                        onChange={(e) => onChangeValue("sex", +e.target.value)}
                     />
                     <label htmlFor="female">Женский</label>
                  </div>
               </div>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="link">Социальная
                     сеть</label>
                  <div>
                     <input
                        className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                        id="link"
                        type="text"
                        value={otherInformation?.link}
                        onChange={(e) => onChangeValue("link", e.target.value)}
                     />
                  </div>
               </div>

               <h2 className={'font-bold text-xl my-4'}>Данные о родителях</h2>

               <div className="flex xl:flex-row flex-col xl:items-start w-full overflow-x-auto">
                  <div>
                     <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="relationDegreeRelatives">Степень
                        родства *</label>
                     <div className="flex xl:block">
                        <div>
                           <input name="nameRelatives" id="2002"
                                  value={otherInformation?.relationDegreeRelatives}
                                  onChange={(e) => onChangeValue("relationDegreeRelatives", e.target.value)}
                                  className="xl:mr-2 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                        <div className="ml-4 xl:ml-0 xl:mt-2">
                           <input name="nameRelatives2" id="2007"
                                  value={otherInformation?.relationDegreeRelatives2}
                                  onChange={(e) => onChangeValue("relationDegreeRelatives2", e.target.value)}
                                  className=" bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>

                        </div>
                     </div>
                  </div>
                  <div>
                     <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="nameRelatives">Ф.И.О.
                        * </label>
                     <div className="flex xl:block">
                        <div>
                           <input name="relationDegreeRelatives" id="2001"
                                  value={otherInformation?.nameRelatives}
                                  onChange={(e) => onChangeValue("nameRelatives", e.target.value)}
                                  className="xl:mr-2 block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                        <div className="ml-4 xl:ml-0 xl:mt-2">
                           <input name="relationDegreeRelatives2" id="2008"
                                  value={otherInformation?.nameRelatives2}
                                  onChange={(e) => onChangeValue("nameRelatives2", e.target.value)}
                                  className="block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>

                        </div>
                     </div>
                  </div>
                  <div>
                     <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="cityRelatives">Город
                        проживания *</label>
                     <div className="flex xl:block">
                        <div>
                           <input name="cityRelatives" id="2003"
                                  value={otherInformation?.cityRelatives}
                                  onChange={(e) => onChangeValue("cityRelatives", e.target.value)}
                                  className="xl:mr-2 block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                        <div className="ml-4 xl:ml-0 xl:mt-2">
                           <input name="cityRelatives2" id="2009"
                                  value={otherInformation?.cityRelatives2}
                                  onChange={(e) => onChangeValue("cityRelatives2", e.target.value)}
                                  className="block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                     </div>
                  </div>
                  <div>
                     <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="jobPlaceRelatives">Место
                        работы/учебы *</label>
                     <div className="flex xl:block">
                        <div>
                           <input name="jobPlaceRelatives" id="2005"
                                  value={otherInformation?.jobPlaceRelatives}
                                  onChange={(e) => onChangeValue("jobPlaceRelatives", e.target.value)}
                                  className="xl:mr-2 block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                        <div className="ml-4 xl:ml-0 xl:mt-2">
                           <input name="jobPlaceRelatives2" id="20011"
                                  value={otherInformation?.jobPlaceRelatives2}
                                  onChange={(e) => onChangeValue("jobPlaceRelatives2", e.target.value)}
                                  className="block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                     </div>
                  </div>
                  <div>
                     <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="jobRelatives">Занимаемая
                        должность *</label>
                     <div className="flex xl:block">
                        <div>
                           <input name="jobRelatives" id="2006"
                                  value={otherInformation?.jobRelatives}
                                  onChange={(e) => onChangeValue("jobRelatives", e.target.value)}
                                  className="xl:mr-2 block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                        <div className="ml-4 xl:ml-0 xl:mt-2">
                           <input name="jobRelatives2" id="20012"
                                  value={otherInformation?.jobRelatives2}
                                  onChange={(e) => onChangeValue("jobRelatives2", e.target.value)}
                                  className="block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                     </div>
                  </div>
                  <div>
                     <label className="leading-7 text-sm text-gray-900 font-semibold"
                            htmlFor="phoneRelatives">Телефон</label>
                     <div className="flex xl:block xl:mr-2">
                        <div>
                           <PhoneInput
                              country={'ru'}
                              onlyCountries={['ru']}
                              inputStyle={{
                                 height: "42px",
                                 width: "222px",
                              }}
                              id="2004"
                              value={otherInformation.phoneRelatives}
                              placeholder={"+71231231212"}
                              autoFormat={false}
                              inputProps={{
                                 name: "phoneRelatives",
                                 onChange: (e) => onChangeValue("phoneRelatives", e.target.value),
                              }}
                           />

                        </div>
                        <div className="ml-4 xl:ml-0 xl:mt-2">
                           <PhoneInput
                              country={'ru'}
                              onlyCountries={['ru']}
                              inputStyle={{
                                 height: "42px",
                                 width: "222px",
                              }}
                              id="20010"
                              value={otherInformation.phoneRelatives2}
                              placeholder={"+71231231212"}
                              autoFormat={false}
                              inputProps={{
                                 name: "phoneRelatives2",
                                 onChange: (e) => onChangeValue("phoneRelatives2", e.target.value),
                              }}
                           />
                        </div>
                     </div>
                  </div>
               </div>

               <h2 className={'font-bold text-xl my-4'}>Данные о образовании</h2>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold"
                         htmlFor="onlySchool">Окончил только школу</label>
                  <input
                     className="ml-2 bg-white rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                     id="onlySchool"
                     type="checkbox"
                     checked={otherInformation?.onlySchool}
                     onChange={(e) => onChangeValue("onlySchool", +e.target.checked)}
                  />
               </div>

               <div className="hidden md:block">
                  <div className="w-full flex justify-between">
                     <div className="flex-1 flex-col h-full">
                        <h2 className="mb-4">Год поступления и окончания *</h2>
                        <div className="flex gap-4">
                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"entryYear1"}></label>
                           <input
                              value={otherInformation?.entryYear1}
                              onChange={(e) => onChangeValue("entryYear1", e.target.value)}
                              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>

                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"exitYear1"}></label>
                           <input
                              value={otherInformation?.exitYear1}
                              onChange={(e) => onChangeValue("exitYear1", e.target.value)}
                              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                     </div>
                     <div className="flex-1 flex-col h-full mx-8">
                        <h2 className="mb-4">Название учебного заведения *</h2>

                        <label className="leading-7 text-md text-gray-900 font-semibold"
                               htmlFor={"schoolName1"}></label>
                        <input
                           value={otherInformation?.schoolName1}
                           onChange={(e) => onChangeValue("schoolName1", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                     </div>
                     <div className="flex-1 flex-col h-full">
                        <h2 className="mb-4">Квалификация, специальность *</h2>

                        <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={"specialty1"}></label>
                        <input
                           value={otherInformation?.specialty1}
                           onChange={(e) => onChangeValue("specialty1", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                     </div>
                  </div>
                  <hr></hr>
                  <div className="w-full flex justify-between mt-4">
                     <div className="flex-1 flex-col h-full">
                        <div className="flex gap-4">
                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"entryYear2"}></label>
                           <input
                              value={otherInformation?.entryYear2}
                              onChange={(e) => onChangeValue("entryYear2", e.target.value)}
                              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"exitYear2"}></label>
                           <input
                              value={otherInformation?.exitYear2}
                              onChange={(e) => onChangeValue("exitYear2", e.target.value)}
                              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                     </div>
                     <div className="flex-1 flex-col h-full mx-8">
                        <label className="leading-7 text-md text-gray-900 font-semibold"
                               htmlFor={"schoolName2"}></label>
                        <input
                           value={otherInformation?.schoolName2}
                           onChange={(e) => onChangeValue("schoolName2", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                     </div>
                     <div className="flex-1 flex-col h-full">
                        <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={"specialty2"}></label>
                        <input
                           value={otherInformation?.specialty2}
                           onChange={(e) => onChangeValue("specialty2", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                     </div>
                  </div>
                  <hr></hr>
                  <div className="w-full flex justify-between mt-4">
                     <div className="flex-1 flex-col h-full">
                        <div className="flex gap-4">
                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"entryYear3"}></label>
                           <input
                              value={otherInformation?.entryYear3}
                              onChange={(e) => onChangeValue("entryYear3", e.target.value)}
                              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"exitYear3"}></label>
                           <input
                              value={otherInformation?.exitYear3}
                              onChange={(e) => onChangeValue("exitYear3", e.target.value)}
                              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                     </div>
                     <div className="flex-1 flex-col h-full mx-8">
                        <label className="leading-7 text-md text-gray-900 font-semibold"
                               htmlFor={"schoolName3"}></label>
                        <input
                           value={otherInformation?.schoolName3}
                           onChange={(e) => onChangeValue("schoolName3", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                     </div>
                     <div className="flex-1 flex-col h-full">
                        <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={"specialty3"}></label>
                        <input
                           value={otherInformation?.specialty3}
                           onChange={(e) => onChangeValue("specialty3", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                     </div>
                  </div>
               </div>

               <h2 className={'font-bold text-xl my-4'}>Данные о прошлой работе</h2>

               <div className="mb-4">
                  <label className="leading-7 text-md text-gray-900 font-semibold"
                         htmlFor="noWork">Не работал</label>
                  <input
                     className="ml-2 bg-white rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                     id="noWork"
                     type="checkbox"
                     checked={otherInformation?.noWork}
                     onChange={(e) => onChangeValue("noWork", +e.target.checked)}
                  />
               </div>

               <div className="hidden md:block overflow-x-scroll">
                  <div className="w-full flex justify-between items-end">
                     <div className="flex-1 flex-col h-full">
                        <h2 className="mb-4">Дата приема и увольнения*</h2>
                        <div className="flex gap-1">
                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"workHiringDate1"}>{}</label>
                           <InputMask name={"workHiringDate1"} mask="09.19.2399" formatChars={formatChars} required
                                      value={otherInformation.workHiringDate1}
                                      onChange={(e) => onChangeValue("workHiringDate1", e.target.value)}
                                      className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"workQuitDate1"}>{}</label>
                           <InputMask name={"workQuitDate1"} mask="09.19.2399" formatChars={formatChars} required
                                      value={otherInformation.workQuitDate1}
                                      onChange={(e) => onChangeValue("workQuitDate1", e.target.value)}
                                      className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                        </div>
                     </div>
                     <div className="flex-1 flex-col h-full mx-4">
                        <h2 className="mb-4">Предприятие*</h2>
                        <input
                           value={otherInformation?.workPlace1}
                           onChange={(e) => onChangeValue("workPlace1", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                     </div>
                     <div className="flex-1 flex-col h-full">
                        <h2 className="mb-4">Квалификация, специальность*</h2>
                        <input
                           value={otherInformation?.workJob1}
                           onChange={(e) => onChangeValue("workJob1", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                     </div>
                     <div className="flex-1 flex-col h-full mx-4">
                        <h2 className="mb-4">Выполняемые функции*</h2>
                        <input
                           value={otherInformation?.workFunctions1}
                           onChange={(e) => onChangeValue("workFunctions1", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                     </div>
                     <div className="flex-1 flex-col h-full">
                        <h2 className="mb-4">Причина увольнения*</h2>
                        <input
                           value={otherInformation?.workQuitReason1}
                           onChange={(e) => onChangeValue("workQuitReason1", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                     </div>
                  </div>
                  <hr></hr>
                  <div className="w-full flex justify-between items-end mt-4 ">
                     <div className="flex-1 flex-col h-full">
                        <h2 className="mb-4">Дата приема и увольнения</h2>
                        <div className="flex gap-1">
                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"workHiringDate2"}>{}</label>
                           <InputMask name={"workHiringDate2"} mask="09.19.2399" formatChars={formatChars} required
                                      value={otherInformation?.workHiringDate2}
                                      onChange={(e) => onChangeValue("workHiringDate2", e.target.value)}
                                      className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                           />
                           <label className="leading-7 text-md text-gray-900 font-semibold"
                                  htmlFor={"workQuitDate2"}>{}</label>
                           <InputMask name={"workQuitDate2"} mask="09.19.2399" formatChars={formatChars} required
                                      value={otherInformation?.workQuitDate2}
                                      onChange={(e) => onChangeValue("workQuitDate2", e.target.value)}
                                      className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                           />
                        </div>
                     </div>
                     <div className="flex-1 flex-col h-full mx-4">
                        <h2 className="mb-4">Предприятие</h2>
                        <input
                           value={otherInformation?.workPlace2}
                           onChange={(e) => onChangeValue("workPlace2", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                     </div>
                     <div className="flex-1 flex-col h-full">
                        <h2 className="mb-4">Квалификация, специальность</h2>
                        <input
                           value={otherInformation?.workJob2}
                           onChange={(e) => onChangeValue("workJob2", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                     </div>
                     <div className="flex-1 flex-col h-full mx-4">
                        <h2 className="mb-4">Выполняемые функции</h2>
                        <input
                           value={otherInformation?.workFunctions2}
                           onChange={(e) => onChangeValue("workFunctions2", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                     </div>
                     <div className="flex-1 flex-col h-full">
                        <h2 className="mb-4">Причина увольнения</h2>
                        <input
                           value={otherInformation?.workQuitReason2}
                           onChange={(e) => onChangeValue("workQuitReason2", e.target.value)}
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                     </div>
                  </div>

                  <div className="mb-4">
                     <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="prevSalary">Размер з/пл
                        на последнем месте работы (в руб.)</label>
                     <div>
                        <input
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                           id="prevSalary"
                           type="text"
                           value={otherInformation?.prevSalary}
                           onChange={(e) => onChangeValue("prevSalary", e.target.value)}
                        />
                     </div>
                  </div>

                  <div className="mb-4">
                     <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="requestedSalary">Какую
                        з/пл
                        хочет (в руб.)</label>
                     <div>
                        <input
                           className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                           id="prevSalary"
                           type="text"
                           value={otherInformation?.requestedSalary}
                           onChange={(e) => onChangeValue("requestedSalary", e.target.value)}
                        />
                     </div>
                  </div>

                  <div className="mb-4">
                     <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor="newWorkStartDate">Когда
                        готов
                        приступать</label>
                     <div>
                        <InputMask name={"newWorkStartDate"} mask="09.19.2399" formatChars={formatChars} required
                                   value={otherInformation?.newWorkStartDate}
                                   onChange={(e) => onChangeValue("newWorkStartDate", e.target.value)}
                                   className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        />
                     </div>
                  </div>

                  {otherInformation?.conviction !== 2 ? (
                     <div className="mb-4">
                        <label className="leading-7 text-md text-gray-900 font-semibold"
                               htmlFor="conviction">Имеется ли судимость</label>
                        <input
                           className="ml-2 bg-white rounded border border-gray-300 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                           id="conviction"
                           type="checkbox"
                           checked={otherInformation?.conviction}
                           onChange={(e) => onChangeValue("conviction", +e.target.checked)}
                        />
                     </div>
                  ) : (
                     <div className="mb-4">
                        <label className="leading-7 text-md text-gray-900 font-semibold"
                               htmlFor="criminal_record_extinguished">Cудимость погашена</label>
                        <div>
                           <input
                              className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8"
                              id="prevSalary"
                              type="text"
                              value={otherInformation?.criminal_record_extinguished}
                              onChange={(e) => onChangeValue("criminal_record_extinguished", e.target.value)}
                           />
                        </div>
                     </div>
                  )}
               </div>
            </div>
            <div className="p-4 border-t flex justify-between">
               <button onClick={onClose} className="px-4 py-2 bg-red-500 text-white rounded mr-2">Закрыть</button>
               <button onClick={onSubmit} className="px-4 py-2 bg-blue-500 text-white rounded">Сохранить</button>
            </div>
         </div>
      </div>
   );
};

export default EditOtherInformationModal;