import React from 'react'
import { connect } from "react-redux"
import { togglePatentFileModal } from '../../redux/manager/managerActions'

// компонент в профиле пользователя, отображающий все прикрепленные к нему файлы
function PatentFileCard({files, fileClickHandler, toggleDeleteModal, ...props}) {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Чеки оплаты патента
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Нажмите чтобы посмотреть подробнее
        </p>
      </div>
      <div className="border-t border-gray-200" style={{ maxHeight: '700px', overflowY: 'auto' }}>
        <dl className="grid gap-4 grid-cols-2 p-4">
          {files && files.length > 0 && (
            <>
              {files.map((file, idx) => (
                <div key={idx} className="bg-gray-100 shadow-md rounded p-3 flex flex-col">
                  <div className="pt-2 px-1 flex items-start mb-4">
                    <h3 className="text-gray-800 text-sm font-semibold pr-1 truncate">{file.originalname}</h3>
                      <button onClick={()=>toggleDeleteModal(true, file.file_token, "Файл", file.originalname)} className="p-1 flex items-center justify-center ml-auto bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 rounded-md">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                  </div>
    
                  <div className="group relative h-52 bg-white rounded mb-2 mt-auto">
                    <img download className="object-cover h-full rounded absolute left-1/2 -translate-x-1/2 transform" src={`/file?file_token=${file.file_token}`} alt={file.originalname}/>
                    <div className="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly">
                      <a
                        href={`/file?file_token=${file.file_token}`}
                        className="hover:scale-110 text-white opacity-0 transform translate-y-3 focus:outline-none group-hover:translate-y-0 group-hover:opacity-100 transition"
                        download
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-14 w-14 bg-white text-black rounded-full p-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                      </a>
                    </div>
                  </div>
    
                  <button onClick={()=>fileClickHandler(`/file?file_token=${file.file_token}`, file.originalname)} className="hidden xl:flex mt-auto w-full py-2 px-2 justify-start items-center text-sm bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center font-semibold rounded whitespace-nowrap ">
                    Показать
                  </button>
                </div>
              ))}
            </>
          )
        }
          <div onClick={()=>props.togglePatentFileModal(true)} className="bg-gray-100 hover:bg-gray-200 shadow-md rounded flex min-h-full h-48 items-center justify-center cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 bg-white text-black rounded-full p-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </div>
        </dl>
      </div>
    </>
  )
}
const mapDispatchToProps = {
  togglePatentFileModal
}

export default connect(null, mapDispatchToProps)(PatentFileCard)
