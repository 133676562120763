import { useQuery } from "@apollo/client"
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { GET_STAGES } from "../graphql/Queries"
import { changeArrowsObj, changeCurrentStatus, toggleStatusDropdown } from "../redux/manager/managerActions"

function StatusList({ companyId, ...props }) {
  const { error, loading, data, previousData } = useQuery(GET_STAGES, {
    variables: { companyId },
    pollInterval: 60000,
  })
  if (error) console.log(`Error graphql: ${error}`)

  // нажатие на статус
  const statusClickHandler = (stageId, statusId, title, viewOnly) => {
    window.sessionStorage.setItem('numberPage', 0);
    props.changeCurrentStatus(stageId, statusId, title, viewOnly, props.currentStatusId)
  }
      
  // если произошло обновление вывод стрелок
  useEffect(() => {
    if (previousData){
      let arrowsObject = {}
      // прохожусь по всем этапам
      previousData.Stages.forEach(stage => {
        let newStage = data.Stages.find(nStage => nStage.stage_id === stage.stage_id)
        // прохожусь по всем статусам
        stage?.statuses.forEach(status => { 
          let newStatus = newStage.statuses.find(nStatus => nStatus.status_id === status.status_id )
          // количество увеличилось
          if (newStatus.profile_count > status.profile_count) {
              arrowsObject[status.status_id] = "green"
          // количество уменьшилось
          } else if (newStatus.profile_count < status.profile_count) {
            arrowsObject[status.status_id] = "red"
          // количество не изменилось
          } else arrowsObject[status.status_id] = ""
        })
      })
      props.changeArrowsObj(arrowsObject)
    }
  }, [data])

  return (

    <div className="flex flex-col items-center justify-center bg-white rounded-lg border shadow-lg md:sticky top-2 overflow-hidden">
      <div className="px-4 py-5 sm:px-6 border-b w-full">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
          Этапы и статусы
        </h3>
      </div>
      {loading 
        ? (
          <div className="w-64 h-72 flex items-center justify-center">
            <div className="w-32 h-32 ease-linear rounded-full border-4 border-t-4 border-gray-200 loader"></div>
          </div>
        ) 
        : <>
            <ul className="flex flex-col divide divide-y w-full">
            {data?.Stages.map((stage, index) =>(
              <div key={index}>
                <li key={`stages-id-${stage.stage_id}`} className="flex flex-row">
                  <div className="select-none items-center py-2 pr-4 w-full flex">

                      <div className="px-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>

                      <div className="w-full">
                        <div className="font-medium dark:text-white underline">{stage.title}</div>
                        <div className="text-gray-600 dark:text-gray-200 text-sm w-full">{`${stage.profile_count} анкет`}</div>
                      </div>

                      <div className="ml-auto">
                        {stage.only_view && (
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                          </svg>
                        )}
                      </div>

                  </div>
                </li>
                {stage?.statuses.map(status => (
                  <li onClick={()=>statusClickHandler(stage.stage_id, status.status_id, status.title, stage.only_view)} key={`status-id-${status.status_id}`} className={`flex flex-row transition ease-in duration-200 ${props.currentStatusId === status.status_id && ("bg-gray-100")}`}>
                    <div className="select-none cursor-pointer items-center py-1 pr-4 w-full flex border-t">

                      <div className="flex items-center justify-start">
                          {props.arrows[status.status_id] === "green" ? 
                            <div className="ml-8">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                              </svg> 
                            </div>
                          : props.arrows[status.status_id] === "red" ?
                            <div className="ml-8">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                            </div>
                          : <div className="ml-12">&nbsp;</div>
                          }
                        <div className="ml-2 break-words">
                          <div className="font-medium ">{status.title}</div>
                          <div className="text-gray-600 dark:text-gray-200 text-sm w-full">{`${status.profile_count} анкет`}</div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </div>
            ))}
          </ul>
        </>
      }
    </div>
  )
}

const mapDispatchToProps = {
  changeCurrentStatus, toggleStatusDropdown, changeArrowsObj
}

const mapStateToProps = state => ({
  currentStatusId: state.manager.currentStatusId,
  arrows: state.manager.arrows
})

export default connect(mapStateToProps, mapDispatchToProps)(StatusList)
