import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import App from './App'
import './index.css'
import { rootReducer } from './redux/rootReducer'


// создаю стор для redux
const store = createStore(rootReducer, compose(
  applyMiddleware(
      thunk
  ),
//   window.navigator.userAgent.includes('Chrome') ?
//       window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : compose
))


const app = (
    <Provider store={store}>
        <App />
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'))
