import { useMutation } from "@apollo/client"
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
import { GET_MANAGER, GET_PROFILE, UPDATE_MANAGER_FIELD, UPDATE_PROFILE_INFO } from "../../graphql/Queries"
import { hideSmallAlert, showSmallAlert } from "../../redux/alert/alertActions"
import CitizenshipInput from "./CitizenshipInput"
import JobAddressInput from "./JobAddressInput"
import ProfessionSelect from "./ProfessionSelect"
import {client} from "../../App";

function InputField({
  field, 
  data, 
  manager, 
  profileIdFromProps=null, 
  isRevise=false, 
  reviseId, 
  getReviseData, 
  skipPageResetRef, 
  ...props
}) {
  const [toggle, setToggle] = useState(true)
  const [value, setValue] = useState(data)
  const [workplaceId, setWorkplaceId] = useState()

  // айди анкеты в которой происходит изменение поля
  const profileId = useMemo(() => {
    if (profileIdFromProps) return profileIdFromProps
    else return props.match.params.id
  }, [profileIdFromProps])

  // если есть данные поля из пропсов - устанавливаю его в локальный стейт
  useEffect(() => {
    setValue(data)
  }, [data])

  // обновление поля в странице профиля
  const [updateProfileField] = useMutation(UPDATE_PROFILE_INFO, { 
      refetchQueries: [{query: GET_PROFILE, variables: { companyId: localStorage.getItem('company_id'), profileId}}],
      awaitRefetchQueries: true,
      onError: (error) => {
        // отображение алерта ошибки
        props.showSmallAlert("Ошибка при обновлении", "danger")
        setTimeout(() => {
          props.hideSmallAlert()
        }, 5000);
        console.error(error.message)
    },
  })

  // обновление поля в странице сверки
  const [updateReviseField] = useMutation(UPDATE_PROFILE_INFO, { 
      onError: (error) => {
        // отображение алерта ошибки
        props.showSmallAlert("Ошибка при обновлении", "danger")
        setTimeout(() => {
          props.hideSmallAlert()
        }, 5000);
        console.error(error.message)
    },
  })

  // обновление поля в странице менеджера
  const [updateManagerField] = useMutation(UPDATE_MANAGER_FIELD,
    { refetchQueries: [{query: GET_MANAGER}],
      awaitRefetchQueries: true,
      onError: (error) => {
        props.showSmallAlert("Ошибка при обновлении", "danger")
        setTimeout(() => {
          props.hideSmallAlert()
        }, 5000);
        console.error(error.message)
    },
  })

  // обработчик двойного нажатия на поле
  const dbClickHandler = () => {
    if (field === "dogovorExpired") return;
    setToggle(false)
  }
  // нажатие на x - скрывает инпут
  const closeInput = () => {
    setValue(data)
    setToggle(true)
  }
  // подтверждение изменения
  const applyButtonHandler = async () => {
    if (field === "patentDate") {
      window.history.go(0)
    }
    // страница менеджера
    if (manager) {
      await updateManagerField({ variables: {
        field, value
        }
      })
      if (field === "name") {
        localStorage.setItem("manager_name", value)
      }
    // страница сверки
    } else if (isRevise) {
      // отмена сбрасывания настроек таблицы
      skipPageResetRef.current = true
      await updateReviseField({ variables: {
        profileId,
        companyId: localStorage.getItem("company_id"),
        field, value
        }
      })
      // обновлние сверки
      getReviseData()
    // страница профиля
    } else {
      await updateProfileField({ variables: {
          profileId,
          companyId: localStorage.getItem("company_id"),
          field,
          value: workplaceId ? workplaceId : value,
        },
      })
      setWorkplaceId(null);
      // Обновление запроса в кэше Apollo
      client.cache.modify({
        fields: {
          Expiring_profiles(existingData) {
            return {
              ...existingData,
              // Обновленные поля запроса
            };
          },
        },
      })}
    setToggle(true)
  }
  return (
    <>
      {/* переключатель инпута и простого текста по двойному клику */}
      {toggle
        ? <p onDoubleClick={dbClickHandler} className="mt-1 sm:flex-1 text-sm text-gray-900 bg-transparent sm:mt-0">{data}&nbsp;</p>
        : (
          <div className="flex flex-1 items-center">
            {field.toLowerCase().includes('date') 
            ? <input  
                className="w-full py-2 pl-3 text-left bg-white rounded-lg shadow-md border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm" 
                value={value}
                type="date"
                onChange={e => setValue(e.target.value)}
              />
            : field === "citizenship"
            ? <CitizenshipInput value={value} setValue={setValue}/>
            : field === "birthplace"
            ? <CitizenshipInput value={value} setValue={setValue}/>
            : field === "jobAddress"
            ? <JobAddressInput value={value} setValue={setValue} setWorkplaceId={setWorkplaceId}/>
            : field === "job"
            ? <ProfessionSelect value={value} setValue={setValue}/>
            : <input  
                className="w-full py-2 pl-3 text-left bg-white rounded-lg shadow-md border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm" 
                value={value}
                type="text"
                onChange={e => setValue(e.target.value)}
              />
            }
            <button onClick={closeInput} className="h-9 w-9 mx-2 p-2 bg-red-500 hover:bg-red-700 flex items-center justify-center rounded text-white shadow-md focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <button onClick={applyButtonHandler} className="h-9 w-9 p-2 bg-green-500 hover:bg-green-700 flex items-center justify-center rounded text-white shadow-md focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </button>
          </div>
        ) 
      }
    </>
  )
}

const mapDispatchToProps = {
  showSmallAlert,
  hideSmallAlert
}
export default connect(null, mapDispatchToProps)(withRouter(InputField))
