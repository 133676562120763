import { useMutation } from "@apollo/client";
import { Popover, Transition } from '@headlessui/react';
import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { connect } from "react-redux";
import { GET_PROFILES, GET_STAGES, UPDATE_PROFILE_TO_BACK_STAGE, UPDATE_PROFILE_TO_NEXT_STAGE, UPDATE_STATUS_FOR_PROFILE } from "../graphql/Queries";
import { commentInputClose, commentInputOpen, toggleLoader, toggleStatusDropdown } from "../redux/manager/managerActions";
import { Portal } from '../services/Portal';
import ReasonRow from './ReasonRow';

function ButtonRow(props) {
  const commentRef = useRef("")

  // библиотека popper js для отображения дропдаунов
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-end',
  })

  const [updateStatusForProfiles, { loading: statusMutationLoading }] = useMutation(UPDATE_STATUS_FOR_PROFILE)
  const [updateProfilesToNextStage, { loading: stageMutationLoading }] = useMutation(UPDATE_PROFILE_TO_NEXT_STAGE)
  const [updateProfilesToBackStage, { loading: rejectMutationLoading }] = useMutation(UPDATE_PROFILE_TO_BACK_STAGE)

  // перевод анкет в следующий статус
  const nextStatusHandler = () => {
    props.commentInputClose()
    props.toggleLoader()
    
    // перевод в отказ
    if (props.isReject){
      updateProfilesToBackStage({ variables: {
          profilesId: props.selectedProfilesIds,
          companyId: +localStorage.getItem('company_id'),
          comment: commentRef.current.value
        },
        refetchQueries: [
          {query: GET_STAGES, variables: {companyId: +localStorage.getItem("company_id")}},
          {query: GET_PROFILES, variables: { companyId: +localStorage.getItem('company_id'), statusId: +props.currentStatusId}},
        ],
        awaitRefetchQueries: true
      })
    }
    // перевод в другой этап
    if (props.selectedNextStage) {
      updateProfilesToNextStage({ variables: {
        profilesId: props.selectedProfilesIds,
        companyId: localStorage.getItem('company_id'),
        comment: commentRef.current.value
      },
        refetchQueries: [
          {query: GET_STAGES, variables: {companyId: +localStorage.getItem("company_id")}},
          {query: GET_PROFILES, variables: { companyId: +localStorage.getItem('company_id'), statusId: +props.currentStatusId}},
        ],
        awaitRefetchQueries: true
      })
      // перевод в другой статус
    } else if (props.selectedNextStatus) {
        updateStatusForProfiles({ variables: { 
          profilesId: props.selectedProfilesIds,
          companyId: localStorage.getItem('company_id'),
          newStatus: props.selectedNextStatus,
          comment: commentRef.current.value
        }, 
        refetchQueries: [
          {query: GET_STAGES, variables: {companyId: +localStorage.getItem("company_id")}},
          {query: GET_PROFILES, variables: { companyId: +localStorage.getItem('company_id'), statusId: +props.currentStatusId}},
        ],
        awaitRefetchQueries: true
      })
    }
  }
  return (
    <>
      {props.isCommentInputOpen ? <ReasonRow complete={nextStatusHandler} commentRef={commentRef} /> : (
        <>
         {props.backStage && (
          <button onClick={()=>props.commentInputOpen("Введите причину отказа", "", false, true)} type="button" disabled={props.selectedProfilesIds.length === 0} className="w-11/12 py-2 px-4 flex justify-center items-center bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md rounded-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed">
          {rejectMutationLoading
              ? (
                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 16 16">
                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                  <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                </svg>
              ) 
              : (
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              )}
            {props.backStage.title}
          </button>
         )}

          <div className={`flex shadow-md rounded-lg relative ${props.backStage ? "md:ml-4 md:mt-0 mt-4 w-11/12" : "w-full"}`}>
            <button onClick={()=>props.commentInputOpen(`Причина перевода в "${props.nextStatus?.title ? props.nextStatus.title : props.nextStage.title}"`, 
                                                        props.nextStatus?.status_id ? props.nextStatus.status_id : props.nextStage.stage_id, 
                                                        props.nextStatus?.status_id ? (false) : (true))} 
              type="button" disabled={props.selectedProfilesIds.length === 0 || ((props.nextStage?.title === undefined) && (props.nextStatus?.title === undefined) && (props.previousStatuses?.title === undefined))}
              className="w-11/12 py-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold rounded-l-lg whitespace-nowrap border-r border-white disabled:opacity-50 disabled:cursor-not-allowed"  
            >
              {statusMutationLoading || stageMutationLoading
              ? (
                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 16 16">
                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                  <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                </svg>
              ) 
              : (
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              )}

              {props.nextStatus?.title ? props.nextStatus.title : props.nextStage?.title ? props.nextStage.title : "Некуда переводить"}
            </button>
            <Popover as={React.Fragment}>
              <Popover.Button ref={setReferenceElement} disabled={props.selectedProfilesIds.length === 0 || ((props.nextStage?.title === undefined) && (props.nextStatus?.title === undefined) && (props.previousStatuses[0]?.title === undefined))} className={`${!props.backStage && "md:w-28"} py-2 px-4 flex justify-center items-center bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold rounded-r-lg whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed`}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                </svg>
              </Popover.Button>
              <Portal>
                <Transition
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                  className="absolute z-50 transform"
                >
                  <Popover.Panel 
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                  >
                    <div className="w-56 rounded-md shadow-2xl bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {props.previousStatuses.length > 0 && (
                          <>
                            {props.previousStatuses.map(previousStatus => (
                              <div key={previousStatus.status_id} onClick={()=>props.commentInputOpen(`Причина перевода в "${previousStatus.title}"`, previousStatus.status_id)} className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-pointer" role="menuitem">
                                <span className="flex flex-col">
                                  <span>
                                    {previousStatus.title}
                                  </span>
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                        <div className="block px-4 py-2 text-md bg-gray-100 text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-not-allowed" disabled role="menuitem">
                          <span className="flex flex-col">
                            <span>
                              {props.tableTitle}
                            </span>
                          </span>
                        </div>   
                        {props.nextStatus && (
                          <div onClick={()=>props.commentInputOpen(`Причина перевода в "${props.nextStatus.title}"`, props.nextStatus.status_id)} className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-pointer" role="menuitem">
                            <span className="flex flex-col">
                              <span>
                                {props.nextStatus.title}
                              </span>
                            </span>
                          </div>   
                        )}
                        {props.subsequentStatuses.length > 0  && (
                          <>
                            {props.subsequentStatuses.map(subsequentStatus => (
                              <div key={subsequentStatus.status_id} onClick={()=>props.commentInputOpen(`Причина перевода в "${subsequentStatus.title}"`, subsequentStatus.status_id)} className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-pointer" role="menuitem">
                                <span className="flex flex-col">
                                  <span>
                                    {subsequentStatus.title}
                                  </span>
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                        {props.nextStage && (
                          <>
                            <div onClick={()=>props.commentInputOpen(`Причина перевода в "${props.nextStage.title}"`, props.nextStage.stage_id, true)} className="border-t block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-pointer" role="menuitem">
                              <span className="flex flex-col">
                                <span>
                                  {props.nextStage.title}
                                </span>
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Portal>
            </Popover>
          </div>
        </>
      )}
    </>
  )
}

const mapDispatchToProps = {
  commentInputOpen, toggleStatusDropdown, commentInputClose, toggleLoader
}
const mapStateToProps = state => ({
  currentStatusId: state.manager.currentStatusId,
  currentStatusProfiles: state.manager.currentStatusProfiles,
  selectedProfilesIds: state.manager.selectedProfilesIds,
  isCommentInputOpen: state.manager.isCommentInputOpen,
  nextStatus: state.manager.nextStatus,
  previousStatuses: state.manager.previousStatuses,
  subsequentStatuses: state.manager.subsequentStatuses,
  nextStage: state.manager.nextStage,
  tableTitle: state.manager.tableTitle,
  isStatusDropdownOpen: state.manager.isStatusDropdownOpen,
  selectedNextStage: state.manager.selectedNextStage,
  selectedNextStatus: state.manager.selectedNextStatus,
  isReject: state.manager.isReject,
  backStage: state.manager.backStage
})

export default connect(mapStateToProps, mapDispatchToProps)(ButtonRow)
