import React from 'react'
import Header from '../components/Header'
import StatusTable from '../components/Settings/StatusTable'
import { useHistory } from "react-router-dom"

export default function CompanyPage() {
  let history = useHistory()
  return (
    <>
      <Header />
      <section className="flex flex-col xl:mx-24 md:mx-12 sm:mx-4 mx-2 ">
        <button onClick={()=>history.push("/settings")} className="w-32 py-2 px-4 flex justify-start items-center text-sm bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center font-semibold rounded whitespace-nowrap ">
          <div className="w-6 h-6 mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 17l-5-5m0 0l5-5m-5 5h12" />
            </svg>
          </div>
          Назад
        </button>

        <div className="mt-4">
          <StatusTable />
        </div>

      </section>
    </>
  )
}
