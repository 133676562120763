import {useLazyQuery, useMutation, useQuery} from "@apollo/client"
import React, {useState} from 'react'
import {connect} from "react-redux"
import {withRouter} from "react-router"
import Header from '../components/Header'
import {Loader} from '../components/Loader'
import {ButtonsCard} from '../components/Profile/ButtonsCard'
import DeleteModal from '../components/Profile/DeleteModal'
import FileCard from '../components/Profile/FileCard'
import FileModal from '../components/Profile/FileModal'
import InfoCard from '../components/Profile/InfoCard'
import LastStatus from '../components/Profile/LastStatus'
import StatusButtons from '../components/Profile/StatusButtons'
import StatusHistory from '../components/Profile/StatusHistory'
import UserInfo from '../components/Profile/UserInfo'
import GeneratorField from "../components/Profile/GeneratorField"
import SmallAlert from '../components/SmallAlert'
import {
    GET_PROFILE,
    GET_STATUS,
    REMOVE_PROFILE_DOCUMENT,
    REMOVE_PROFILE_FILE, UPDATE_COMMENT
} from "../graphql/Queries"
import { changeCompany, toggleDocumentModal } from '../redux/manager/managerActions'
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import Rashod from "../components/Profile/Rashod"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {usePopperTooltip} from "react-popper-tooltip";
import SwitchCompany from "../components/Profile/SwitchCompany";
import {hideSmallAlert, showSmallAlert} from "../redux/alert/alertActions";
import AnyCompany from "../components/Profile/AnyCompany";
import EmptyModalButton from "../components/Profile/PatentPayment";
import PatentFileCard from "../components/Profile/PatentFileCard";
import PatentFileModal from "../components/Profile/PatentFileModal";
import DuplicatedProfile from "../components/Profile/DuplicatedProfile";
import date from 'date-and-time';
import backendService from "../services/backendService";
import OneCStatus from "../components/Profile/1cStatus";
import EditOtherInformationModal from "../components/Profile/Modals/EditOtherInformationModal";

const dateStart1C = new Date('2024-05-21T00:00:00');
function UserProfile(props) {
    const [imgPreviewOpen, setImgPreviewOpen] = useState(false)
    const [imgPreviewURL, setImgPreviewURL] = useState("")
    const [imgPreviewName, setImgPreviewName] = useState("")
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [tokenToDelete, setTokenToDelete] = useState("")
    const [typeToDelete, setTypeToDelete] = useState("")
    const [nameToDelete, setNameToDelete] = useState("")
    const [comment, setComment] = useState("")
    const [commentDisabled, setCommentDisabled] = useState(false);
    const [dateHistory, setDateHistory] = useState();
    const [sent1C, setSent1C] = useState(0);
    const [sent1CDate, setSent1CDate] = useState();
    const [otherInformation, setOtherInformation] = useState();
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [userError, setUserError] = useState("");
    const [documentInfo, setDocumentInfo] = useState({});

    const [showEditOtherInfoModal, setShowEditOtherInfoModal] = useState(false);

    const [removeProfileFile] = useMutation(REMOVE_PROFILE_FILE)
    const [removeProfileDocument] = useMutation(REMOVE_PROFILE_DOCUMENT)
    const [updateComment] = useMutation(UPDATE_COMMENT);

    let {error, loading, data, refetch: refetchProfile} = useQuery(GET_PROFILE, {
        variables: {
            companyId: localStorage.getItem('company_id'),
            profileId: props.match.params.id
        },
        fetchPolicy: "cache-and-network",
        onCompleted: () => {
            getStatusInfo();
        }
    })
    if (error) console.log(`Error! ${error}`)

    let [getStatusInfo, {error: statusError, loading: statusLoading, data: statusData}] = useLazyQuery(GET_STATUS, {
        variables: {
            companyId: +localStorage.getItem('company_id'),
            statusId: +data?.Employee_profile?.status_id
        },
        fetchPolicy: "no-cache"
    })
    if (statusError) console.log(`statusError! ${statusError}`)

    React.useEffect(() => {
        if (data) {
            setComment(data.Employee_profile.comment_employee)
            setDateHistory(data?.Employee_profile.status_history.at(-1)?.date && date.parse(data?.Employee_profile.status_history.at(-1)?.date, 'DD.MM.YYYY HH:mm'));
            setSent1C(data?.Employee_profile?.is_send_to_1c);
            setSent1CDate(data?.Employee_profile.send_to_1c_time);
            const other_information = JSON.parse(data?.Employee_profile?.other_information);
            setOtherInformation(other_information)

            if (data.Employee_profile.dogovorExpired) {
                setDocumentInfo({
                    dogovorExpired: data.Employee_profile.dogovorExpired ? data.Employee_profile.dogovorExpired + " дней" : "нет данных"
                })
            }
        }
    }, [data])

    const changeCompanyForFindUser = async () => {
        await backendService.getCompany(props.match.params.id)
           .then(res => {
               console.log(res)
               console.log(res.status)
               if (res.status === 200 && res.data) {
                   console.log(res.data)
                   props.changeCompany(res.data)
                   window.location.reload();
               }
           })
           .catch(e => {
               setUserError(e.response.data)
           });
    }

    const onSaveComment = async () => {
        setCommentDisabled(true);
        await updateComment({
            variables: {
                profile_id: props.match.params.id,
                comment: comment,
            }
        })
        setCommentDisabled(false);
    }

    // отображение файла справа
    const fileClickHandler = (url, name) => {
        setImgPreviewOpen(true)
        setImgPreviewURL(url)
        setImgPreviewName(name)
    }

    // открытие модалки удаления файла или документа
    const toggleDeleteModal = (toggle, token = "", type = "", name = "") => {
        setDeleteModalOpen(toggle)
        setTokenToDelete(token)
        setTypeToDelete(type)
        setNameToDelete(name)
    }

    // удаление файла
    const deleteFile = (token = "") => {
        removeProfileFile({
            variables: {
                profileId: props.match.params.id,
                companyId: +localStorage.getItem('company_id'),
                fileToken: token
            },
            refetchQueries: [
                {
                    query: GET_PROFILE, variables: {
                        companyId: localStorage.getItem('company_id'),
                        profileId: props.match.params.id
                    }
                }
            ],
            awaitRefetchQueries: true
        })
    }

    // удаление документа
    const deleteDocument = (token = "") => {
        removeProfileDocument({
            variables: {
                profileId: props.match.params.id,
                companyId: +localStorage.getItem('company_id'),
                fileToken: token
            },
            refetchQueries: [
                {
                    query: GET_PROFILE, variables: {
                        companyId: localStorage.getItem('company_id'),
                        profileId: props.match.params.id
                    }
                }
            ],
            awaitRefetchQueries: true
        })
    }
    let currentCompanyId = +localStorage.getItem('company_id');

    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip();

    const submitTo1C = async () => {
        try {
            setLoadingRequest(true)
            const data = {
                profile_id: props.match.params.id
            }
            await backendService.sendTo1C(JSON.stringify(data))
               .then(data => {
                   setSent1C(true);
                   setSent1CDate(date.format(new Date(), 'HH:mm DD.MM.YYYY'));
               })
               .catch(e => {
                   throw e;
               })
               .finally(() => {
                   setLoadingRequest(false);
               });
            window.alert("Анкета отправлена");
        } catch (e) {
            window.alert("Произошла ошибка. Не удалось отправить анкету")
        }
    }

    const openEditOtherInfoModal = () => {
        document.body.style.overflow = 'hidden'
        setShowEditOtherInfoModal(true);
    };

    const closeEditOtherInfoModal = () => {
        document.body.style.overflow = ''
        setShowEditOtherInfoModal(false);
    };

    const updateOtherInformation = async (data) => {
        try {
            await backendService.updateOtherInformation(data);
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }

    const sendTo1C = (
       <div className="mt-2">
           <button
              type="button"
              disabled={loadingRequest}
              className="w-full inline-flex justify-center items-center gap-3 px-4 py-2 text-sm font-medium text-white bg-yellow-300 border border-transparent rounded-md hover:bg-yellow-400 transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={submitTo1C}
           >
               Отправить анкету в 1С {loadingRequest && <span className={'text-center'}><Loader mini={true}/></span>}
           </button>

           <button
              type="button"
              className="mt-2 w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-400 border border-transparent rounded-md hover:bg-blue-500 transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={openEditOtherInfoModal}
           >
               Редактировать доп. сведения
           </button>
           <EditOtherInformationModal
              data={otherInformation}
              profileId={props.match.params.id}
              show={showEditOtherInfoModal}
              onClose={closeEditOtherInfoModal}
              onSave={updateOtherInformation}
           />
       </div>
    );
    return (
       <>
           <SmallAlert/>
           {loading
              ? <Loader full={true}/> :
              error ? (
                 <>
                     <Header/>
                     <div className={'text-center text-xl font-bold flex flex-col justify-center m-auto items-center'} style={{height: '100vh'}}>
                         <p>Не удалось найти сотрудника в компании {localStorage.getItem('company_name')}</p>
                         <p>Попробовать найти в других компаниях ?</p>
                         <button className='mt-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-400 border border-transparent rounded-md hover:bg-blue-500 transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
                                 style={{width: 200}}
                                 onClick={changeCompanyForFindUser}
                         >Поиск</button>
                         {userError && <p className='text-red-600 font-normal'>{userError}</p>}
                     </div>
                 </>
                 )
              : (
                 <div className="flex-col">
                     <PatentFileModal/>
                     <FileModal/>
                     <DeleteModal
                        deleteModalOpen={deleteModalOpen}
                        toggleDeleteModal={toggleDeleteModal}
                        tokenToDelete={tokenToDelete}
                        typeToDelete={typeToDelete}
                        nameToDelete={nameToDelete}
                        deleteFile={deleteFile}
                        deleteDocument={deleteDocument}
                     />
                     <Header/>

                     {statusData?.Status?.title === "Требует проверки (З)" && (
                        <div>
                            <DuplicatedProfile showSmallAlert={showSmallAlert} hideSmallAlert={hideSmallAlert}/>
                            <div className="mt-8"></div>
                        </div>
                     )}


                     <section className="flex lg:px-12 md:px-6 sm:px-4 mb-40 md:mb-24 mx-3">
                            {/* левый столбец */}
                            <aside className="hidden md:block mr-4 w-96">
                                <div className="h-48 rounded-lg border bg-white shadow-xl flex flex-col items-center">
                                    <div
                                       className="object-cover rounded-full h-24 w-24 bg-gray-100 flex items-center justify-center mt-4 mb-8">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                                        </svg>
                                    </div>
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                        {data && `${data.Employee_profile.lastName} ${data.Employee_profile.firstName} `}
                                    </h3>
                                </div>

                                <div className="mt-2">
                                    <textarea
                                       value={comment}
                                       onChange={(e) => setComment(e.target.value)}
                                       className="placeholder-gray-400 border-gray-400 leading-5 w-full rounded-md focus:outline-none focus:ring-1 focus:border-gray-500"
                                       placeholder="Комментарий"/>
                                    <button
                                       type="button"
                                       className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                                       onClick={onSaveComment}
                                       disabled={commentDisabled}
                                    >
                                        Сохранить
                                    </button>
                                </div>

                                {(localStorage.getItem('role_id') === "1" || localStorage.getItem('role_id') === "3") && (
                                   <>
                                       {!data?.Employee_profile?.is_mini_form && !sent1C && statusData?.Status?.title === "Требует проверки (З)" && <OneCStatus date={null} title={null} children={sendTo1C} /> }
                                       {!data?.Employee_profile?.is_mini_form && sent1C ?
                                          <OneCStatus date={sent1CDate} title={"Анкета отправлена в 1C"}/> :
                                          (!data?.Employee_profile?.is_mini_form && statusData?.Status?.title !== "Требует проверки (З)" && data?.Employee_profile?.status_history.length > 0 && dateHistory > dateStart1C && !sent1C) ?
                                             <OneCStatus date={null} title={"Анкета не отправлена в 1с"}/> : null
                                       }
                                   </>
                                )}

                                {data?.Employee_profile?.status_history?.length > 0 && (
                                   <div className="rounded-lg border bg-white shadow-xl my-4 z-30 ">
                                       <LastStatus status={data.Employee_profile.status_history[0]}/>
                                   </div>
                                )}

                                {(localStorage.getItem('manager_name') == "HR") && (
                                   <div className="rounded-lg border bg-white shadow-xl my-4 z-30 ">
                                       <Rashod id={data?.Employee_profile.profile_id}
                                               rashod={data?.Employee_profile.rashod}/>
                                   </div>
                                )}

                                {/* переключение статусов */}
                                <StatusButtons loading={statusLoading} data={statusData} refetch={getStatusInfo}
                                               statusId={+data?.Employee_profile?.status_id}
                                               refetchProfile={refetchProfile}
                                               sendTo1C={sent1C}
                                               status={statusData?.Status?.title}
                                               isMiniForm={data?.Employee_profile?.is_mini_form}
                                />

                                {statusData?.Status?.title === "Требует проверки (З)" && (
                                   <SwitchCompany showSmallAlert={showSmallAlert} hideSmallAlert={hideSmallAlert}/>
                                )}

                                {statusData?.Status?.title === "Принят в штат" && (
                                   <AnyCompany showSmallAlert={showSmallAlert} hideSmallAlert={hideSmallAlert}/>
                                )}

                                {statusData?.Status?.traffic_queue?.next_stage?.title === "Уволен" && (
                                   <div className="rounded-lg border bg-white shadow-xl my-4 z-30 ">
                                       <ButtonsCard date={data?.Employee_profile?.dismissDate}
                                                    comment={data?.Employee_profile?.dismissComment}
                                                    profileId={props.match.params.id}
                                                    statusId={data?.Employee_profile?.status_id}/>
                                   </div>
                                )}
                                {data.Employee_profile.contract &&
                                   <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                       <InfoCard title="Договор" subtitle="" contract
                                                 data={data.Employee_profile.contract}/>
                                   </div>
                                }

                                <div className="mt-4 rounded-lg border bg-white shadow-xl x-50">
                                    <div className="px-4 py-5 flex justify-center sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Документы
                                        </h3>
                                    </div>
                                    <div
                                       className="border-t border-gray-200 sm:px-8 flex flex-col flex-wrap items-center p-2 md:p-2">
                                        {data.Employee_profile.documents && data.Employee_profile.documents.length > 0 && (
                                           <>
                                               {data.Employee_profile.documents.map((document, idx) => (
                                                  <div key={idx} className="flex w-full mb-2">
                                                      <Tippy content={document.originalname}>
                                                          <a
                                                             rel="noopener noreferrer"
                                                             href={`/file?file_token=${document.file_token}`}
                                                             className="w-10/12 py-2 px-4 flex justify-start items-center bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold rounded-l-lg border-r disabled:opacity-50 disabled:cursor-not-allowed"
                                                          >
                                                              <div className="h-6 w-6 mr-2">
                                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                                       className="h-6 w-6" fill="none"
                                                                       viewBox="0 0 24 24" stroke="currentColor">
                                                                      <path strokeLinecap="round"
                                                                            strokeLinejoin="round" strokeWidth={2}
                                                                            d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                                                  </svg>
                                                              </div>
                                                              <p className="truncate">{document.originalname}</p>
                                                          </a>
                                                      </Tippy>
                                                      <button
                                                         onClick={() => toggleDeleteModal(true, document.file_token, "Документ", document.originalname)}
                                                         className="flex items-center justify-center w-2/12 bg-blue-400 hover:bg-blue-500 text-white focus:outline-none focus:ring-0 transition ease-in duration-200 rounded-r-lg">
                                                          <svg xmlns="http://www.w3.org/2000/svg"
                                                               className="h-6 w-6"
                                                               fill="none" viewBox="0 0 24 24"
                                                               stroke="currentColor">
                                                              <path strokeLinecap="round" strokeLinejoin="round"
                                                                    strokeWidth={2}
                                                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                                          </svg>
                                                      </button>
                                                  </div>
                                               ))}
                                           </>
                                        )}
                                        <button onClick={() => props.toggleDocumentModal(true)}
                                                className="self-end w-full bg-gray-400 hover:bg-gray-500 py-2 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 rounded-lg flex justify-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>

                                {Object.keys(documentInfo).length > 0 &&
                                   <div className="mt-3 rounded-lg border bg-white shadow-xl z-40 mb-4">
                                       <InfoCard title="Сведения о документах" subtitle="Дата окончания действия договра"
                                                 data={documentInfo}/>
                                   </div>
                                }

                                {data.Employee_profile.generators.length > 0 && (
                                   <div className="mt-4 rounded-lg border bg-white shadow-xl x-50">
                                       <div className="px-4 py-5 flex justify-center sm:px-6">
                                           <h3 className="text-lg leading-6 font-medium text-gray-900">
                                               Сгенерировать файлы
                                           </h3>
                                       </div>
                                       <div className="px-4 flex flex-col flex-wrap sm:px-6">
                                           <GeneratorField data={data}/>
                                       </div>
                                   </div>
                                )}
                            </aside>

                            {/* второй столбец */}
                         <main className="xl:flex-1 xl:mr-4 w-full">
                             {/* sm */}
                             <div
                                className="md:hidden h-48 rounded-lg border bg-white shadow-xl flex flex-col items-center mb-4">
                                 <div
                                    className="object-cover rounded-full h-24 w-24 bg-gray-100 flex items-center justify-center mt-4 mb-8">
                                     <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none"
                                          viewBox="0 0 24 24" stroke="currentColor">
                                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                               d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                                     </svg>
                                 </div>
                                 <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                     {`${data.Employee_profile.firstName} ${data.Employee_profile.lastName}`}
                                 </h3>
                             </div>

                             {data?.Employee_profile?.status_history.length > 0 && (
                                <div className="md:hidden rounded-lg border bg-white shadow-xl my-4 z-30 ">
                                    <LastStatus status={data.Employee_profile.status_history[0]}/>
                                </div>
                             )}

                             <div className="md:hidden">
                                 <StatusButtons loading={statusLoading} data={statusData} refetch={getStatusInfo}
                                                statusId={+data?.Employee_profile?.status_id}
                                                refetchProfile={refetchProfile}/>
                             </div>

                             {statusData?.Status?.traffic_queue?.next_stage?.title === "Уволен" && (
                                <div className="md:hidden rounded-lg border bg-white shadow-xl my-4 z-30 ">
                                    <ButtonsCard date={data.Employee_profile.dismissDate}
                                                 comment={data.Employee_profile.dismissComment}
                                                 profileId={props.match.params.id}
                                                 statusId={data.Employee_profile.status_id}/>
                                </div>
                             )}


                             {data.Employee_profile.contract &&
                                <div className="md:hidden rounded-lg border bg-white shadow-xl z-40 mb-4">
                                    <InfoCard title="Договор" subtitle="" contract
                                              data={data.Employee_profile.contract}/>
                                </div>
                             }

                             <div className="md:hidden mt-4 rounded-lg border bg-white shadow-xl x-50 mb-4">
                                 <div className="px-4 py-5 flex justify-center sm:px-6">
                                     <h3 className="text-lg leading-6 font-medium text-gray-900">
                                         Документы
                                     </h3>
                                 </div>
                                 <div
                                    className="border-t border-gray-200 sm:px-2 flex flex-col flex-wrap items-center p-2 md:p-2">
                                     {data.Employee_profile.documents && data.Employee_profile.documents.length > 0 && (
                                        <>
                                            {data.Employee_profile.documents.map((document, idx) => (
                                               <div key={idx} className="flex w-full mb-2">
                                                   <a
                                                      rel="noopener noreferrer"
                                                      href={`/file?file_token=${document.file_token}`}
                                                      className="w-full py-2 px-4 flex justify-start items-center bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 text-center text-base font-semibold rounded-l-lg whitespace-nowrap border-r disabled:opacity-50 disabled:cursor-not-allowed truncate"
                                                   >
                                                       <div className="h-6 w-6 mr-2">
                                                           <svg xmlns="http://www.w3.org/2000/svg"
                                                                className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                                                stroke="currentColor">
                                                               <path strokeLinecap="round" strokeLinejoin="round"
                                                                     strokeWidth={2}
                                                                     d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
                                                           </svg>
                                                       </div>

                                                       <p className="truncate">{document.originalname}</p>

                                                   </a>
                                                   <button
                                                      onClick={() => toggleDeleteModal(true, document.file_token, "Документ", document.originalname)}
                                                      className="flex items-center justify-center w-2/12 bg-blue-400 hover:bg-blue-500 text-white focus:outline-none focus:ring-0  transition ease-in duration-200 rounded-r-lg">
                                                       <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"
                                                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                           <path strokeLinecap="round" strokeLinejoin="round"
                                                                 strokeWidth={2}
                                                                 d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                                       </svg>
                                                   </button>
                                               </div>
                                            ))}
                                        </>
                                     )}
                                     <button onClick={() => props.toggleDocumentModal(true)}
                                             className="self-end w-full bg-gray-400 hover:bg-gray-500 text-white py-2 focus:outline-none focus:ring-0 transition ease-in duration-200 rounded-lg flex justify-center">
                                         <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                              viewBox="0 0 24 24" stroke="currentColor">
                                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                   d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                         </svg>
                                     </button>
                                 </div>
                             </div>

                             {data.Employee_profile.generators.length > 0 && (
                                <div className="md:hidden mt-4 rounded-lg border bg-white shadow-xl x-50 mb-4">
                                    <div className="px-4 py-5 flex justify-center sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Сгенерировать файлы
                                        </h3>
                                        <div className="px-4 flex flex-col flex-wrap sm:px-6">
                                            <GeneratorField data={data}/>
                                        </div>
                                    </div>
                                </div>
                             )}

                             {/* -------------------------------- */}
                             <div className="h-auto rounded-lg border bg-white shadow-xl z-40 mb-4">
                                 <UserInfo data={data}/>
                             </div>

                             <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                 <FileCard toggleDeleteModal={toggleDeleteModal} fileClickHandler={fileClickHandler}
                                           files={data.Employee_profile.files}/>
                             </div>
                             {/* -------------------------------- */}

                             {/* md */}
                             {data.Employee_profile.passport &&
                                <div className="xl:hidden rounded-lg border bg-white shadow-xl z-40 mb-4">
                                    <InfoCard title="Паспорт" subtitle="Подробная информация о паспорте"
                                              data={data.Employee_profile.passport}/>
                                </div>
                             }

                             {data.Employee_profile.study &&
                                <div className="xl:hidden rounded-lg border bg-white shadow-xl z-40 mb-4">
                                    <InfoCard title="Справка об учёбе" subtitle="Подробная информация о справке"
                                              data={data.Employee_profile.study}/>
                                </div>
                             }

                             {data.Employee_profile.migrationCard &&
                                <div className="xl:hidden rounded-lg border bg-white shadow-xl z-40 mb-4">
                                    <InfoCard title="Миграционная карта"
                                              subtitle="Подробная информация о миграционной карте"
                                              data={data.Employee_profile.migrationCard}/>
                                </div>
                             }

                             {data.Employee_profile.patent &&
                                <div className="xl:hidden rounded-lg border bg-white shadow-xl z-40 mb-4">
                                    <InfoCard title="Патент" subtitle="Подробная информация о патенте"
                                              data={data.Employee_profile.patent}/>
                                </div>
                             }
                             {data.Employee_profile.rvp &&
                                <div className="xl:hidden rounded-lg border bg-white shadow-xl z-40 mb-4">
                                    <InfoCard title="Разрешение на временное проживание"
                                              subtitle="Подробная информация о РВП"
                                              data={data.Employee_profile.rvp}/>
                                </div>
                             }
                             {data.Employee_profile.vng &&
                                <div className="xl:hidden rounded-lg border bg-white shadow-xl z-40 mb-4">
                                    <InfoCard title="Вид на жительство" subtitle="Подробная информация о ВНЖ"
                                              data={data.Employee_profile.vng}/>
                                </div>
                             }
                             {data.Employee_profile.status_history.length > 0 &&
                                <div className="xl:hidden rounded-lg border bg-white shadow-xl z-40">
                                    <StatusHistory data={data.Employee_profile.status_history}/>
                                </div>
                             }

                             {/* xl when image preview opened */}
                             {imgPreviewOpen && (
                                <div className="hidden xl:block">
                                    {data.Employee_profile.passport &&
                                       <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                           <InfoCard title="Паспорт" subtitle="Подробная информация о паспорте"
                                                     data={data.Employee_profile.passport}/>
                                       </div>
                                    }

                                    {data.Employee_profile.study &&
                                       <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                           <InfoCard title="Справка об учёбе"
                                                     subtitle="Подробная информация о справке"
                                                     data={data.Employee_profile.study}/>
                                       </div>
                                    }

                                    {data.Employee_profile.migrationCard &&
                                       <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                           <InfoCard title="Миграционная карта"
                                                     subtitle="Подробная информация о миграционной карте"
                                                     data={data.Employee_profile.migrationCard}/>
                                       </div>
                                    }
                                    {data.Employee_profile.patent &&
                                       <EmptyModalButton data={data.Employee_profile.patent}/>}
                                    {data.Employee_profile.patent && (
                                       <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                           <PatentFileCard
                                              toggleDeleteModal={toggleDeleteModal}
                                              fileClickHandler={fileClickHandler}
                                              files={data.Employee_profile.patent_files}
                                           />
                                       </div>
                                    )}
                                    {data.Employee_profile.patent &&
                                       <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                           <InfoCard title="Патент" subtitle="Подробная информация о патенте"
                                                     data={data.Employee_profile.patent}/>
                                       </div>
                                    }

                                    {data.Employee_profile.rvp &&
                                       <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                           <InfoCard title="Разрешение на временное проживание"
                                                     subtitle="Подробная информация о РВП"
                                                     data={data.Employee_profile.rvp}/>
                                       </div>
                                    }

                                    {data.Employee_profile.vng &&
                                       <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                           <InfoCard title="Вид на жительство"
                                                     subtitle="Подробная информация о ВНЖ"
                                                     data={data.Employee_profile.vng}/>
                                       </div>
                                    }

                                    {data.Employee_profile.status_history.length > 0 &&
                                       <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                           <StatusHistory data={data.Employee_profile.status_history}/>
                                       </div>
                                    }
                                </div>
                             )}
                         </main>

                         {/* xl when image preview close*/}
                         <aside className={`hidden ${imgPreviewOpen ? "" : "xl:block"} xl:flex-1`}>
                             {data.Employee_profile.passport &&
                                <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                        <InfoCard title="Паспорт" subtitle="Подробная информация о паспорте"
                                                  data={data.Employee_profile.passport}/>
                                    </div>
                                }

                                {data.Employee_profile.polis &&
                                    <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                        <InfoCard title="Полис ДМС" subtitle="Подробная информация о полисе"
                                                  data={data.Employee_profile.polis}/>
                                    </div>
                                }

                                {data.Employee_profile.study &&
                                    <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                        <InfoCard title="Справка об учёбе" subtitle="Подробная информация об учёбе"
                                                  data={data.Employee_profile.study}/>
                                    </div>
                                }

                                {data.Employee_profile.migrationCard &&
                                    <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                        <InfoCard title="Миграционная карта"
                                                  subtitle="Подробная информация о миграционной карте"
                                                  data={data.Employee_profile.migrationCard}/>
                                    </div>
                                }

                                {data.Employee_profile.patent &&
                                <EmptyModalButton data={data.Employee_profile.patent}/>}
                                {data.Employee_profile.patent && (
                                    <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                        <PatentFileCard
                                            toggleDeleteModal={toggleDeleteModal}
                                            fileClickHandler={fileClickHandler}
                                            files={data.Employee_profile.patent_files}
                                        />
                                    </div>
                                )}
                                {data.Employee_profile.patent &&
                                    <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                        <InfoCard title="Патент" subtitle="Подробная информация о патенте"
                                                  data={data.Employee_profile.patent}/>
                                    </div>
                                }

                                {data.Employee_profile.rvp &&
                                    <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                        <InfoCard title="Разрешение на временное проживание"
                                                  subtitle="Подробная информация о РВП"
                                                  data={data.Employee_profile.rvp}/>
                                    </div>
                                }
                                {data.Employee_profile.vng &&
                                    <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                        <InfoCard title="Вид на жительство" subtitle="Подробная информация о ВНЖ"
                                                  data={data.Employee_profile.vng}/>
                                    </div>
                                }

                                {data.Employee_profile.status_history.length > 0 &&
                                    <div className="rounded-lg border bg-white shadow-xl z-40 mb-4">
                                        <StatusHistory data={data.Employee_profile.status_history}/>
                                    </div>
                                }
                            </aside>

                            {/* xl when image preview open*/}
                            <aside className={`hidden ${imgPreviewOpen ? "xl:block" : ""} xl:flex-1`}>
                                <div className="rounded-lg border bg-white shadow-xl z-40 mb-4 sticky top-4 w-full">

                                    <div className="px-4 py-4 flex">
                                        <button onClick={() => setImgPreviewOpen(false)}
                                                className="bg-gray-100 hover:bg-gray-200 rounded-md p-1 focus:outline-none transition ease-in duration-200">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M6 18L18 6M6 6l12 12"/>
                                            </svg>
                                        </button>
                                        <h3 className="pl-4 text-lg leading-6 font-medium text-gray-900">
                                            {imgPreviewName}
                                        </h3>
                                    </div>
                                    <div className="border-t border-gray-200 overflow-hidden">
                                        <ReactPanZoom image={imgPreviewURL} alt="Image alt text"/>
                                    </div>
                                </div>
                            </aside>
                        </section>
                    </div>
                )
            }
        </>
    )
}

const mapDispatchToProps = {
    toggleDocumentModal,
    showSmallAlert,
    hideSmallAlert,
    changeCompany,
}
const mapStateToProps = state => ({
    viewOnly: state.manager.viewOnly,
    showList: state.manager.showList,
    smallAlertVisible: state.alert.smallAlertVisible,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfile))
