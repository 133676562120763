import React from 'react'
import { connect } from "react-redux"
import { toggleDocumentModal, toggleFileModal } from '../../redux/manager/managerActions'

function DeleteModal({
  deleteModalOpen, 
  toggleDeleteModal, 
  tokenToDelete,
  typeToDelete,
  nameToDelete,
  deleteFile,
  deleteDocument
}) {

  // в зависимости от типа вызывается удаление файла или документа
  const deleteHandler = () => {
    if (typeToDelete === "Файл") {
      deleteFile(tokenToDelete)
    } else if (typeToDelete === "Документ") {
      deleteDocument(tokenToDelete)
    }
    toggleDeleteModal(false)
  }

  return (
    <>
      { deleteModalOpen && (
          <div className="fixed bg-gray-900 bg-opacity-50 w-full h-full z-50 flex items-center justify-center">
            <div className="h-2/3 xl:w-4/12 lg:w-5/12 w-10/12 sm:w-2/3 bg-white rounded-lg overflow-hidden">
              <div className="flex flex-col items-center w-11/12 mx-auto h-full text-center pt-32">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                <p className="font-semibold text-xl leading-7 text-gray-800 mt-4">
                  Вы уверены что хотите удалить<br/>
                  <span className="text-xl">
                    {typeToDelete} - "{nameToDelete}"?
                  </span>
                </p>
                <div className="flex w-full mt-auto mb-8">
                  <button onClick={()=>toggleDeleteModal(false)} className="flex-1 mr-4 mt-2 bg-gray-400 hover:bg-gray-500 py-4 focus:outline-none focus:ring-0 text-white transition ease-in duration-200 rounded-md flex justify-center">
                    Нет
                  </button>
                  <button onClick={deleteHandler} className="flex-1 mt-2 bg-red-500 hover:bg-red-600 text-white py-4 focus:outline-none focus:ring-0 transition ease-in duration-200 rounded-md flex justify-center">
                    Да
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}
const mapDispatchToProps = {
  toggleFileModal, toggleDocumentModal
}
const mapStateToProps = state => ({
  isFileModalOpen: state.manager.isFileModalOpen,
  isDocumentModalOpen: state.manager.isDocumentModalOpen,
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal)
