import React, { useRef } from "react"
import { connect } from 'react-redux'
import BottomAlert from "../components/BottomAlert"
import { hideBottomAlert, showBottomAlert } from "../redux/alert/alertActions"
import { signin } from '../redux/auth/authActions'


const Login = (props) => {

  const login = useRef()
  const password = useRef()

  const submitHandler = async (event) => {
    event.preventDefault()
    const message = await props.signin(login.current.value, password.current.value)
    if (message) {
      // есть сообщение об ошибке
      props.showBottomAlert(message, "danger")
      setTimeout(() => {
        props.hideBottomAlert()
      }, 5000);
    } else {
      // успешная авторизация
      props.hideBottomAlert()
      props.history.push("/")
    }
  }
  // обработчик нажания на enter
  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      submitHandler(event)
    }
  }

  return (
    <div className="min-h-screen relative">
      <div className="flex flex-col min-h-screen justify-center sm:py-12">
        <div className="p-10 mx-auto w-11/12 md:w-full md:max-w-md">
          <h1 className="font-bold text-center text-2xl mb-5">Авторизация</h1>

          <div className="bg-white shadow-2xl w-full rounded-lg divide-y divide-gray-200 border-gray-200 border">
            <div className="px-5 py-7">
              <label htmlFor="email-address" className="font-semibold text-sm text-gray-600 pb-1 block">E-mail</label>
              <div className="relative">
                <svg className="w-5 h-5 absolute top-2 ml-1 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                <input ref={login} required id="email-address" name="email" placeholder="пример@mail.com" type="email" autoComplete="email" className="border rounded-lg px-7 py-2 text-sm w-full"/>
              </div>

              <label  htmlFor="password" className="font-semibold text-sm text-gray-600 pb-1 block mt-4">Пароль</label>
              <div className="relative">
                <svg className="w-5 h-5 absolute top-3 ml-1 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
                <input onKeyPress={handleKeyPress} ref={password} required id="password" name="password" placeholder="**********" type="password" autoComplete="current-password" className="border rounded-lg px-7 py-2 mt-1 mb-5 text-sm w-full"/>
              </div>

              <button onClick={submitHandler} type="button" className="transition duration-200 bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span className="inline-block mr-2">Войти</span>
                <svg className="w-4 h-4 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
                </svg>
              </button>
            </div>

          </div>
        </div>
      </div>
      <BottomAlert />
    </div>
  );
}

const mapDispatchToProps = {
  signin, showBottomAlert, hideBottomAlert
}
const mapStateToProps = state => ({
  bottomAlertVisible: state.alert.bottomAlertVisible
})
export default connect(mapStateToProps, mapDispatchToProps)(Login)
