import React from 'react'


export default function OrgInput({orgSelect, setOrgSelect}) {
  return (
    <>
      <div>
        <label className="flex pl-2 pb-1">Организация</label>
        <select
          value={orgSelect}
          onChange={event => setOrgSelect(event.target.value)}
          className="form-select rounded-lg w-full flex"
          id="org">
          <option value="1">ООО "ВС"</option>
          <option value="2">ООО "ВОРЛД-ПРО"</option>
          <option value="3">ООО "ВЕЛЛ"</option>
          <option value="4">ООО "ЮНИТ-СТАРТ"</option>
          <option value="5">ООО "СТЭП"</option>
        </select>
      </div>
    </>
  )
}
