import React from 'react'
import { useField } from 'formik'
import { Error } from './Error'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'


export const Relatives = ({ label, ...props }) => {

  const [fieldRelationDegreeRelatives, metaRelationDegreeRelatives] = useField({
    name: "relationDegreeRelatives",
    id: 2001
  })
  const [fieldNameRelatives, metaNameRelatives] = useField({
    name: "nameRelatives",
    id: 2002
  })
  const [fieldCityRelatives, metaCityRelatives] = useField({
    name: "cityRelatives",
    id: 2003
  })
  const [fieldPhoneRelatives, metaPhoneRelatives] = useField({
    name: "phoneRelatives",
    id: 2004
  })
  const [fieldJobPlaceRelatives, metaJobPlaceRelatives] = useField({
    name: "jobPlaceRelatives",
    id: 2005
  })
  const [fieldJobRelatives, metaJobRelatives] = useField({
    name: "jobRelatives",
    id: 2006
  })

  const [fieldRelationDegreeRelatives2, metaRelationDegreeRelatives2] = useField({
    name: "relationDegreeRelatives2",
    id: 2007
  })
  const [fieldNameRelatives2, metaNameRelatives2] = useField({
    name: "nameRelatives2",
    id: 2008
  })
  const [fieldCityRelatives2, metaCityRelatives2] = useField({
    name: "cityRelatives2",
    id: 2009
  })
  const [fieldPhoneRelatives2, metaPhoneRelatives2] = useField({
    name: "phoneRelatives2",
    id: 20010
  })
  const [fieldJobPlaceRelatives2, metaJobPlaceRelatives2] = useField({
    name: "jobPlaceRelatives2",
    id: 20011
  })
  const [fieldJobRelatives2, metaJobRelatives2] = useField({
    name: "jobRelatives2",
    id: 20012
  })

  return (
    <div className="mb-4 w-full ">
      <h1 className="text-lg mt-4">Укажите, пожалуйста, сведения о Ваших родных: </h1>
      <div className="flex xl:flex-row flex-col xl:items-start w-full overflow-x-auto">
        <div>
          <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="relationDegreeRelatives">Степень родства *</label>
          <div className="flex xl:block">
            <div>
              <input name="nameRelatives" id="2002" className="xl:mr-2 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldRelationDegreeRelatives} />
              {metaRelationDegreeRelatives.touched && metaRelationDegreeRelatives.error && (
                <Error>{metaRelationDegreeRelatives.error}</Error>)}
              {metaRelationDegreeRelatives2.touched && metaRelationDegreeRelatives2.error && (
                <Error>{metaRelationDegreeRelatives2.error}</Error>)}
            </div>
            <div className="ml-4 xl:ml-0 xl:mt-2">
              <input name="nameRelatives2" id="2007" className=" bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldRelationDegreeRelatives2} />
              
            </div>
          </div>
        </div>
        <div>
          <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="nameRelatives">Ф.И.О. * </label>
          <div className="flex xl:block">
            <div>
              <input name="relationDegreeRelatives" id="2001" className="xl:mr-2 block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldNameRelatives} />
            </div>
            <div className="ml-4 xl:ml-0 xl:mt-2">
              <input name="relationDegreeRelatives2" id="2008" className="block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldNameRelatives2} />
              {metaNameRelatives.touched && metaNameRelatives.error && (
                <Error>{metaNameRelatives.error}</Error>)}
              {metaNameRelatives2.touched && metaNameRelatives2.error && (
                <Error>{metaNameRelatives2.error}</Error>)}
              
            </div>
          </div>
        </div>
        <div>
          <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="cityRelatives">Город проживания *</label>
          <div className="flex xl:block">
            <div>
              <input name="cityRelatives" id="2003" className="xl:mr-2 block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldCityRelatives} />
            </div>
            <div className="ml-4 xl:ml-0 xl:mt-2">
              <input name="cityRelatives2" id="2009" className="block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldCityRelatives2} />
              {metaCityRelatives.touched && metaCityRelatives.error && (
                <Error>{metaCityRelatives.error}</Error>)}
              {metaCityRelatives2.touched && metaCityRelatives2.error && (
                <Error>{metaCityRelatives2.error}</Error>)}
            </div>
          </div>
        </div>
        <div>
          <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="jobPlaceRelatives">Место работы/учебы *</label>
          <div className="flex xl:block">
            <div>
              <input name="jobPlaceRelatives" id="2005" className="xl:mr-2 block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldJobPlaceRelatives} />
            </div>
            <div className="ml-4 xl:ml-0 xl:mt-2">
              <input name="jobPlaceRelatives2" id="20011" className="block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldJobPlaceRelatives2} />
              {metaJobPlaceRelatives.touched && metaJobPlaceRelatives.error && (
                <Error>{metaJobPlaceRelatives.error}</Error>)}
              {metaJobPlaceRelatives2.touched && metaJobPlaceRelatives2.error && (
                <Error>{metaJobPlaceRelatives2.error}</Error>)}
            </div>
          </div>
        </div>
        <div>
          <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="jobRelatives">Занимаемая должность *</label>
          <div className="flex xl:block">
            <div>
            <input name="jobRelatives" id="2006" className="xl:mr-2 block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldJobRelatives} />
            </div>
            <div className="ml-4 xl:ml-0 xl:mt-2">
            <input name="jobRelatives2" id="20012" className="block bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...fieldJobRelatives2} />
            {metaJobRelatives.touched && metaJobRelatives.error && (
              <Error>{metaJobRelatives.error}</Error>)}
            {metaJobRelatives2.touched && metaJobRelatives2.error && (
              <Error>{metaJobRelatives2.error}</Error>)}
            </div>
          </div>
        </div>
        <div>
          <label className="leading-7 text-sm text-gray-900 font-semibold" htmlFor="phoneRelatives">Телефон * </label>
          <div className="flex xl:block xl:mr-2">
            <div>
              <PhoneInput
                country={'ru'}
                onlyCountries={['ru']}
                inputStyle={{
                  height: "42px",
                  width: "222px",
                }}
                id="2004"
                value={fieldPhoneRelatives.value}
                placeholder={"+71231231212"}
                autoFormat={false}
                inputProps={{
                  name: "phoneRelatives",
                  onBlur: fieldPhoneRelatives.onBlur,
                  onChange: fieldPhoneRelatives.onChange,
                }}
              />
            
          </div>
            <div className="ml-4 xl:ml-0 xl:mt-2">
            <PhoneInput
              country={'ru'}
              onlyCountries={['ru']}
              inputStyle={{
                height: "42px",
                width: "222px",
              }}
              id="20010"
              value={fieldPhoneRelatives2.value}
              placeholder={"+71231231212"}
              autoFormat={false}
              inputProps={{
                name: "phoneRelatives2",
                onBlur: fieldPhoneRelatives.onBlur,
                onChange: fieldPhoneRelatives.onChange,
              }}
            />
            {metaPhoneRelatives.touched && metaPhoneRelatives.error && (
              <Error>{metaPhoneRelatives.error}</Error>)}
            {metaPhoneRelatives2.touched && metaPhoneRelatives2.error && (
              <Error>{metaPhoneRelatives2.error}</Error>)}
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};