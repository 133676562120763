import React from "react";

export default function PopupFail({type}) {
  return (
    <div className="fixed w-full h-full bg-gray-900 z-50 bg-opacity-60 top-0 left-0 flex items-center justify-center">
      <div className="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800 w-64 m-auto">
        <div className="w-full h-full text-center">
          <div className="flex h-full flex-col justify-between">
            <svg className="h-12 w-12 mt-4 m-auto text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
            <p className="text-gray-800 dark:text-gray-100 text-md py-2 px-6">
              { type === "form" ? "Произошла ошибка. Повторите отправку формы" : type === "validation" ? "Заполните обязательные поля" : "Произошла ошибка при отправке файлов. Повторите отправку формы"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}