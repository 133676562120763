import { useMutation } from "@apollo/client"
import { XIcon } from '@heroicons/react/outline'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { GET_PROFILE, REMOVE_DISMISSAL_QUEUE, SET_DISMISSAL_QUEUE, UPDATE_DISMISSAL_QUEUE } from "../../graphql/Queries"

export const ButtonsCard = ({statusData, date, comment, profileId, statusId, ...props}) => {
  
  const [setDismissalQueue] = useMutation(SET_DISMISSAL_QUEUE)
  const [removeDismissalQueue] = useMutation(REMOVE_DISMISSAL_QUEUE)
  const [updateDismissalQueue] = useMutation(UPDATE_DISMISSAL_QUEUE)
  
  const [canSend, setCanSend] = useState(false)
  const [update, setUpdate] = useState(false)

  const dismissalDate = useRef(null)
  const dismissalComment = useRef(null)


  // если есть дата перевода, отображать копку сброса, и отображать значение в инпутах
  // так же флаг, позволяющий понять, происходить первая запись или обновление
  useEffect(() => {
    if (dismissalDate && dismissalComment) {
      dismissalDate.current.value = date
      dismissalComment.current.value = comment
      if (date) setUpdate(true)
    }
  }, [])
  
  // срабатывает на заполнение инпута
  const dataChangeHandler = () => {
    if (dismissalDate.current.value) {
      setCanSend(true)
    } else {
      setCanSend(false)
    }
  }

  // обработчик сброса перевода к определенной дате
  const removeHandler = () => {
    removeDismissalQueue({
      variables: {
        profileId,
        companyId: +localStorage.getItem('company_id'),
      },
      refetchQueries: [
        {query: GET_PROFILE, variables: { companyId: localStorage.getItem('company_id'), profileId}}
    ],
      awaitRefetchQueries: true
    })
  }

  // запись увольнения к определенной дате
  const sendButtonHandler = () => {
    // если дата уже есть матация обновления
    if (update) {
      updateDismissalQueue({
        variables: {
          profileId,
          companyId: +localStorage.getItem('company_id'),
          date: dismissalDate.current.value,
          comment: dismissalComment.current.value,
        },
        refetchQueries: [
          {query: GET_PROFILE, variables: { companyId: localStorage.getItem('company_id'), profileId}}
      ],
        awaitRefetchQueries: true
      })
    } else {
      // если даты нет, то добавление ее бд
      setDismissalQueue({
        variables: {
          profileId,
          companyId: +localStorage.getItem('company_id'),
          date: dismissalDate.current.value,
          comment: dismissalComment.current.value || undefined,
        },
        refetchQueries: [
          {query: GET_PROFILE, variables: { companyId: localStorage.getItem('company_id'), profileId}}
      ],
        awaitRefetchQueries: true
      })
    }
  }

  return (
    <>
      <div className="px-4 py-5 sm:px-6 flex flex-row items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 ">
            Перевод в уволен
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            К определенной дате
          </p>
        </div>
        {update && (
          <button 
            className="ml-auto mr-2 md:mr-0 mt-2 md:mt-0 py-1 px-2 h-8 w-8 flex justify-center items-center hover:bg-gray-100 focus:ring-gray-500 focus:ring-offset-gray-200  text-gray-800 transition ease-in duration-100 text-center text-base font-semibold hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 rounded"
            onClick={removeHandler}
          >
            <div className="w-5 h-5">
              <XIcon className="w-5 h-5 text-red-500"/>
            </div>
          </button>
        )}
      </div>
      <div className="border-t border-gray-200">
        <div className="flex-row px-2 py-2">
          <div>
            <input ref={dismissalDate} onChange={dataChangeHandler} type="date" className={`border-gray-400 leading-4 w-full rounded-md focus:outline-none focus:ring-1 focus:border-gray-500 cursor-pointer ${dismissalDate?.current?.value ? 'date-input--has-value' : ''}`}/>
          </div>

          <div className="mt-2">
            <input ref={dismissalComment} onChange={dataChangeHandler} type="text" className="placeholder-gray-400 border-gray-400 leading-5 w-full rounded-md focus:outline-none focus:ring-1 focus:border-gray-500" placeholder="Комментарий"/>
          </div>

          <div className="mt-2">
            <button
              type="button"
              className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md hover:bg-blue-600 transition duration-150 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={!canSend}
              onClick={sendButtonHandler}
            >
              {date ? "Обновить" : "Отправить"}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsCard)
