import React from 'react'

const ErrorPage = () => {
  return (
    <div className="flex justify-center">
      <div className="my-8 bg-white rounded-lg p-4 sm:p-8 flex flex-col lg:w-80 xl:w-96 w-11/12 z-10 border shadow-2xl relative">
        <h1 className="font-bold text-2xl text-gray-800">Произошла ошибка. Обновите страницу позже.</h1>
      </div>
    </div>
  )
}

export default ErrorPage
