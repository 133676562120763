import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import CompaniesList from '../components/CompaniesList'
import MainLayout from '../components/MainLayout'
import { signout } from '../redux/auth/authActions'
import { changeCompany, toggleShowList } from '../redux/manager/managerActions'


function Main(props) {

  useEffect(()=>{
    if (localStorage.getItem('company_id') && props.showList) {
      props.toggleShowList()
    }
  }, [])

  return (
    <>
      { props.showList 
        ? <CompaniesList /> 
        : <MainLayout />
      }
    </>
  )
}

const mapDispatchToProps = {
  changeCompany, signout, toggleShowList
}
const mapStateToProps = state => ({
  company: state.manager.company,
  showList: state.manager.showList,
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)
