import React, { useEffect, useContext } from 'react'
import { useFormikContext } from "formik"
import { TextInput } from './TextInput'
import { FormContext } from "../context/FormContext"

export default function Education() {
  const { values } = useFormikContext()
  const { changeDidNotWork } = useContext(FormContext)

  useEffect(() => {
    if (values.didNotWork) {
      changeDidNotWork(true)
    } else {
      changeDidNotWork(false)
    }
  }, [values.didNotWork])

  return (
    <>
      {!values.didNotWork && (
        <>
          <div className="hidden md:block overflow-x-scroll">
            <div className="w-full flex justify-between items-end">
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Дата приема и увольнения*</h2>
                <div className="flex gap-1">
                  <TextInput name="workHiringDate1" type="date"/>
                  <TextInput name="workQuitDate1" type="date"/>
                </div>
              </div>
              <div className="flex-1 flex-col h-full mx-4">
                <h2 className="mb-4">Предприятие*</h2>
                <TextInput name="workPlace1" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Квалификация, специальность*</h2>
                <TextInput name="workJob1" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full mx-4">
                <h2 className="mb-4">Выполняемые функции*</h2>
                <TextInput name="workFunctions1" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Причина увольнения*</h2>
                <TextInput name="workQuitReason1" type="text"/>
              </div>
            </div>
            <hr></hr>
            <div className="w-full flex justify-between items-end mt-4 ">
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Дата приема и увольнения</h2>
                <div className="flex gap-1">
                  <TextInput name="workHiringDate2" type="date"/>
                  <TextInput name="workQuitDate2" type="date"/>
                </div>
              </div>
              <div className="flex-1 flex-col h-full mx-4">
                <h2 className="mb-4">Предприятие</h2>
                <TextInput name="workPlace2" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Квалификация, специальность</h2>
                <TextInput name="workJob2" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full mx-4">
                <h2 className="mb-4">Выполняемые функции</h2>
                <TextInput name="workFunctions2" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Причина увольнения</h2>
                <TextInput name="workQuitReason2" type="text"/>
              </div>
            </div>
          </div>

          {/* sm */}
          <div className="md:hidden">
            <div className="w-full flex-col justify-between items-end">
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Дата приема и увольнения*</h2>
                <div className="flex gap-1">
                  <TextInput name="workHiringDate1" type="date"/>
                  <TextInput name="workQuitDate1" type="date"/>
                </div>
              </div>
              <div className="flex-1 flex-col h-full ">
                <h2 className="mb-4">Предприятие*</h2>
                <TextInput name="workPlace1" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Квалификация, специальность*</h2>
                <TextInput name="workJob1" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Выполняемые функции*</h2>
                <TextInput name="workFunctions1" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Причина увольнения*</h2>
                <TextInput name="workQuitReason1" type="text"/>
              </div>
            </div>
            <hr></hr>
            <div className="w-full flex-col justify-between items-end mt-4 ">
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Дата приема и увольнения</h2>
                <div className="flex gap-1">
                  <TextInput name="workHiringDate2" type="date"/>
                  <TextInput name="workQuitDate2" type="date"/>
                </div>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Предприятие</h2>
                <TextInput name="workPlace2" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Квалификация, специальность</h2>
                <TextInput name="workJob2" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Выполняемые функции</h2>
                <TextInput name="workFunctions2" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Причина увольнения</h2>
                <TextInput name="workQuitReason2" type="text"/>
              </div>
            </div>
          </div>

        </>
      )}
    </>
  )
}


// {
//   label: "Дата приема и увольнения",
//   initialValue: "",
//   id: "46",
//   config: {
//     name: "workHiringDate1",
//     type: "date",
//   }
// },
// {
//   initialValue: "",
//   id: "47",
//   config: {
//     name: "workQuitDate1",
//     type: "date",
//   }
// },
// {
//   label: "Предприятия",
//   initialValue: "",
//   id: "48",
//   config: {
//     name: "workPlace1",
//     type: "text",
//   }
// },
// {
//   label: "Квалификация, специальность",
//   initialValue: "",
//   id: "49",
//   config: {
//     name: "workJob1",
//     type: "text",
//   }
// },
// {
//   label: "Выполняемые функции (кол-во подчиненных)",
//   initialValue: "",
//   id: "50",
//   config: {
//     name: "workFunctions1",
//     type: "text",
//   }
// },
// {
//   label: "Причина увольнения",
//   initialValue: "",
//   id: "51",
//   config: {
//     name: "workQuitReason1",
//     type: "text",
//   }
// },
// {
//   label: "(Если есть) Дата приема и увольнения",
//   initialValue: "",
//   id: "52",
//   config: {
//     name: "workHiringDate2",
//     type: "date",
//   }
// },
// {
//   initialValue: "",
//   id: "53",
//   config: {
//     name: "workQuitDate2",
//     type: "date",
//   }
// },
// {
//   label: "Предприятия",
//   initialValue: "",
//   id: "54",
//   config: {
//     name: "workPlace2",
//     type: "text",
//   }
// },
// {
//   label: "Квалификация, специальность",
//   initialValue: "",
//   id: "55",
//   config: {
//     name: "workJob2",
//     type: "text",
//   }
// },
// {
//   label: "Выполняемые функции (кол-во подчиненных)",
//   initialValue: "",
//   id: "56",
//   config: {
//     name: "workFunctions2",
//     type: "text",
//   }
// },
// {
//   label: "Причина увольнения",
//   initialValue: "",
//   id: "57",
//   config: {
//     name: "workQuitReason2",
//     type: "text",
//   }
// },
