import React from 'react'
import { useField } from 'formik'
import { Error } from './Error'
import InputMask from "react-input-mask";

export const NumberTT = ({ label, ...props }) => {

  const [field, meta] = useField(props)

  return (
    <>
      <div className="mb-4">
        <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={props.name}>{label}</label>
        <InputMask name={props.name} mask="Т999" maskChar=" " className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...field} {...props} />
        {meta.touched && meta.error && (
          <Error>{meta.error}</Error>)}
      </div>
    </>
  );
};