import React from 'react'
import { useField } from 'formik'
import { Error } from './Error';

export const Checkbox = ({ children, label, ...props }) => {

  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <div className="mb-4">
        <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={props.id || props.name}>{label}</label>
      <label className="flex items-center">
        <input className="appearance-none form-checkbox h-5 w-5 rounded-md text-indigo-600" type="checkbox" {...field} {...props} />
        <div className="pl-2">
          {props.placeholder}
        </div>
      </label>
      {meta.touched && meta.error ? (
        <Error>{meta.error}</Error>
      ) : null}
    </div>
  );
};