import { SIGNIN_SUCCESS, SIGNIN_ERROR, SIGNOUT } from '../types'

const initialState = {
  message: '',
  token: ''
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
      case SIGNIN_SUCCESS:
        return {...state, token: action.payload}
      case SIGNIN_ERROR:
        return {...state, message: action.payload}
      case SIGNOUT:
          return {...state, token: ""}
      default: return state
  }
}