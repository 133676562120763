import { SELECT_CANCELED_ID, SELECT_FILTERED_ID } from "../types";

// выбор анкет из левой таблицы
export const selectFilteredIds = (ids) => {
  const payload = ids
  return {type: SELECT_FILTERED_ID, payload}
}
// выбор анкет из правой таблицы
export const selectCanceledIds = (ids) => {
  const payload = ids
  return {type: SELECT_CANCELED_ID, payload}
}