import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import {useMutation, useQuery} from '@apollo/client';
import {GET_DUPLICATED_PROFILES, MERGE_DUPLICATED_PROFILES} from "../../graphql/Queries";
import {connect} from "react-redux";
import {hideSmallAlert, showSmallAlert} from "../../redux/alert/alertActions"
import {withRouter} from "react-router-dom";
import {client} from "../../App";

export function DuplicatedProfile(props) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [duplicatedProfiles, setDuplicatedProfiles] = useState([]);
    const [mergeDuplicatedProfiles] = useMutation(MERGE_DUPLICATED_PROFILES);
    const url = window.location.pathname;
    const profileId = url.split('/').pop();
    const {loading, data: duplicatedProfilesData} = useQuery(GET_DUPLICATED_PROFILES, {
        variables: {profile_id: profileId},
        skip: !profileId,
        onCompleted: (data) => {
            const transformedProfiles = data.DuplicatedProfiles.map(profile => ({
                ...profile,
                birthDate: formatDate(profile.birthDate)
            }));
            setDuplicatedProfiles(transformedProfiles);
        },
    });
    const handleOpenModal = () => {
        if (hasDuplicatedProfiles) {
            setModalIsOpen(true);
        }
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const formatDate = (timestamp) => {
        const date = new Date(parseInt(timestamp));
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
    };
    const profileClickHandler = (profileId, companyId, companyName) => {
        if (profileId) {
            const prevCompanyId = localStorage.getItem('company_id');
            const prevCompanyName = localStorage.getItem('company_name');
            localStorage.setItem('company_id', companyId);
            localStorage.setItem('company_name', companyName);
            window.open(`/user/${profileId}`, '_blank');
            setTimeout(() => {
                localStorage.setItem('company_id', prevCompanyId);
                localStorage.setItem('company_name', prevCompanyName);
            }, 4000);
        }
    };
    const handleMergeProfile = async () => {
        const duplicatedProfile = duplicatedProfilesData?.DuplicatedProfiles[0];
        const oldProfileId = duplicatedProfile ? duplicatedProfile.profile_id : null;

        mergeDuplicatedProfiles({
            variables: {
                new_profile_id: profileId,
                old_profile_id: oldProfileId
            }
        })
            .then((response) => {
                // Обработка успешного объединения профилей
                props.showSmallAlert("Анкеты объединены", "success");
                closeModal();
                setTimeout(() => {
                    props.hideSmallAlert();
                }, 6000);
                // Обновление запроса в кэше Apollo
                client.cache.modify({
                    fields: {
                        DuplicatedProfiles(existingData) {
                            return {
                                ...existingData,
                                // Обновленные поля запроса
                            };
                        },
                    },
                })
            })
            .catch((error) => {
                console.error('Произошла ошибка при объединении профилей:', error);
                // Обработка ошибки при объединении профилей
            });
    };
    const hasDuplicatedProfiles = !loading && duplicatedProfilesData?.DuplicatedProfiles?.length > 0;

    return (
        <>
            {hasDuplicatedProfiles && (
                <button
                    onClick={handleOpenModal}
                    type="button"
                    className="font-semibold py-3 px-4 rounded-lg shadow-xl z-4 mb-4 "
                    style={{
                        background: 'linear-gradient(to right, #FFD700, #FFA500, #FF4500)',
                        color: 'black',
                        fontSize: '26px',
                        margin: '0 10px',
                        width: 'calc(100% - 20px)'
                    }}
                >
                    ВОЗМОЖНЫ ДУБЛИРУЮЩИЕСЯ ЗАПИСИ
                </button>
            )}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="ВОЗМОЖНЫ ДУБЛИРУЮЩИЕСЯ ЗАПИСИ"
                style={{
                    content: {
                        width: '1000px',
                        height: '800px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        border: 'none',
                        borderRadius: '15px 15px 15px 15px',
                        overflow: 'hidden',
                        zIndex: 99999
                    },
                    overlay: {backgroundColor: 'rgba(0, 0, 0, 0.8)'},
                }}
            >
                <div style={{fontSize: '26px'}}>
                    <h3 style={{fontWeight: 'bold', marginBottom: '16px', marginTop: '5px'}}>Возможные
                        дубликаты:</h3>
                    <ul style={{listStyle: 'none', paddingLeft: 0}}>
                        {duplicatedProfiles.map((profile) => (
                            <li key={profile.profile_id} style={{
                                marginBottom: '9px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <a
                                    href={`/user/${profile.profile_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => profileClickHandler(profile.profile_id, profile.company_id, profile.company_name)}
                                    style={{fontSize: '30px'}}
                                >
                                    {profile.firstName} {profile.lastName} {profile.patronymic} {profile.birthDate} <br/>
                                    <span>{`Статус: `}</span>
                                    <span style={{ color: (profile.status_id === "12" || profile.status_id === "10") ? 'palegreen' : 'indianred' }}>{profile.status_title}</span>                                </a>
                                <button
                                    onClick={() => handleMergeProfile()}
                                    type="button"
                                    className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg ml-4`}
                                >
                                    Объединить
                                </button>
                            </li>

                        ))}
                    </ul>
                </div>
            </Modal>
        </>
    );
}

const mapDispatchToProps = {
    showSmallAlert,
    hideSmallAlert
}
export default connect(null, mapDispatchToProps)(withRouter(DuplicatedProfile));
