import React from "react";

// компонент выводится в модалке добаления файла, после того как пользователь выбрал файл
export default function File(props) {
  return (
    <div className="relative mt-4 flex w-11/12 mx-auto flex-col sm:flex-row sm:items-center bg-gray-50 shadow-md rounded-md py-5 pl-6 pr-8 sm:pr-6">
      <div className="flex flex-row items-center border-b sm:border-b-0 sm:w-auto pb-4 sm:pb-0">
        <div className="text-green-500">
          <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
          </svg>
        </div>
        <div className="text-sm font-medium ml-3 truncate w-96">{props.name}</div>
      </div>
      <div onClick={()=>props.onDelete(props.name)} className="absolute sm:relative sm:top-auto sm:right-auto ml-auto right-4 top-5 text-gray-400 hover:text-gray-800 cursor-pointer">
        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      </div>
    </div>
  );
}
