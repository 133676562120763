import { SHOW_ALERT, HIDE_ALERT } from "../types"

export const showBottomAlert = (message, type) => {
  const payload = {
    bottomAlertVisible: true,
    message, type
  }
  return { type: SHOW_ALERT, payload }
}
export const hideBottomAlert = () => {
  const payload = {
    bottomAlertVisible: false
  }
  return { type: HIDE_ALERT, payload }
}

export const showSmallAlert = (message, type) => {
  const payload = {
    smallAlertVisible: true,
    message, type
  }
  return { type: SHOW_ALERT, payload }
}

export const hideSmallAlert = () => {
  const payload = {
    smallAlertVisible: false
  }
  return { type: HIDE_ALERT, payload }
}