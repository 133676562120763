import { useQuery, useLazyQuery } from "@apollo/client"
import dateAndTime from 'date-and-time'
import ru from 'date-and-time/locale/ru'
import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import { useGlobalFilter, usePagination, useRowSelect, useTable } from "react-table"
import { CREATE_SVERKA, GET_REVISES, CREATE_GLOBAL_SVERKA } from "../../graphql/Queries"
import { Loader } from "../Loader"

dateAndTime.locale(ru)

const RevisesList = () => {

  let { error, loading, data } = useQuery(GET_REVISES, {
    variables: { 
      companyId: +localStorage.getItem('company_id'),
    },
    fetchPolicy: "cache-and-network",
  })

  const [ createSverka ] = useLazyQuery(CREATE_GLOBAL_SVERKA, {
    variables: { 
      companyId: +localStorage.getItem('company_id'),
    },
    fetchPolicy: "cache-and-network",
  })

  const [ createGlobalSverka ] = useLazyQuery(CREATE_SVERKA, {
    variables: { 
      companyId: +localStorage.getItem('company_id'),
    },
    fetchPolicy: "cache-and-network",
  })

  const CreateSverka = async () => {
      createSverka();
      window.location.reload()
  };

  const CreateGlobalSverka = async () => {
    createGlobalSverka();
    window.location.reload()
};

  let history = useHistory()

  const columns = React.useMemo(
    () => [
      {
        Header: "Период",
        accessor: "period",
        Cell: ({ row }) => (
          <>
            <span>Cверка от {row.original.period}</span>
          </>
        ), 
      },
      {
        Header: "Файлы",
        accessor: "fired",
      },
  ],[])
    
  const rowClickHandler = (sverkaId) => {
    if (sverkaId) {
      history.push(`/revise/${sverkaId}`)
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page, 
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    } = useTable(
      { initialState: {pageSize: 10}, 
      columns, 
      data: useMemo(
        () => data ? data?.Revises : [],
      [data]
    )}, 
    useGlobalFilter, 
    usePagination, 
    useRowSelect, 
  )

  return (
    <div className="shadow-lg rounded-lg overflow-hidden border">
      <div className="h-full flex flex-col">
        <div className="flex flex-col xl:flex-row justify-center items-center w-full py-6 xl:px-4 rounded-t-lg bg-white">
          <p className="text-xl leading-6 font-medium text-gray-900">Доступные сверки</p>
        </div>
        <div className="px-5 grid grid-cols-2 gap-5">
          <div className="rounded-t-lg bg-white">
            <button onClick={() => CreateSverka()}type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded w-full">
              Создать сверку за прошлый месяц (основная)
            </button>
          </div>
          <div className="rounded-t-lg bg-white">
            <button onClick={() => CreateGlobalSverka()}type="button" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded w-full">
              Создать сверку за текущий месяц (предварительная)
            </button>
          </div>
        </div>
      {loading ? <div className="w-full bg-white pb-8 rounded-b-lg"><Loader /></div> :
        <>
          <table {...getTableProps()} className="w-full border-callapse table-fixed tableHoverGray">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr className="overflow-y-auto bg-white w-full overflow-hidden shadow-xl" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th 
                      className={`uppercase text-center py-6 leading-4 text-gray-700 tracking-wider relative border-b`}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="mt-4 tableBody">
              {page.map(row => {
                prepareRow(row)
                return (
                  <>
                    <tr className="text-center cursor-pointer" {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td 
                                  onClick={() => rowClickHandler(row?.original?.sverka_id)} 
                                  className={`py-6 bg-white`}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell')}
                                </td>
                      })}
                    </tr>
                  </>
                )
              })}
            </tbody>
          </table>
          {/* Страницы */}
          {pageOptions.includes(1) && (
            <div className="pagination flex flex-row justify-center xl:justify-start xl:pl-8 py-4 border bg-white">
              <div className="flex items-center">
                <button onClick={() => gotoPage(0)} type="button" className="focus:outline-none w-full p-3 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed" disabled={!canPreviousPage}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                    </svg>
                </button>
                <button onClick={() => previousPage()} type="button" className="focus:outline-none w-full border-t p-3 border-b border-r font-semibold text-base text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed" disabled={!canPreviousPage}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                </button>
                {pageOptions.includes(pageIndex - 2) && (
                  <button onClick={() => gotoPage(pageIndex - 2)} type="button" className={`focus:outline-none text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                    {pageIndex - 1}
                  </button>
                )}
                {pageOptions.includes(pageIndex - 1) && (
                  <button onClick={() => gotoPage(pageIndex - 1)} type="button" className={`focus:outline-none text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                    {pageIndex}
                  </button>
                )}
                <button type="button" className={`focus:outline-none text-indigo-500 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                  {pageIndex + 1}
                </button>
                {pageOptions.includes(pageIndex + 1) && (
                  <button onClick={() => gotoPage(pageIndex + 1)} type="button" className={`focus:outline-none text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                    {pageIndex + 2}
                  </button>
                )}
                {pageOptions.includes(pageIndex + 2) && (
                  <button onClick={() => gotoPage(pageIndex + 2)} type="button" className={`focus:outline-none text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                    {pageIndex + 3}
                  </button>
                )}
                <button onClick={() => nextPage()} type="button" className="focus:outline-none w-full border-t p-3 border-b border-r font-semibold text-base text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed" disabled={!canNextPage}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
                <button onClick={() => gotoPage(pageCount - 1)}type="button" className="focus:outline-none w-full p-3 border-t border-b border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed" disabled={!canNextPage} >
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                  </svg>
                </button>
              </div>
            </div>
          )}
          </>
        }
      </div>
    </div>
  )
}

export default RevisesList
