import { useQuery } from '@apollo/client'
import { Popover, Transition } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { GET_HISTORY } from '../graphql/Queries'
import { Portal } from '../services/Portal'
import { Loader } from './Loader'

export default function Notification() {
  let [missedMessages, setMissedMessages] = useState(0)
  // библиотека popper js для отображения дропдаунов
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  })

  const { error, loading, data, previousData } = useQuery(GET_HISTORY, {
    // 'dateStart': "2021-07-27", 'dateEnd': "2021-07-28"
    variables: {companyId: localStorage.getItem('company_id')},
    pollInterval: 60000,
  })
  if (error) console.log(`Error graphql: ${error}`)

  // обновление счетчика уведомлений
  useEffect(() => {
    if (previousData && data) {
      if ((data.Status_history.length - previousData.Status_history.length) > 0) {
        setMissedMessages(data.Status_history.length - previousData.Status_history.length)
      }
    }
  }, [data, previousData])

  return (
     // компонент из headless Ui
    <Popover className="relative">
      {({ open }) => (
        <>
          <div 
            onClick={()=>setMissedMessages(0)}
            ref={setReferenceElement}
          >
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                relative text-black group bg-orange-700 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <BellIcon
                className={`${open ? '' : 'text-opacity-70'}
                  h-5 w-5 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
              {missedMessages > 0 && (
                <div className="origin-top-right -top-1 -right-1 absolute rounded-full bg-red-600 text-white text-xs w-4 h-4 flex items-center justify-center">
                  {missedMessages}
                </div>
              )}
            </Popover.Button>
          </div>
          <Portal>
            <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              className="absolute z-50 "
            >
              <Popover.Panel 
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                >
                <div className="w-screen max-w-sm mt-3 lg:max-w-xl notification bg-white overflow-scroll border rounded-lg shadow-lg relative grid gap-8 p-7">
                  {loading 
                    ? <Loader/> 
                    : data?.Status_history.length > 0 
                    ? (
                      <>
                          {data.Status_history.map((item, idx) => (
                            <div
                              key={`status-history-row-${idx}`}            
                              className="border border-gray-100 py-2 items-center -m-3 rounded-lg "
                            >
                              <div className="ml-4 flex flex-col mx-4">
                                <div className="w-full text-sm font-medium text-gray-900 flex items-center justify-between">
                                  <div>{item.lastName} {item.firstName}</div> 
                                  <div>{item.title}</div>
                                </div>
                                <p className="text-sm text-gray-500">
                                  {item.date} {item.name}
                                </p>
                              </div>
                            </div>
                          ))}
                        </>
                      )
                    : (
                      <p>За сегодня нет новых событий.</p>
                    )
                  }
                </div>
              </Popover.Panel>
            </Transition>
          </Portal>
        </>
      )}
    </Popover>
  )
}
