import {
    HIDE_ALERT,
    SHOW_ALERT,
    SHOW_LOADER,
    ADD_FILE_TOKEN,
    HIDE_LOADER,
    FETCH_DATA,
    CHANGE_STAGE,
    SET_FILES,
    SIGN_IN,
    CHANGE_DID_NOT_WORK,
    CHANGE_ONLY_SCHOOL,
    LOG_OUT,
    CHANGE_PATENT_SHOW,
    SHOW_NONE,
    DELETE_FILES,
    CHANGE_TO_BACK_STAGE,
    SHOW_SECTION_NINE,
    SHOW_RVP,
    SHOW_VNG,
    FILE_LOADER,
    SHOW_STUDY,
    SET_COUNTRY,
    FILE_ADD,
    FILE_REMOVE,
    INC_COUNT_SENT_FILES,
    FILE_CLEAR
} from './types'

const handlers = {
    [SHOW_ALERT]: (state, {payload}) => ({...state, ...payload, alertVisible: true}), 
    [HIDE_ALERT]: state => ({...state, alertVisible: false}),
    [SHOW_LOADER]: state => ({...state, loading: true}),
    [HIDE_LOADER]: state => ({...state, loading: false}),
    [FETCH_DATA]: (state, {payload}) => ({...state, data: payload, loading: false}),
    [CHANGE_STAGE]: state => ({...state, currentPageIndex: state.currentPageIndex + 1}),
    [CHANGE_TO_BACK_STAGE]: state => ({...state, currentPageIndex: state.currentPageIndex - 1}),
    [SET_FILES]: (state, {payload}) => ({...state, files: [...state.files, ...payload.files]}),
    [DELETE_FILES]: (state, {payload}) => ({...state, files: payload.files}),
    [SIGN_IN]: (state, {payload}) => ({...state, token: payload}),
    [LOG_OUT]: state => ({...state, token: ""}),
    [SET_COUNTRY]: (state, {payload}) => ({...state, country: payload}),
    [SHOW_SECTION_NINE]: (state, {payload}) => ({...state, showSectionNine: payload}),
    [CHANGE_ONLY_SCHOOL]: (state, {payload}) => ({...state, onlySchool: payload}),
    [CHANGE_DID_NOT_WORK]: (state, {payload}) => ({...state, didNotWork: payload}),
    [CHANGE_PATENT_SHOW]: (state, {payload}) => ({...state, showPatent: payload}),
    [SHOW_NONE]: (state, { payload }) => ({ ...state, showNone: payload }),
    [SHOW_RVP]: (state, {payload}) => ({...state, showRvp: payload}),
    [SHOW_VNG]: (state, {payload}) => ({...state, showVng: payload}),
    [SHOW_STUDY]: (state, {payload}) => ({...state, showStudy: payload}),
    [ADD_FILE_TOKEN]: (state, {payload}) => ({...state, fileTokens: [...state.fileTokens, payload]}),
    [FILE_LOADER]: (state, {payload}) => ({...state, fileLoader: payload}),
    [FILE_ADD]: (state, {payload}) => ({...state, filesData: [...state.filesData, payload]}),
    [INC_COUNT_SENT_FILES]: (state) => ({...state, countSentFiles: state.countSentFiles + 1}),
    [FILE_REMOVE]: (state, {payload}) => ({...state, filesData: [...state.filesData.filter(i => i.newName !== payload)]}),
    [FILE_CLEAR]: (state) => ({...state, filesData: []}),
    DEFAULT: state => state
}

export const formReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT

    return handle(state, action)
}