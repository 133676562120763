export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const FETCH_DATA = 'FETCH_NOTES'
export const SEND_DATA = 'SEND_DATA'
export const CHANGE_STAGE = 'CHANGE_STAGE'
export const CHANGE_TO_BACK_STAGE = 'CHANGE_TO_BACK_STAGE'
export const SHOW_SECTION_NINE = 'SHOW_SECTION_NINE'
export const SET_FILES = 'GET_FILES'
export const SIGN_IN = "SIGN_IN"
export const LOG_OUT = "LOG_OUT"
export const CHANGE_PATENT_SHOW = "CHANGE_PATENT_SHOW"
export const SHOW_NONE = 'SHOW_NONE';
export const DELETE_FILES = "DELETE_FILES"
export const CHANGE_ONLY_SCHOOL = "CHANGE_ONLY_SCHOOL"
export const CHANGE_DID_NOT_WORK = "CHANGE_DID_NOT_WORK"
export const SHOW_RVP = "SHOW_RVP"
export const SET_COUNTRY = "SET_COUNTRY"
export const SHOW_VNG = "SHOW_VNG"
export const ADD_FILE_TOKEN = "ADD_FILE_TOKEN"
export const FILE_LOADER = "FILE_LOADER"
export const FILE_ADD = "FILE_ADD"
export const INC_COUNT_SENT_FILES = "INC_COUNT_SENT_FILES"
export const FILE_REMOVE = "FILE_REMOVE"
export const FILE_CLEAR = "FILE_CLEAR"
export const SHOW_STUDY = "SHOW_STUDY"




