import { OPEN_WORKER_SETTINGS, CLOSE_WORKER_SETTINGS, OPEN_ADD_COMPANY, CLOSE_ADD_COMPANY } from "../types"


export const openWorkerSettings = (data) => {
  const payload = {workerSettingsData: data}
  return {type: OPEN_WORKER_SETTINGS, payload}
}

export const closeWorkerSettings = () => {
  return {type: CLOSE_WORKER_SETTINGS}
}

export const openAddCompany = () => {
  return {type: OPEN_ADD_COMPANY}
}

export const closeAddCompany = () => {
  return {type: CLOSE_ADD_COMPANY}
}