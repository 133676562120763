import React from "react"
import RvpVngStudyPatentOther from "./inputs/RvpVngStudyPatentOther";

export default function SectionNine({countryData}) {
  return (
      <>
        {countryData === null ? null : countryData?.name === 'Российская Федерация' ? null : <RvpVngStudyPatentOther name="RvpVngStudyPatent" country={countryData} />}
      </>
  )
}
