import React from 'react'
import { useField } from 'formik'
import { Error } from './Error'
import InputMask from "react-input-mask";

const formatChars = {
  '0': '[0123]',
  '1': '[01]',
  '2': '[12]',
  '3': '[09]',
  '9': '[0123456789]'
};

export const TextInput = ({ label, ...props }) => {

  const [field, meta] = useField(props)

  return (
    <>
      {props.type === "date" ? (
        <div className="mb-4">
          <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={props.name}>{label}</label>
          <InputMask name={props.name} mask="09.19.2399" formatChars={formatChars} required className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...field}  />
          {meta.touched && meta.error && (
            <Error>{meta.error}</Error>)}
        </div>
      ) : props.type === "year" ? (
        <div className="mb-4">
          <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={props.name}>{label}</label>
          <InputMask name={props.name} mask="9999" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...field}  />
          {meta.touched && meta.error && (
            <Error>{meta.error}</Error>)}
        </div>
      ) : (
        <div className="mb-4">
          <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={props.id || props.name}>{label}</label>
          <input className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...field} {...props} />
          {meta.touched && meta.error && (
            <Error>{meta.error}</Error>)}
        </div>
      )}
    </>
  );
};