import React from 'react'
import Header from '../components/Header'
import RevisesList from '../components/revise/RevisesList'

export const ReviseTable = () => (
  <>
    <Header />
    <div className="flex flex-col">
      <section className="flex flex-col lg:flex-row justify-between lg:px-12 sm:px-4 mb-8 px-2">
        <div className="w-full flex-1 relative z-40">
          <RevisesList />
        </div>
      </section>
    </div>
  </>
)

export default ReviseTable
