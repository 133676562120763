import React, { useEffect, useContext } from 'react'
import { useFormikContext } from "formik"
import { Select } from './Select'
import { TextInput } from './TextInput'
import { FormContext } from "../context/FormContext"

export default function Education() {
  const { values } = useFormikContext()
  const { changeOnlySchool } = useContext(FormContext)

  useEffect(() => {
    if (values.onlySchool) {
      changeOnlySchool(true)
      values.educationLevel = "Выберите один из вариантов"
      values.entryYear1 = ""
      values.exitYear1 = ""
      values.schoolName1 = ""
      values.specialty1 = ""
      values.entryYear2 = ""
      values.exitYear2 = ""
      values.schoolName2 = ""
      values.specialty2 = ""
      values.entryYear3 = ""
      values.exitYear3 = ""
      values.schoolName3 = ""
      values.specialty3 = ""
    } else {
      changeOnlySchool(false)
    }
  }, [values.onlySchool])

  return (
    <>
      {!values.onlySchool && (
        <>
          <Select name="educationLevel" id="32" label="Уровень профессионального образования *"
            options={[
              {
                id: 1,
                name: "Выберите один из вариантов"
              },
              {
                id: 2,
                name: "Среднее профессиональное"
              },
              {
                id: 3,
                name: "Высшее"
              }
            ]} 
          />
          <div className="hidden md:block">
            <div className="w-full flex justify-between">
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Год поступления и окончания *</h2>
                <div className="flex gap-4">
                  <TextInput name="entryYear1" type="year"/>
                  <TextInput name="exitYear1" type="year"/>
                </div>
              </div>
              <div className="flex-1 flex-col h-full mx-8">
                <h2 className="mb-4">Название учебного заведения *</h2>
                <TextInput name="schoolName1" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2 className="mb-4">Квалификация, специальность *</h2>
                <TextInput name="specialty1" type="text"/>
              </div>
            </div>
            <hr></hr>
            <div className="w-full flex justify-between mt-4">
              <div className="flex-1 flex-col h-full">
                <div className="flex gap-4">
                  <TextInput name="entryYear2" type="year"/>
                  <TextInput name="exitYear2" type="year"/>
                </div>
              </div>
              <div className="flex-1 flex-col h-full mx-8">
                <TextInput name="schoolName2" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <TextInput name="specialty2" type="text"/>
              </div>
            </div>
            <hr></hr>
            <div className="w-full flex justify-between mt-4">
              <div className="flex-1 flex-col h-full">
                <div className="flex gap-4">
                  <TextInput name="entryYear3" type="year"/>
                  <TextInput name="exitYear3" type="year"/>
                </div>
              </div>
              <div className="flex-1 flex-col h-full mx-8">
                <TextInput name="schoolName3" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <TextInput name="specialty3" type="text"/>
              </div>
            </div>
          </div>

          {/* sm */}
          <div className="md:hidden">
            <div className="w-full flex-col justify-between">
              <div className="flex-1 flex-col">
                <h2>Год поступления и окончания *</h2>
                <div className="flex gap-4">
                  <TextInput name="entryYear1" type="year"/>
                  <TextInput name="exitYear1" type="year"/>
                </div>
              </div>
              <div className="flex-1 flex-col">
                <h2>Название учебного заведения *</h2>
                <TextInput name="schoolName1" type="text"/>
              </div>
              <div className="flex-1 flex-col">
                <h2>Квалификация, специальность *</h2>
                <TextInput name="specialty1" type="text"/>
              </div>
            </div>
            <hr></hr>
            <div className="w-full flex-col justify-between mt-4">
              <div className="flex-1 flex-col h-full">
                <h2>Год поступления и окончания</h2>
                <div className="flex gap-4">
                  <TextInput name="entryYear2" type="year"/>
                  <TextInput name="exitYear2" type="year"/>
                </div>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2>Название учебного заведения</h2>
                <TextInput name="schoolName2" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2>Квалификация, специальность</h2>
                <TextInput name="specialty2" type="text"/>
              </div>
            </div>
            <hr></hr>
            <div className="w-full flex-col justify-between mt-4">
              <div className="flex-1 flex-col h-full">
                <h2>Год поступления и окончания</h2>
                <div className="flex gap-4">
                  <TextInput name="entryYear3" type="year"/>
                  <TextInput name="exitYear3" type="year"/>
                </div>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2>Название учебного заведения</h2>
                <TextInput name="schoolName3" type="text"/>
              </div>
              <div className="flex-1 flex-col h-full">
                <h2>Квалификация, специальность</h2>
                <TextInput name="specialty3" type="text"/>
              </div>
            </div>
          </div>

        </>
      )}
    </>
  )
}

// {
//   label: "Уровень профессионального образования *",
//   initialValue: "",
//   id: "32",
//   config: {
//     name: "educationLevel",
//     type: "select",
//     options: [
//       {
//         id: 1,
//         name: "Выберите один из вариантов"
//       },
//       {
//         id: 2,
//         name: "Среднее профессиональное"
//       },
//       {
//         id: 3,
//         name: "Высшее"
//       }
//     ]
//   }
// },
// {
//   label: "Год поступления и окончания *",
//   initialValue: "",
//   id: "33",
//   config: {
//     name: "entryYear1",
//     type: "text",
//   }
// },
// {
//   initialValue: "",
//   id: "34",
//   config: {
//     name: "exitYear1",
//     type: "text",
//   }
// },
// {
//   label: "Название учебного заведения *",
//   initialValue: "",
//   id: "35",
//   config: {
//     name: "schoolName1",
//     type: "text",
//   }
// },
// {
//   label: "Квалификация, специальность *",
//   initialValue: "",
//   id: "36",
//   config: {
//     name: "specialty1",
//     type: "text",
//   }
// },
// {
//   label: "(Если есть) Год поступления и окончания ",
//   initialValue: "",
//   id: "37",
//   config: {
//     name: "entryYear2",
//     type: "text",
//   }
// },
// {
//   initialValue: "",
//   id: "38",
//   config: {
//     name: "exitYear2",
//     type: "text",
//   }
// },
// {
//   label: "Название учебного заведения ",
//   initialValue: "",
//   id: "39",
//   config: {
//     name: "schoolName2",
//     type: "text",
//   }
// },
// {
//   label: "Квалификация, специальность",
//   initialValue: "",
//   id: "40",
//   config: {
//     name: "specialty2",
//     type: "text",
//   }
// },
// {
//   label: "(Если есть) Год поступления и окончания",
//   initialValue: "",
//   id: "41",
//   config: {
//     name: "entryYear3",
//     type: "text",
//   }
// },
// {
//   initialValue: "",
//   id: "42",
//   config: {
//     name: "exitYear3",
//     type: "text",
//   }
// },
// {
//   label: "Название учебного заведения",
//   initialValue: "",
//   id: "43",
//   config: {
//     name: "schoolName3",
//     type: "text",
//   }
// },
// {
//   label: "Квалификация, специальность",
//   initialValue: "",
//   id: "44",
//   config: {
//     name: "specialty3",
//     type: "text",
//   }
// },
