import React from 'react'
import { useField } from 'formik'
import { Error } from './Error'

export const Select = ({ label, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <div className="flex flex-col mb-4">
      <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={props.id || props.name}>{label}</label>
      <select className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" {...field} {...props}> 
        {props.options?.map(option => (
          <option key={option.id} value={option.hasOwnProperty('initial') ? '' : option.id}>{option.name}</option>
        ))}
      </select>
      {meta.touched && meta.error && (
        <Error>{meta.error}</Error>
      )}
    </div>
  )
}