import { useQuery } from '@apollo/client'
import React, { useEffect, useMemo } from 'react'
import { GET_TRUSTED_MANAGERS } from '../../graphql/Queries'


export default function TrustedManagersInput({trustedManagersSelect, setTrustedManagersSelect}) {

  let { error, data } = useQuery(GET_TRUSTED_MANAGERS, {
    variables: { 
      companyId: +localStorage.getItem('company_id'),
     },
  })
  if (error) console.log(error)

  const trusted_managers = useMemo(
      () => data ? data?.TrustedManagers : [],
    [data]
  )

  useEffect(()=>{
    setTrustedManagersSelect(trusted_managers[0]?.id);
  }, [trusted_managers])

  return (
    <>
      <div>
       <label className="flex pl-2 pb-1">Доверенное лицо</label>
        <select
          value={trustedManagersSelect}
          onChange={event => setTrustedManagersSelect(event.target.value)}
          className="form-select rounded-lg w-full flex"
          id="trusted_manager">
          {trusted_managers.map((trusted_manager) => (
            <option key={trusted_manager.id.toString()} value={trusted_manager.id.toString()}>{trusted_manager.short_name}</option>
          ))}
        </select>
      </div>
    </>
  )
}
