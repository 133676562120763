import React from 'react'
import { withRouter } from "react-router"

function StatusHistory({data}) {

  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 ">
          История статусов
        </h3>
      </div>
      <div className="border-t border-gray-200">
        <div className="flex-row">
            {data && data.map((status, idx) => (
              <div key={`status-history-id-${idx}`} className="mb-2">
                <div className="flex pt-2 px-4">
                  <p className="leading-7 text-sm">{status.date}</p>
                  <p className="leading-7 text-md ml-2">{status.title}</p>
                </div>
                {status.comment && status.comment.length > 0 && (
                  <div className="flex px-4">
                    <p className="text-sm text-gray-500">
                     Причина перевода: {status.comment}
                    </p>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

export default withRouter(StatusHistory)
