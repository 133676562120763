import React from 'react'
import { useField } from 'formik'
import { Error } from './Error'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

export const Phone = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <div className="mb-4">
      <label className="leading-7 text-md text-gray-900 font-semibold" htmlFor={props.id || props.name}>{label}</label>
      <PhoneInput
        country={'ru'}
        inputStyle={{
          width: "100%",
          height: "45px",
        }}
        value={field.value}
        placeholder={props.placeholder}
        autoFormat={false}
        inputProps={{
          name: props.name,
          onBlur: field.onBlur,
          onChange: field.onChange,
        }}
      />
      {meta.touched && meta.error && (
        <Error>{meta.error}</Error>)}
    </div>
  );
};