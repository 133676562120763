import {useMutation} from "@apollo/client"
import {CheckIcon, XIcon} from "@heroicons/react/outline"
import {UserIcon} from "@heroicons/react/solid"
import React, {useEffect, useRef, useState} from "react"
import {connect} from "react-redux"
import {useParams} from "react-router"
import {Link} from "react-router-dom"
import {useExpanded, useGlobalFilter, useGroupBy, usePagination, useRowSelect, useSortBy, useTable} from "react-table"
import {ACCEPT_REVISE_CHANGE, GET_REVISE} from "../../graphql/Queries"
import {selectCanceledIds, selectFilteredIds} from "../../redux/revise/reviseActions"
import {Loader} from "../Loader"
import InputField from "../Profile/InputField"
import TableHeaderCell from "../table/TableHeaderCell"
import CancelCommentModal from "./CancelCommentModal"


const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input className="ml-6 rounded focus:outline-none focus:ring-0 text-gray-700" type="checkbox"
                       ref={resolvedRef} {...rest} />
            </>
        )
    }
)

const EmployeesList = ({
                           data,
                           loading,
                           reject = false,
                           buttons = true,
                           tableTitle,
                           getReviseData,
                           selectCanceledIds,
                           selectFilteredIds
                       }) => {

    let {id} = useParams();

    const [acceptReviseChange] = useMutation(ACCEPT_REVISE_CHANGE)
    const [isOpenCancel, setIsOpenCancel] = useState(false)
    const [selectedProfile, setSelectedProfile] = useState()
    const skipPageResetRef = useRef()
    const [filterInput, setFilterInput] = useState("")

    // необходимо сбрасывать при каждом рендере
    useEffect(() => {
        skipPageResetRef.current = false
    })

    // отказ в переводе анкеты
    const cancelHandler = (profileId) => {
        setIsOpenCancel(true)
        setSelectedProfile(profileId)
    }
    // принятие перевода анкеты
    const acceptHandler = (profileId) => {
        acceptReviseChange({
            variables: {
                profileId,
                sverkaId: id,
                companyId: localStorage.getItem('company_id'),
            }, refetchQueries: [
                {query: GET_REVISE, variables: {companyId: +localStorage.getItem("company_id"), reviseId: id}},
            ],
            awaitRefetchQueries: true
        })
    }

    // обработчик поиска
    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilterInput(value)
        setGlobalFilter(value)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "",
                accessor: "profileBtn",
                Cell: ({row}) => (
                    "original" in row && (
                        <Link to={`/user/${row.original.profile_id}`} target="_blank" rel="noopener noreferrer">
                            <div className="text-gray-600 hover:text-gray-900 pl-2">
                                <UserIcon className="w-6 h-6 rounded-full"/>
                            </div>
                        </Link>
                    )
                )
            },
            {
                Header: "ФИО",
                accessor: "name",
            },
            {
                Header: "Точка",
                accessor: "title",
                Cell: ({row}) => {
                    if ("original" in row) {
                        return <InputField
                            field="jobAddress"
                            data={row.original?.title || "Нет ТТ"}
                            manager={false}
                            isRevise={true}
                            profileIdFromProps={row.original.profile_id}
                            reviseId={id}
                            getReviseData={getReviseData}
                            skipPageResetRef={skipPageResetRef}
                        />
                    } else if (row.values?.title) {
                        // если сгрупировали по этому полю
                        return <span>{row.values?.title}</span>
                    } else return null
                }
            },
            {
                Header: "ИП",
                accessor: "legal_entity",
            },
            {
                Header: "Принят",
                accessor: "hired",
            },
            {
                Header: "Уволен",
                accessor: "fired",
            },
            {
                Header: "",
                accessor: "btns",
                Cell: ({row}) => (
                    (!row?.original?.accept && "original" in row) &&
                    <div className="flex">
                        <button onClick={() => acceptHandler(row.original.profile_id)}
                                className="rounded-2xl bg-green-500 text-white hover:bg-green-700 shadow-lg py-1 px-4 cursor-pointer focus:outline-none">
                            <CheckIcon className="w-5 h-5"/>
                        </button>
                        <button onClick={() => cancelHandler(row.original.profile_id)}
                                className="ml-2 lg:mr-2 rounded-2xl bg-red-500 text-white hover:bg-red-700 shadow-lg py-1 px-4 cursor-pointer focus:outline-none">
                            <XIcon className='w-5 h-5'/>
                        </button>
                    </div>

                )
            },
        ], [])

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        selectedFlatRows,
        setGlobalFilter,
        state: {pageIndex},
    } = useTable(
        {
            initialState: {
                pageSize: 10,
                pageIndex: +window.sessionStorage.getItem('numberPage' + tableTitle) || 0
            },
            columns,
            data,
            autoResetPage: !skipPageResetRef.current,
            autoResetExpanded: !skipPageResetRef.current,
            autoResetGroupBy: !skipPageResetRef.current,
            autoResetSelectedRows: !skipPageResetRef.current,
            autoResetSortBy: !skipPageResetRef.current,
            autoResetFilters: !skipPageResetRef.current,
            autoResetRowState: !skipPageResetRef.current,
        },
        useGlobalFilter, useGroupBy, useSortBy, useExpanded, usePagination, useRowSelect,
        hooks => {
            if (buttons) {
                hooks.visibleColumns.push((columns) => [
                    {
                        id: "selection",
                        Header: ({getToggleAllPageRowsSelectedProps}) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                            </div>
                        ),
                        Cell: ({row}) => (
                            <div>
                                {row?.original?.blocked && row?.original?.accept &&
                                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />}
                            </div>
                        )
                    },
                    ...columns
                ]);
            }
        })

    // выбор анкет
    useEffect(() => {
        if (selectedFlatRows && selectFilteredIds && selectCanceledIds) {
            let selectedRows = []
            selectedFlatRows.forEach(row => {
                if (row?.original?.profile_id) {
                    selectedRows.push(row.original.profile_id)
                }
            })
            if (reject) {
                selectCanceledIds(selectedRows)
            } else {
                selectFilteredIds(selectedRows)
            }
        }
    }, [selectedFlatRows])

    useEffect(() => {
        window.sessionStorage.setItem('numberPage' + tableTitle, pageIndex);
    }, [pageIndex])
    return (
        <>
            <CancelCommentModal isOpen={isOpenCancel} setIsOpen={setIsOpenCancel} profileId={selectedProfile}/>
            <div>
                <div className="h-full flex flex-col ">
                    <div
                        className="flex flex-col xl:flex-row justify-between items-center w-full pt-6 pb-4 xl:px-4 rounded-md">
                        <div className="flex">
                            <p className="xl:ml-3 text-xl leading-6 font-medium text-gray-900">{tableTitle}</p>
                            <a
                                href={`/generate?action=sverka&rejected=${Number(reject)}&sverka_id=${id}&company_id=${localStorage.getItem("company_id")}&Authorization=${`Bearer ${localStorage.getItem("token")}`}`}
                                className="ml-2 py-1 px-1 flex justify-center items-center hover:bg-gray-100 focus:ring-gray-500 focus:ring-offset-gray-200  text-gray-800 transition ease-in duration-200 text-center text-base font-semibold hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 rounded"
                            >
                                <div className="w-5 h-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                         fill="currentColor">
                                        <path fillRule="evenodd"
                                              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        <div className="mt-2 xl:mt-0 flex justify-end px-6">
                            <div className="xl:w-64 inline-block relative ">
                                <input
                                    className="leading-snug border border-gray-300 block w-full appearance-none text-sm text-gray-600 py-1 px-4 pl-8 rounded-lg focus:outline-none"
                                    value={filterInput}
                                    onChange={handleFilterChange}
                                    placeholder="Найти анкету"
                                />
                                <div
                                    className="pointer-events-none absolute pl-3 inset-y-0 left-0 flex items-center px-2 text-gray-300">
                                    <svg className="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 511.999 511.999">
                                        <path
                                            d="M508.874 478.708L360.142 329.976c28.21-34.827 45.191-79.103 45.191-127.309C405.333 90.917 314.416 0 202.666 0S0 90.917 0 202.667s90.917 202.667 202.667 202.667c48.206 0 92.482-16.982 127.309-45.191l148.732 148.732c4.167 4.165 10.919 4.165 15.086 0l15.081-15.082c4.165-4.166 4.165-10.92-.001-15.085zM202.667 362.667c-88.229 0-160-71.771-160-160s71.771-160 160-160 160 71.771 160 160-71.771 160-160 160z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading ? <Loader full/> :
                        <div className="overflow-y-scroll">
                            <table {...getTableProps()} className="table-auto border-collapse w-full mb-20">
                                <thead className="overflow-y-auto">
                                {headerGroups.map(headerGroup => (
                                    <tr
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {headerGroup.headers.map(column => (
                                            <th
                                                className={`uppercase py-6 pr-2 border-b-2 border-gray-300 text-left leading-4 text-gray-700 hover:text-gray-900 tracking-wider relative`}
                                                {...column.getHeaderProps()}
                                            >
                                                <div className="flex items-center">
                                                    {/* {column.isSorted ? column.isSortedDesc ? "sort-desc" : "sort-asc" : ""} */}
                                                    <div
                                                        className={` ${column.isSorted || column.isSortedDesc ? "mr-1" : ""}`}>
                                                        {column.isSorted ? column.isSortedDesc ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"
                                                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      strokeWidth={2}
                                                                      d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"/>
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6"
                                                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      strokeWidth={2}
                                                                      d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"/>
                                                            </svg>
                                                        ) : null}
                                                    </div>
                                                    {column.canGroupBy ? (
                                                        <span>
                                  {column.isGrouped ? (
                                      <div onClick={() => column.toggleGroupBy()}
                                           className="text-center h-7 w-7 text-red-600 mr-1 cursor-pointer hover:bg-red-600 hover:text-white transition ease-in duration-200 rounded-full">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none"
                                               viewBox="0 0 24 24" stroke="currentColor">
                                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                          </svg>
                                      </div>
                                  ) : ''}
                                </span>
                                                    ) : null}
                                                    <TableHeaderCell {...column}/>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                                </thead>
                                <tbody {...getTableBodyProps()} className="overflow-y-auto">
                                {page.map((row, idx) => {
                                    prepareRow(row)
                                    return (
                                        <>
                                            <tr {...row.getRowProps()}
                                                className={`cursor-pointer ${row?.original?.comment ? " " : "border-b"} ${!row?.original?.blocked ? row?.original?.accept ? "bg-gray-100" : "" : ""} border-gray-300`}>
                                                {row.cells.map(cell => {
                                                    return <td
                                                        className={`whitespace-nowrap py-2 pr-2 text-sm text-sm${row?.original?.comment ? "pt-2 pb-0" : "py-2"}`}
                                                        {...cell.getCellProps()}
                                                    >
                                                        {cell.isGrouped ? (
                                                            <>
                                                                <div className="flex items-center">
                                          <span className="mr-1" {...row.getToggleRowExpandedProps()}>
                                            {row.isExpanded ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 groupArrow"
                                                     fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                          d="M5 15l7-7 7 7"/>
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     className="h-6 w-6 groupArrow open" fill="none" viewBox="0 0 24 24"
                                                     stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                          d="M5 15l7-7 7 7"/>
                                                </svg>
                                            )}
                                          </span>
                                                                    <p>{cell.render('Cell')} ({row.subRows.length})</p>
                                                                </div>
                                                            </>
                                                        ) : cell.isAggregated ? (
                                                            cell.render('Aggregated')
                                                        ) : cell.isPlaceholder ? null : (
                                                            cell.render('Cell')
                                                        )}
                                                    </td>
                                                })}
                                            </tr>
                                            {/* Комментарий перевода */}
                                            {row?.original?.comment && (
                                                <tr key={`additional-row-${idx}`}
                                                    className={`cursor-pointer border-b border-gray-300 ${!row.original.blocked ? row.original.accept ? "bg-gray-100" : "" : ""}`}>
                                                    {/* если есть кнопки выбора анкет, добавляю пустые ячейки */}
                                                    {buttons && <>
                                                        <td></td>
                                                        <td></td>
                                                    </>}
                                                    <td className="pb-2 pt-0 text-sm" colSpan="8">
                                                        <p><span
                                                            className="underline">Причина</span>: {row.original.comment}
                                                        </p>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )
                                })}
                                </tbody>
                            </table>
                            {/* Страницы */}
                            <div
                                className="pagination absolute bottom-0 flex flex-row justify-center xl:justify-start xl:ml-8 pb-5 left-1/2 transform -translate-x-1/2 xl:left-0 xl:-translate-x-0">
                                <div className="flex items-center">
                                    {/*// Стрелка назад - 2*/}
                                    <button onClick={() => gotoPage(0)} type="button"
                                            className="focus:outline-none w-full p-3 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed"
                                            disabled={!canPreviousPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M11 19l-7-7 7-7m8 14l-7-7 7-7"/>
                                        </svg>
                                    </button>
                                    {/*// Стрелка назад */}
                                    <button onClick={() => previousPage()} type="button"
                                            className="focus:outline-none w-full border-t p-3 border-b border-r font-semibold text-base text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed"
                                            disabled={!canPreviousPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M15 19l-7-7 7-7"/>
                                        </svg>
                                    </button>


                                    {pageOptions.includes(pageIndex - 2) && (
                                        <button onClick={() => gotoPage(pageIndex - 2)} type="button"
                                                className={`focus:outline-none text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                                            {pageIndex - 1}
                                        </button>
                                    )}
                                    {pageOptions.includes(pageIndex - 1) && (
                                        <button onClick={() => gotoPage(pageIndex - 1)} type="button"
                                                className={`focus:outline-none text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                                            {pageIndex}
                                        </button>
                                    )}
                                    <button type="button"
                                            className={`focus:outline-none text-indigo-500 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                                        {pageIndex + 1}
                                    </button>
                                    {pageOptions.includes(pageIndex + 1) && (
                                        <button onClick={() => gotoPage(pageIndex + 1)} type="button"
                                                className={`focus:outline-none text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                                            {pageIndex + 2}
                                        </button>
                                    )}
                                    {pageOptions.includes(pageIndex + 2) && (
                                        <button onClick={() => gotoPage(pageIndex + 2)} type="button"
                                                className={`focus:outline-none text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                                            {pageIndex + 3}
                                        </button>
                                    )}


                                    {/*Стрелка назад - */}
                                    <button onClick={() => nextPage()} type="button"
                                            className="focus:outline-none w-full border-t p-3 border-b border-r font-semibold text-base text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed"
                                            disabled={!canNextPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M9 5l7 7-7 7"/>
                                        </svg>
                                    </button>
                                    {/*// Стрелка впреред +2*/}
                                    <button onClick={() => gotoPage(pageCount - 1)} type="button"
                                            className="focus:outline-none w-full p-3 border-t border-b border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed"
                                            disabled={!canNextPage}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M13 5l7 7-7 7M5 5l7 7-7 7"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    selectFilteredIds,
    selectCanceledIds
}

export default connect(null, mapDispatchToProps)(EmployeesList)
