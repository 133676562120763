import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ADD_PROFILE_FILE_PATENT, GET_PROFILE} from "../../graphql/Queries";
import { togglePatentFileModal } from '../../redux/manager/managerActions';
import backendService from "../../services/backendService";
import File from "./File";

const PatentFileInput = (props) => {
  const [ localFiles, setLocalFiles ] = useState([])
  const [addProfileFilePatent] = useMutation(ADD_PROFILE_FILE_PATENT)

  // обработчик добаления файла
  const handleFileChange = (e) => {
    e.preventDefault();
    // массив добавленных файлов
    const fileArray = [...e.target.files]
    fileArray.forEach(file => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "docx" || fileExtension === "doc") {
        alert("Допустимый формат файла: .pdf, .png, .jpeg!");
        return;
      }
      if (!localFiles.some(localFile => localFile.name === file.name)) {
        // поле newName для бека
        file["newName"] = file.name.slice(0,99)
        // если такого файла нет, то добавить
        setLocalFiles(prev => [...prev, file])
      }
    })
  }

  // удаление файлов из локального массива
  const deleteFile = (fileToDelete) => {
    setLocalFiles(prev => prev.filter(file => file.name !== fileToDelete))
  }

  // обработчик кнопки добавить
  const addButtonHandler = async () => {
    let filesUrls = []
    const promises = []
    // добаление промисов в массив
    localFiles.forEach(file => {
      promises.push(backendService.uploadFiles(file)
          .then(response => {
            return response.data
          })
          .catch((error) => {
            throw error
          })
      )
    })
    // отправка файлов на сервер
    try {
      filesUrls = await Promise.all(promises)
    } catch (e) {
      console.log(e.response.data)
    }
    // отправка токенов файлов на сервер
    if (props.isPatentFileModalOpen) {
      // отправка файлов
      filesUrls.forEach(file => {
        addProfileFilePatent({
          variables: {
            profileId: props.match.params.id,
            companyId: +localStorage.getItem('company_id'),
            fileToken: file
          },
          refetchQueries: [
            {
              query: GET_PROFILE, variables: {
                companyId: localStorage.getItem('company_id'),
                profileId: props.match.params.id
              }
            }
          ],
          awaitRefetchQueries: true
        })
      })
      props.togglePatentFileModal(false)
    }
  }

  return (
    <>
      <div className={`flex flex-col  ${localFiles.length > 0 ? "" : "h-full items-center justify-center -mt-20"} mb-4`}>
        
        {/* Отображение выбранных файлов */}
        {localFiles.map(file => <File name={file.name} key={file.name} onDelete={deleteFile}/>)}

        <label className="leading-7 text-md text-gray-900" htmlFor="documentInput"></label>
          <label className={`${localFiles.length > 0 ? "w-11/12" : "w-2/3 sm:w-1/2"} mx-auto h-16 flex items-center mt-4 py-1 px-4 shadow-md rounded-md bg-green-500 hover:bg-green-600 text-white cursor-pointer `} htmlFor="documentInput">
           <div className="w-6 h-6 mr-4">
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
            </svg>
           </div>
            <p>Нажмите чтобы прикрепить файлы</p>
          </label>
          {localFiles.length > 0 && (
            <button onClick={addButtonHandler} className="self-end w-11/12 mx-auto mt-2 bg-blue-400 hover:bg-blue-500 text-white py-4 focus:outline-none focus:ring-0 transition ease-in duration-200 rounded-md flex justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              Добавить
            </button>
          )}
          <input
            id="documentInput"
            name="documentInput"
            type="file"
            onChange={(e)=>handleFileChange(e)}
            className="hidden"
            multiple />
        </div>
      </>
  )
}
const mapDispatchToProps = {
  togglePatentFileModal
}
const mapStateToProps = state => ({
  isPatentFileModalOpen: state.manager.isPatentFileModalOpen,
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PatentFileInput))