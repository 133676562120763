export const SIGNIN_SUCCESS         = "AUTH/SIGNIN_SUCCESS"
export const SIGNIN_ERROR           = "AUTH/SIGNIN_ERROR"
export const SIGNOUT                = "AUTH/SIGNOUT"

export const CHANGE_COMPANY         = "MANAGER/CHANGE_COMPANY"
export const TOGGLE_LIST            = "MANAGER/TOGGLE_LIST"
export const CHANGE_STATUS          = "MANAGER/CHANGE_STATUS"
export const SELECT_PROFILES_ID     = "MANAGER/SELECT_PROFILES_ID"  
export const COMMENT_INPUT_OPEN     = "MANAGER/COMMENT_INPUT_OPEN" 
export const COMMENT_INPUT_CLOSE    = "MANAGER/COMMENT_INPUT_CLOSE" 
export const FILE_MODAL_TOGGLE      = "MANAGER/FILE_MODAL_TOGGLE" 
export const PATENT_FILE_MODAL_TOGGLE      = "MANAGER/PATENT_FILE_MODAL_TOGGLE"
export const DOCUMENT_MODAL_TOGGLE  = "MANAGER/DOCUMENT_MODAL_TOGGLE"
export const STATUS_DROPDOWN_OPEN   = "MANAGER/STATUS_DROPDOWN_OPEN" 
export const TOGGLE_LOADING         = "MANAGER/TOGGLE_LOADING"
export const RERENDER_TABLE         = "MANAGER/RERENDER_TABLE"
export const CHANGE_ARROWS          = "MANAGER/CHANGE_ARROWS"
export const SET_TO_DEFAULT_MANAGER = "MANAGER/SET_TO_DEFAULT_MANAGER"

export const HIDE_ALERT             = "ALERT/HIDE_ALERT"
export const SHOW_ALERT             = "ALERT/SHOW_ALERT"

export const OPEN_WORKER_SETTINGS   = "SETTINGS/OPEN_WORKER_SETTINGS"
export const CLOSE_WORKER_SETTINGS  = "SETTINGS/CLOSE_WORKER_SETTINGS"
export const OPEN_ADD_COMPANY       = "SETTINGS/OPEN_ADD_COMPANY"
export const CLOSE_ADD_COMPANY      = "SETTINGS/CLOSE_ADD_COMPANY"


export const SELECT_FILTERED_ID     = "REVISE/SELECT_FILTERED_ID"  
export const SELECT_CANCELED_ID     = "REVISE/SELECT_CANCELED_ID"  

