import React from "react"
import { Field, useField, useFormikContext } from "formik"
import { Error } from "./Error"

export const ConvictionRadio = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const [fieldDate, metaDate] = useField({
    name: "criminal_record_extinguished",
    id: 1001
  })
  
  const { values } = useFormikContext()

  const radioChangeHandler = (field, form, variant) => {
    form.setFieldValue(field, variant)
  }
  return (
    <>
      <div className="flex flex-col mb-4">
        <label htmlFor={props.name} className="leading-7 text-md text-gray-900 font-semibold">
          {label}
        </label>
        <div
          role="group"
          aria-labelledby="my-radio-group"
          className="flex flex-col justify-center "
        >
          {props.variants.map((variant, idx) => (
            <label key={variant.id}>
              <Field name="undefined">
                {({ form }) => (
                  <input
                    id={props.name}
                    type="radio"
                    name={props.name}
                    onChange={()=>radioChangeHandler(props.name, form, idx)}
                    onBlur={field.onBlur}
                    value={idx}
                    className="h-5 w-5 text-indigo-600 outline-none"
                    checked={values[props.name] === idx}
                  />
                )}
              </Field>
              <span className="ml-2 text-gray-900">{variant.name}</span>
              { idx + 1 === props.variants.length && (
                <input 
                  className="ml-4 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                  type="text" 
                  placeholder="Год погашения"
                  {...fieldDate}
                />
              )}
              
            </label>
          ))}
          {meta.touched && meta.error && (
        <Error>{meta.error}</Error>)}
        </div>
      </div>
    </>
  )
}
