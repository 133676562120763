import axios from 'axios'
import { client } from '../../App'
import { GET_MANAGER, sendQuery } from '../../graphql/Queries'
import { SIGNIN_ERROR, SIGNIN_SUCCESS, SIGNOUT } from '../types'

export const signin = (login, password) => async dispatch => {
  try {
    let res = await axios.post("/sign_in", {login, password}, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    // в случае успешной авторищации происходит запись токена в localstorage и отправляется запрос на менеджера
    if (res.status === 200) {
      const payload = res.data.token;
      dispatch({ type: SIGNIN_SUCCESS, payload })
      localStorage.setItem('token', payload)
      localStorage.setItem('role_id', res.data.role_id);
      const response = await sendQuery(GET_MANAGER, {fetchPolicy: "no-cache"})
      // имя менеджера записывается в локал сторедж
      localStorage.setItem('manager_name', response.data.Manager.name)
    }
  } catch (e) {
    const payload = "Неправильнно введен логин или пароль."
    dispatch({ type: SIGNIN_ERROR, payload })
    return payload
  }
}

export const signout = () => {
  // очищается кеш в apollo
  client.cache.reset()
  // очищаются все значения в localStorage
  localStorage.removeItem('token')
  localStorage.removeItem('role_id')
  localStorage.removeItem('company_name')
  localStorage.removeItem('company_logo')
  localStorage.removeItem('manager_name')
  localStorage.removeItem('company_id')
  return { type: SIGNOUT }
}