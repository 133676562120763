import { useMutation, useQuery } from "@apollo/client"
import { BanIcon, DownloadIcon } from '@heroicons/react/outline'
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from '@heroicons/react/solid'
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import Header from '../components/Header'
import CommentModal from '../components/revise/CommentModal'
import EmployeesList from '../components/revise/EmployeesList'
import SummaryTable from '../components/revise/SummaryTable'
import SmallAlert from '../components/SmallAlert'
import { CLOSE_REVISE, GET_REVISE } from "../graphql/Queries"
import { hideSmallAlert, showSmallAlert } from '../redux/alert/alertActions'


export const ReviseTable = ({showSmallAlert, hideSmallAlert, ...props}) => {
  let { id } = useParams();

  let [isOpenComment, setIsOpenComment] = useState(false)
  let [rejected, setRejected] = useState(false)

  const [closeRevise] = useMutation(CLOSE_REVISE, {
    onError: () => {
      showSmallAlert("Есть незакрытые анкеты.", "danger")
      setTimeout(() => {
        hideSmallAlert()
      }, 5000);
    },
  })
  
  let { error, data, refetch: getReviseData } = useQuery(GET_REVISE, {
    variables: { 
      companyId: +localStorage.getItem('company_id'),
      reviseId: id
     },
    fetchPolicy: "cache-and-network",
  })

  if (error) console.log(`Error! ${error}`)

  let dataForFilter = useMemo(() => {
    if (data) {
      return data.Revise?.profiles?.filter(profile => !profile.rejected)
    } else return []
  }, [data])

  let dataForRejected = useMemo(() => {
    if (data) {
      return data.Revise?.profiles?.filter(profile => profile.rejected)
    } else return []
  }, [data])
  

  const changeToRejected = () => {
    setIsOpenComment(true)
    setRejected(true)
  }
  const changeToFiltered = () => {
    setIsOpenComment(true)
    setRejected(false)
  }

  const closeReviseHandler = () => {
    closeRevise({ variables: {
        companyId: +localStorage.getItem('company_id'),
        reviseId: id
      },
      refetchQueries: [{query: GET_REVISE, variables: {companyId: +localStorage.getItem("company_id"), reviseId: id}}],
      awaitRefetchQueries: true
    })
  }

  return (
    <>
      <CommentModal isOpen={isOpenComment} setIsOpen={setIsOpenComment} rejected={rejected}/>
      <Header />
      <SmallAlert left/>
      {/* {loading ? <Loader full /> : ( */}
        <div className="flex flex-col lg:px-12 sm:px-4 mb-8 px-2">

          <div className={`flex flex-col lg:grid ${data?.Revise?.is_open ? "lg:grid-cols-9 " : "lg:grid-cols-2"} z-50`}>
            <div className={`relative rounded-lg border bg-white shadow-lg ${data?.Revise?.is_open ? "lg:col-span-4" : "lg:col-span-1"}`}>
              <EmployeesList buttons={data?.Revise?.is_open} data={dataForFilter} tableTitle="Фильтр" getReviseData={getReviseData}/>
            </div>
            {data?.Revise?.is_open && (
              <>
                <div className="lg:hidden my-4 flex flex-shrink w-full lg:col-span-0">
                  <button onClick={changeToFiltered} disabled={props.selectedCanceledIds.length === 0} className={`py-4 rounded-lg shadow bg-white text-green-500 flex-1 flex items-center justify-center border z-40 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none transition duration-200 ease-in-out ${props.selectedCanceledIds.length === 0 ? "" : "hover:shadow-xl" }`}>
                    <ArrowUpIcon className="w-12 h-12 mx-2"/>
                  </button>
                  <button onClick={changeToRejected} disabled={props.selectedFilteredIds.length === 0} className={`ml-4 rounded-lg shadow bg-white text-red-500 flex-1 flex items-center justify-center border z-40 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none transition duration-200 ease-in-out ${props.selectedFilteredIds.length === 0 ? "" : "hover:shadow-xl" }`}>
                    <ArrowDownIcon className="w-12 h-12 mx-2"/>
                    </button>
                </div>
                <div className="hidden lg:flex flex-shrink mx-4 flex-col lg:col-span-1">
                  <button onClick={changeToRejected} disabled={props.selectedFilteredIds.length === 0} className={`rounded-lg shadow bg-white text-red-500 flex-1 flex items-center justify-center border z-40 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none transition duration-200 ease-in-out ${props.selectedFilteredIds.length === 0 ? "" : "hover:shadow-xl" }`}>
                    <div className="w-12 h-12">
                      <ArrowRightIcon className="w-12 h-12"/>
                    </div>
                  </button>
                  <button onClick={changeToFiltered} disabled={props.selectedCanceledIds.length === 0} className={`mt-4 rounded-lg shadow bg-white text-green-500 flex-1 flex items-center justify-center border z-40 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none transition duration-200 ease-in-out ${props.selectedCanceledIds.length === 0 ? "" : "hover:shadow-xl" }`}>
                    <div className="w-12 h-12">
                      <ArrowLeftIcon className="w-12 h-12"/>
                    </div>
                  </button>
                </div>
              </>
            )}
            <div className={`${!data?.Revise?.is_open && "lg:ml-8 mt-4 lg:mt-0"} relative rounded-lg border bg-white shadow-lg ${data?.Revise?.is_open ? "lg:col-span-4" : "lg:col-span-1"}`}>
              <EmployeesList buttons={data?.Revise?.is_open} data={dataForRejected} tableTitle="Исключение" reject getReviseData={getReviseData} />
            </div>
          </div>

          <div className="w-full lg:mt-8 mb-4 lg:mb-0 flex flex-col xl:grid xl:grid-cols-1 order-first lg:order-2">
            
            <div className="z-50 w-full shadow-lg rounded-lg">
              <SummaryTable data={data?.Revise?.summary}/>
              <div className="flex z-50 w-full ml-auto xl:order-last">
                <div className="ml-auto flex h-20 w-full bg-white border rounded-b-lg overflow-hidden">
                  <div className="flex w-full items-center px-4 py-2">
                    {data?.Revise?.closeText && <p className="text-gray-800">{data?.Revise?.closeText}</p>}
                    {data?.Revise?.buttons && (
                      <button onClick={closeReviseHandler} className="ml-auto whitespace-nowrap h-12 flex items-center py-2 px-4 bg-green-400 rounded-lg text-white shadow-lg transition duration-150 ease-in-out hover:bg-green-600">
                        <BanIcon className="h-5 w-5 mr-2"/>
                        Закрыть сверку
                      </button>
                    )}
                    {!data?.Revise?.is_open && (
                      <a 
                        href={`/generate?action=sverka_summary&sverka_id=${id}&company_id=${localStorage.getItem("company_id")}&Authorization=${`Bearer ${localStorage.getItem("token")}`}`}  
                        className="ml-auto whitespace-nowrap h-12 flex items-center py-2 px-4 bg-green-400 rounded-lg text-white shadow-lg transition duration-150 ease-in-out hover:bg-green-600"
                      >
                        <DownloadIcon className="h-5 w-5 mr-2"/>
                        Загрузить сверку
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  )
}

const mapStateToProps = (state) => ({
  selectedFilteredIds: state.revise.selectedFilteredIds,
  selectedCanceledIds: state.revise.selectedCanceledIds
})

const mapDispatchToProps = {
  showSmallAlert,
  hideSmallAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviseTable)
