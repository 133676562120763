import { useLazyQuery } from "@apollo/client"
import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom"
import { SEARCH_PROFILES } from "../graphql/Queries"


export default function GlobalSearch() {
  let history = useHistory()
  const [search, setSearch] = useState("")
  const [timerId, setTimerId] = useState()
  const [showResults, setShowResults] = useState(false); // Флаг для отображения/скрытия результатов поиска
  const searchRef = useRef(null); // Ссылка на элемент поиска

  // выполенния запроса по вызову функции
  const [executeSearch, { data, loading }] = useLazyQuery(SEARCH_PROFILES)

  // подсвечивание совпадающих букв
  const showInputLetters = (firstName = "", lastName = "", patronymic = "", companyName = "") => {
    const fio = `${lastName.trim()} ${firstName.trim()} ${patronymic.trim()} — \n ${companyName} `;

    return <p>{fio}</p>;
  };

  // ввод текста в инпут
  const handleSearchChange = e => {
    const value = e.target.value;
    setSearch(value)
  }
  // нажатие на профиль
  const profileClickHandler = (profileId, companyId, companyName) => {
    if (profileId) {
      localStorage.setItem('company_id', companyId);
      localStorage.setItem('company_name', companyName);
      history.push(`/user/${profileId}`)
    }
  }

  // обработчик поиска
  // поиск выполняется:
  // если введено больше 2 символов
  // через секуду после ввода символов
  // если не ввели другие сиволы, иначе обновляется таймер
  useEffect(()=>{
    if (search.length >= 3) {
      clearTimeout(timerId)
      setTimerId(setTimeout(() => {
        executeSearch({
          variables: {
            companyId: +localStorage.getItem('company_id'),
            filter: search,
          }
        })
      }, 1))
    } else {
      // Если введено 0 символов, очищаем результаты поиска
      executeSearch({
        variables: {
          companyId: +localStorage.getItem('company_id'),
          filter: "", // Пустой фильтр для очистки результатов поиска
        }
      })
    }
  }, [search])

  // Обработчик клика на любую область экрана
  const handleClickOutside = event => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowResults(false);
    }
  };

  // Добавляем обработчик события клика при монтировании компонента
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
      <>
        <div className="w-72 md:w-64 inline-block relative" ref={searchRef}>
          <input className="leading-snug h-8 border-gray-300 block w-full appearance-none bg-gray-100 text-sm text-gray-600 py-1 px-4 pl-8 rounded focus:outline-none"
                 value={search}
                 onChange={handleSearchChange}
                 onFocus={() => setShowResults(true)} // При фокусе на поле поиска показываем результаты
                 placeholder="Глобальный поиск"
          />
          <div className="pointer-events-none absolute pl-3 inset-y-0 left-0 flex items-center px-2 text-gray-300">
            <svg className="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999">
              <path d="M508.874 478.708L360.142 329.976c28.21-34.827 45.191-79.103 45.191-127.309C405.333 90.917 314.416 0 202.666 0S0 90.917 0 202.667s90.917 202.667 202.667 202.667c48.206 0 92.482-16.982 127.309-45.191l148.732 148.732c4.167 4.165 10.919 4.165 15.086 0l15.081-15.082c4.165-4.166 4.165-10.92-.001-15.085zM202.667 362.667c-88.229 0-160-71.771-160-160s71.771-160 160-160 160 71.771 160 160-71.771 160-160 160z" />
            </svg>
          </div>
          {showResults && !loading && data?.Employee_profiles && data.Employee_profiles.length > 0 ? (
              <>
                <div className="origin-bottom-right -right-4 sm:-right-12 absolute top-10 md:right-0 w-160 sm:w-192 rounded-md shadow-2xl bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 overflow-hidden search-results">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    {data.Employee_profiles.map(profile => (
                        <div key={profile.profile_id} onClick={() => profileClickHandler(profile.profile_id, profile.company_id, profile.company_name)} className="block px-4 py-2 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 cursor-pointer" role="menuitem">
                    <span className="flex">
                      {showInputLetters(profile.firstName, profile.lastName, profile.patronymic, profile.company_name)}
                      <span className="ml-auto">
                        {profile.passport.passportNumber}
                      </span>
                    </span>
                        </div>
                    ))}
                  </div>
                </div>
              </>
          ) : null}
        </div>
      </>
  )
}
