import React, { useContext } from 'react'
import { FormContext } from '../context/FormContext'


export default function FormStages() {
    const { data, currentPageIndex } = useContext(FormContext);
    const formStageRef = React.useRef(null);

    const active = "sm:px-6 py-3 px-4 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium bg-gray-100 inline-flex items-center leading-none border-indigo-500 text-indigo-500 tracking-wider rounded-t"
    const unactive = "sm:px-6 py-3 px-4 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 hover:text-gray-900 tracking-wider"

    React.useEffect(() => {
        formStageRef.current.children[currentPageIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }, [currentPageIndex, data]);


    return (
        <div className="formStage mb-10 text-sm" ref={formStageRef}>
            {data.map((stage, index) => (
                <a
                    className={index === currentPageIndex ? active : unactive}
                    key={index}
                >
                    {stage.name}
                </a>
            ))}
        </div>
    )
}
