import React from 'react'
import { connect } from 'react-redux'
import { closeWorkerSettings } from '../../redux/settings/settingsActions'

function WorkerSettingsModal({workerSettingsData, ...props}) {
  return (
    <>
      {props.isWorkerSettings && (
        <div className="fixed bg-gray-900 bg-opacity-50 w-full h-full z-50 flex items-center justify-center">
          <div className="h-5/6 xl:w-2/3 lg:w-10/12 w-11/12 bg-white rounded-lg overflow-y-scroll overflow-x-hidden">
            <div className="w-full flex items-center py-5">
              <p className="font-bold text-md text-black dark:text-white ml-6">
                Настройки {workerSettingsData?.name}
              </p>
              <button onClick={()=>props.closeWorkerSettings()} className="ml-auto mr-6 bg-gray-100 hover:bg-gray-200 rounded-md p-1 focus:outline-none transition ease-in duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="px-2">
            </div>
          </div>
        </div>
      )}
    </>
  )
}
const mapStateToProps = state => ({
  isWorkerSettings: state.settings.isWorkerSettings,
  workerSettingsData: state.settings.workerSettingsData
})
const mapDispatchToProps = {
  closeWorkerSettings
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkerSettingsModal)
