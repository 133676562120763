import { Field, useField, useFormikContext } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../context/FormContext'
import { Error } from './Error'
import { FileInput } from './FileInput'
import { TextInput } from './TextInput'

export default function RvpVngStudyPatentOther({country}) {
  const { changeRvp, changeShowPatent, changeVng, changeStudy, changeShowNone } = useContext(FormContext)
  const { values } = useFormikContext()

  const [variants, setVariants] = useState([])
  React.useEffect(() => {
    const arr = [];
    if (country?.is_study) {
      arr.push({
        id: '124',
        name: 'Учёба',
      });
    }
    if (country?.is_patent) {
      arr.push({
        id: '125',
        name: 'Патент',
      });
    }
    if (country?.is_rvp) {
      arr.push({
        id: '126',
        name: 'РВП',
      });
    }
    if (country?.is_vng) {
      arr.push({
        id: '127',
        name: 'ВНЖ',
      });
    }
    if (country?.is_other) {
      arr.push({
        id: '128',
        name: 'Иное',
      });
    }
    setVariants(arr);
  }, [country])

  const [field, meta] = useField({
    name: 'RvpVngStudyPatent',
    id: 123884,
  })

  useEffect(() => {
    if (values.RvpVngStudyPatent === 'РВП') {
      changeVng(false)
      changeRvp(true)
      changeShowPatent(false)
      changeStudy(false)
      changeShowNone(false)
    } else if (values.RvpVngStudyPatent === 'Патент') {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(true)
      changeStudy(false)
      changeShowNone(false)
    } else if (values.RvpVngStudyPatent === 'ВНЖ') {
      changeRvp(false)
      changeVng(true)
      changeShowPatent(false)
      changeStudy(false)
      changeShowNone(false)
    } else if (values.RvpVngStudyPatent === 'Учёба') {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(false)
      changeStudy(true)
      changeShowNone(false)
    } else {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(false)
      changeStudy(false)
      changeShowNone(true)
    }
  }, [])

  useEffect(() => {
    if (values.RvpVngStudyPatent === 'РВП') {
      changeVng(false)
      changeRvp(true)
      changeShowPatent(false)
      changeStudy(false)
      changeShowNone(false)
      values.studyNumber = ''
      values.studyDate = ''
      values.vngNumber = ''
      values.vngDate = ''
      values.vngDateEnd = ''
      values.vngPlace = ''
      values.patentSeries = ''
      values.patentNumber = ''
      values.patentDate = ''
      values.patentPlace = ''
      values.fileInputStudy = undefined
      values.fileInputPatent = undefined
      values.fileInputVng = undefined
    } else if (values.RvpVngStudyPatent === 'ВНЖ') {
      changeRvp(false)
      changeVng(true)
      changeShowPatent(false)
      changeStudy(false)
      changeShowNone(false)
      values.studyNumber = ''
      values.studyDate = ''
      values.rvpNumber = ''
      values.rvpDate = ''
      values.rvpDateEnd = ''
      values.rvpPlace = ''
      values.patentSeries = ''
      values.patentNumber = ''
      values.patentDate = ''
      values.patentPlace = ''
      values.fileInputStudy = undefined
      values.fileInputPatent = undefined
      values.fileInputRvp = undefined
    } else if (values.RvpVngStudyPatent === 'Патент') {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(true)
      changeStudy(false)
      changeShowNone(false)
      values.studyNumber = ''
      values.studyDate = ''
      values.vngNumber = ''
      values.vngDate = ''
      values.vngDateEnd = ''
      values.vngPlace = ''
      values.rvpNumber = ''
      values.rvpDate = ''
      values.rvpDateEnd = ''
      values.rvpPlace = ''
      values.fileInputStudy = undefined
      values.fileInputRvp = undefined
      values.fileInputVng = undefined
    } else if (values.RvpVngStudyPatent === 'Учёба') {
      changeRvp(false)
      changeVng(false)
      changeShowPatent(false)
      changeStudy(true)
      changeShowNone(false)
      values.vngNumber = ''
      values.vngDate = ''
      values.vngDateEnd = ''
      values.vngPlace = ''
      values.rvpNumber = ''
      values.rvpDate = ''
      values.rvpDateEnd = ''
      values.rvpPlace = ''
      values.patentSeries = ''
      values.patentNumber = ''
      values.patentDate = ''
      values.patentPlace = ''
      values.fileInputPatent = undefined
      values.fileInputRvp = undefined
      values.fileInputVng = undefined
    } else {
      changeShowNone(true)
      changeRvp(false)
      changeVng(false)
      changeShowPatent(false)
      changeStudy(false)
      values.studyNumber = ''
      values.studyDate = ''
      values.vngNumber = ''
      values.vngDate = ''
      values.vngDateEnd = ''
      values.vngPlace = ''
      values.rvpNumber = ''
      values.rvpDate = ''
      values.rvpDateEnd = ''
      values.rvpPlace = ''
      values.patentSeries = ''
      values.patentNumber = ''
      values.patentDate = ''
      values.patentPlace = ''
      values.fileInputStudy = undefined
      values.fileInputPatent = undefined
      values.fileInputRvp = undefined
      values.fileInputVng = undefined
    }
  }, [values])

  const radioChangeHandler = (field, form, variant) => {
    form.setFieldValue(field, variant)
  }

  return (
    <>
      <div className="flex flex-col mb-4">
        <label
          htmlFor="RvpVngStudyPatent"
          className="leading-7 text-md text-gray-900 font-semibold"
        >
          {variants.map(i => i.name).join('/')}
        </label>
        <div
          role="group"
          aria-labelledby="my-radio-group"
          className="flex flex-col justify-center mb-2"
        >
          {variants.map((variant, idx) => (
            <label key={variant.id}>
              <Field name="undefined">
                {({ form }) => (
                  <input
                    id={'RvpVngStudyPatent'}
                    type="radio"
                    name={'RvpVngStudyPatent'}
                    onChange={() =>
                      radioChangeHandler('RvpVngStudyPatent', form, variant.name)
                    }
                    onBlur={field.onBlur}
                    value={idx}
                    className="h-5 w-5 text-indigo-600 outline-none"
                    checked={values['RvpVngStudyPatent'] === variant.name}
                  />
                )}
              </Field>

              <span className="ml-2 text-gray-900">{variant.name}</span>
            </label>
          ))}
          {meta.touched && meta.error && <Error>{meta.error}</Error>}
        </div>
        {values.RvpVngStudyPatent === 'РВП' ? (
          <>
            <TextInput label="Номер РВП *" name="rvpNumber" type="text" />
            <TextInput
              label="Дата выдачи РВП на работу *"
              name="rvpDate"
              type="date"
            />
            <TextInput
              label="Дата окончания РВП на работу *"
              name="rvpDateEnd"
              type="date"
            />
            <TextInput label="Кем выдан *" name="rvpPlace" type="text" />
            <FileInput
              label="Прикрепите скан РВП. Лицевую и обратную сторону. *"
              name="fileInputRvp"
            />
          </>
        ) : values.RvpVngStudyPatent === 'Патент' ? (
          <>
            <TextInput
              label="Серия патента *"
              name="patentSeries"
              type="text"
            />
            <TextInput
              label="Номер патента *"
              name="patentNumber"
              type="text"
            />
            <TextInput
              label="Дата выдачи патента на работу *"
              name="patentDate"
              type="date"
            />
            <TextInput
              label="Дата окончания патента на работу *"
              name="patentDateEnd"
              type="date"
            />
            <TextInput label="Кем выдан *" name="patentPlace" type="text" />
            <TextInput label="ИНН *" name="patentINN" type="text" />
            <FileInput
              label="Прикрепите скан патента. Лицевую и обратную сторону. *"
              name="fileInputPatent"
            />
          </>
        ) : values.RvpVngStudyPatent === 'ВНЖ' ? (
          <>
            <TextInput label="Номер ВНЖ *" name="vngNumber" type="text" />
            <TextInput
              label="Дата выдачи ВНЖ на работу *"
              name="vngDate"
              type="date"
            />
            <TextInput
              label="Дата окончания ВНЖ на работу *"
              name="vngDateEnd"
              type="date"
            />
            <TextInput label="Кем выдан *" name="vngPlace" type="text" />
            <FileInput
              label="Прикрепите скан ВНЖ. Лицевую и обратную сторону. *"
              name="fileInputVng"
            />
          </>
        ) : values.RvpVngStudyPatent === 'Учёба' ? (
          <>
            <TextInput
             label="Номер справки *"
             name="studyNumber"
             type="text" />
            <TextInput
              label="Дата выдачи справки *"
              name="studyDate"
              type="date"
            />
            <FileInput
              label="Прикрепите скан справки *"
              name="fileInputStudy"
            />
          </>
        ) : null}
      </div>
    </>
  )
}
