import React, { useState, useEffect } from "react"
import { Field, useField, useFormikContext } from "formik"
import { Error } from "./Error"

export const AdvRadio = ({ label, ...props }) => {
  const [other, setOther] = useState(false)
  const [field, meta] = useField(props)
  const [fieldComment, metaComment] = useField({
    name: "advertising_comment",
    id: 1002
  })

  
  const { values } = useFormikContext()

  useEffect(() => {
    if (values.vacancySource ===  "Знакомый") {
      setOther(true)
    }
  }, [])

  useEffect(() => {
    console.log(values.vacancySource)
    console.log(values.advertising_comment)
  }, [values])

  const radioChangeHandler = (field, form, variant) => {
    setOther(false)
    if ( variant === "Знакомый") {
      setOther(true)
    }
    form.setFieldValue(field, variant)
  }

  return (
    <>
      <div className="flex flex-col mb-4">
        <label htmlFor={props.name} className="leading-7 text-md text-gray-900 font-bold font-semibold">
          {label}
        </label>
        <div
          role="group"
          aria-labelledby="my-radio-group"
          className="flex flex-col justify-center "
        >
          {props.variants.map((variant, idx) => (
            <label key={variant.id}>
              <Field name="undefined">
                {({ form }) => (
                  <input
                    id={props.name}
                    type="radio"
                    name={props.name}
                    onChange={()=>radioChangeHandler(props.name, form, variant.name)}
                    onBlur={field.onBlur}
                    value={idx}
                    className="h-5 w-5 text-indigo-600 outline-none"
                    checked = {values[props.name] === variant.name}
                  />
                )}
              </Field>
              <span className="ml-2 text-gray-900">{variant.name}</span>
              { idx + 1 === props.variants.length && other &&(
                <textarea
                  className="mt-2 w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  type="text"
                  placeholder="Где вы узнали о вакансии"
                  autoFocus
                  {...fieldComment}
                ></textarea>

              )}
              
            </label>
          ))}
          {meta.touched && meta.error && (
        <Error>{meta.error}</Error>)}
        </div>
      </div>
    </>
  )
}
