export const citizenshipSelect = [
  { 
    id: 'Выберите Гражданство',
    name: 'Выберите Гражданство'
  }, 
  {
    id: "Киргизская Республика",
    name: "Киргизская Республика"
  },
  {
    id:  "Республика Узбекистан",
    name:  "Республика Узбекистан"
  },
  {
    id: "Республика Таджикистан",
    name: "Республика Таджикистан"
  },
  {
    id: "Республика Армения",
    name: "Республика Армения"
  },
  {
    id: "Республика Казахстан",
    name: "Республика Казахстан"
  },
  {
    id: "Украина",
    name: "Украина"
  },
  {
    id: "Республика Беларусь",
    name: "Республика Беларусь"
  },
  {
    id: "Республика Азербайджан",
    name: "Республика Азербайджан"
  },
  {
    id: "Республика Молдова",
    name: "Республика Молдова"
  },
  {
    id: "Туркменистан",
    name: "Туркменистан"
  },
  {
    id: "Российская Федерация",
    name: "Российская Федерация"
  }
]
    

// "Киргизская Республика", "Республика Узбекистан", "Республика Таджикистан", "Республика Армения", "Республика Казахстан", "Украина", "Республика Беларусь", "Республика Азербайджан", "Республика Молдова", "Туркменистан", "Российская Федерация"