import { CHANGE_COMPANY, TOGGLE_LIST, CHANGE_STATUS, SELECT_PROFILES_ID, COMMENT_INPUT_OPEN, COMMENT_INPUT_CLOSE, DOCUMENT_MODAL_TOGGLE, STATUS_DROPDOWN_OPEN, SET_TO_DEFAULT_MANAGER, FILE_MODAL_TOGGLE, TOGGLE_LOADING, RERENDER_TABLE, CHANGE_ARROWS, PATENT_FILE_MODAL_TOGGLE } from '../types'

const initialState = {
  company: {},
  nextStatus: {},
  nextStage: {},
  backStage: {},
  arrows: {},
  currentStatusProfiles: [],
  selectedProfilesIds: [],
  previousStatuses: [],
  subsequentStatuses: [],
  tableTitle: 'Выберите статус',
  currentStatusId: "",
  currentStageId: "",
  commentPlaceholder: "",
  selectedNextStatus: "",
  selectedNextStage: "",
  showList: true,
  viewOnly: false,
  isCommentInputOpen: false,
  isStatusDropdownOpen: false,
  statusLoading: false,
  isReject: false,
  isProfileOpen: false,
  rerenderTable: false,
  isFileModalOpen: false,
  isDocumentModalOpen: false,
  fields: {
    passportDateEnd:"Дата окончания паспорта",
    migrationCardSeries: "Серия",
    migrationCardNumber: "Номер",
    migrationCardDate: "Дата выдачи",
    patentDate: "Дата выдачи патента",
    patentDateEnd: "Дата окончания патента",
    patentSeries: "Серия",
    patentINN: "ИНН",
    patentNumber: "Номер",
    patentPlace: "Кем выдан",
    rvpDate: "Дата выдачи РВП",
    rvpDateEnd: "Дата окончания РВП",
    rvpNumber: "Номер",
    rvpPlace: "Кем выдан",
    vngDate: "Дата выдачи ВНЖ",
    vngDateEnd: "Дата окончания ВНЖ",
    vngNumber: "Номер",
    studyDate: "Дата выдачи справки",
    studyNumber: "Номер",
    vngPlace: "Кем выдан",
    firstName: "Имя",
    lastName: "Фамилия",
    patronymic: "Отчество",
    citizenship: "Гражданство",
    birthplace: "Место рождения",
    birthDate: "Дата рождения",
    passportSeries: "Серия паспорта",
    passportNumber: "Номер паспорта",
    passportDate: "Дата выдачи паспорта",
    passportRecieve: "Кем выдан",
    polisSeries: "Серия полиса",
    polisNumber: "Номер полиса",
    polisDateStart: "Дата выдачи",
    polisRecieve: "Кем выдан",
    registrationAddress: "Адрес регистрации",
    stayDate: "Дата регистрации",
    stayDateEnd: "Дата окончания регистрации",
    job: "Должность",
    jobAddress: "Адрес работы",
    contractInfo: "Контрагент",
    phoneNumber: "Номер телефона",
    name: "ФИО",
    pas: "Пароль",
    date: "Дата создания",
    title: "Файл",
    number: "Номер",
    dogovorExpired: "Договор",
  },
}

export const managerReducer = (state = initialState, action) => {
  switch (action.type) {
      case CHANGE_COMPANY:
        return {...state, company: action.payload}
      case TOGGLE_LIST:
        return {...state, showList: !state.showList}
      case CHANGE_STATUS: 
        return {...state, isStatusDropdownOpen: false, isCommentInputOpen: false, ...action.payload}
      case SELECT_PROFILES_ID: 
        return {...state, selectedProfilesIds: action.payload}
      case COMMENT_INPUT_OPEN:
        return {...state, ...action.payload}
      case COMMENT_INPUT_CLOSE:
        return {...state, isCommentInputOpen: !state.isCommentInputOpen, commentPlaceholder: ""}
      case STATUS_DROPDOWN_OPEN:
        return {...state, isStatusDropdownOpen: !state.isStatusDropdownOpen}
      case TOGGLE_LOADING:
        return {...state, statusLoading: !state.statusLoading}
      case FILE_MODAL_TOGGLE:
          return {...state, isFileModalOpen: action.payload}
      case PATENT_FILE_MODAL_TOGGLE:
          return {...state, isPatentFileModalOpen: action.payload}
      case DOCUMENT_MODAL_TOGGLE:
          return {...state, isDocumentModalOpen: action.payload}
      case RERENDER_TABLE:
        return {...state, rerenderTable: !state.rerenderTable}
      case CHANGE_ARROWS:
        return {...state, ...action.payload}
      case SET_TO_DEFAULT_MANAGER:
        return {...state, ...action.payload}
      default: return state
  }
}