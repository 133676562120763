import InputField from './InputField'
import React from 'react'
import { connect } from 'react-redux'

function UserInfo({data, ...props}) {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Данные профиля
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Подробная информация об исполнителе
        </p>
      </div>
      <div className="border-t border-gray-200 rounded-b-md overflow-hidden">
        <dl>
          {Object.keys(data.Employee_profile).map((field, idx) => {
              if (field === "__typename" || field === "passport"  || field === "comment_employee" || field === "polis" || field === "migrationCard" || field === "patent" || field === "study" || field === "files" || field === "profile_id" || field === "generators" || field === "documents" || field === "status_id" || field === "status_history" || field === "rvp" || field === "vng" || field === "contract" || field === "dismissDate" || field === "dismissComment" || field === "rashod" || field === "patent_files" || field === "other_information" || field === "send_to_1c_time" || field === "is_send_to_1c" || field === "is_mini_form" || field === "dogovorExpired") return null
              if (field === "stayDateEnd" && !data?.Employee_profile[field]) return
              return (
                <div key={idx} className={`${idx % 2 === 0 ? "bg-gray-50" : "bg-white" } px-4 py-5 sm:flex sm:items-center `}>
                  <dt className="text-sm font-medium text-gray-500 sm:flex-1">
                  {props.fields[field]}
                  </dt>
                   {field !== 'contractInfo' ?
                      <InputField field={field} data={data.Employee_profile[field]} manager={false}/>
                      :
                      <p className="mt-1 sm:flex-1 text-sm text-gray-900 bg-transparent sm:mt-0">{data.Employee_profile[field]}&nbsp;</p>
                   }
                </div>
              )
             }
          )}
        </dl>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  fields: state.manager.fields
})

export default connect(mapStateToProps, null)(UserInfo)


