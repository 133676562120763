import axios from "axios";
// import qs from "qs"
// import http from "../http-common";
import date from 'date-and-time'

const citizenshipIds = {
    "Другое": "c95b5e2b-f23a-11e8-80ce-0050569d513d",
    "Китай": "364533fe-4993-11e9-80d1-0050569d513d",
    "Литва": "d4b0803d-8775-11e9-80d5-0050569d513d",
    "Латвия": "22a4cd64-8777-11e9-80d5-0050569d513d",
    "Афганитсан": "991e69d1-49d2-11eb-80e2-0050569d513d",
    "Республика Узбекистан (ВНЖ, РВП)": "f0a1082e-63c2-11eb-80e2-0050569d513d",
    "id": "1434a486-63c3-11eb-80e2-0050569d513d",
    "Азербайджан (ВНЖ, РВП)": "dbf4c1be-63c3-11eb-80e2-0050569d513d",
    "Иран": "7df7fb24-8704-11eb-80e5-005056b40ea0",
    "Судан": "c0f8e950-8d61-11eb-80e5-005056b40ea0",
    "Республика Молдова (ВНЖ, РВП)": "a1ef54b2-920d-11eb-80e5-005056b40ea0",
    "Украина (ВНЖ,РВП)": "2ec4145d-ab93-11eb-80e5-005056b40ea0",
    "Сирия": "bcef74aa-b4be-11eb-80e5-005056b40ea0",
    "Азербайджан": "a7964edd-5e14-11e5-8938-002590e93573",
    "Республика Беларусь": "7d70119a-5e16-11e5-8938-002590e93573",
    "Республика Казахстан": "85529c4a-5e16-11e5-8938-002590e93573",
    "Киргизская Республика": "8ebb87d6-5e16-11e5-8938-002590e93573",
    "Республика Молдова": "9616645f-5e16-11e5-8938-002590e93573",
    "Российская Федерация": "9d7140e8-5e16-11e5-8938-002590e93573",
    "Республика Таджикистан": "9d7140e9-5e16-11e5-8938-002590e93573",
    "Республика Узбекистан": "a9072d4a-5e16-11e5-8938-002590e93573",
    "Украина": "a9072d4b-5e16-11e5-8938-002590e93573",
    "Египет": "4fe2571b-9dea-11e7-8b74-0050569d529b",
    "Тунис": "c28554cc-b236-11e6-8bdf-0050569d529b",
    "Армения": "bab7970f-cf19-11e5-96c8-002590e93573",
    "Туркменистан": "f7cbeb6a-809f-11e6-a615-0050569d529b",
    "Грузия": "077a2817-4cdf-11e6-a979-0050569d529b"
}

const advIds = {
  'HeadHunter': "4da8a7d8-f44b-11e2-ac08-0025906ceca0",
  'Зарплата.ру': "84e4dd5f-cee1-11e3-9c44-002590e3cbe0",
  'SuperJob': "f016218e-6312-11e3-a3ba-0025906ceca0",
  'VK': "8f71d64e-4aba-11e7-bbda-0050569d529b",
  'Авито': "b14cc4ad-4aba-11e7-bbda-0050569d529b",
  'Worki': "e8db93a9-16d6-11e8-80c8-0050569d513d",
  'Работал у нас': "d2b70338-050a-11e8-ad52-002590c488b3",
  'Знакомый': "4da8a7df-f44b-11e2-ac08-0025906ceca0"
}

class backendService {
  
  uploadFiles(file) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("newName", file.newName);

    return axios.post("/upload_file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }

  sendForm(form) {
    return axios.post("/send_form", form, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  async getCompany(profileId) {
    return await axios.get(`/get_profile?profileId=${profileId}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    })
  }

  async getConfigs(name) {
    return await axios.get("/getConfigs", {
      params: {
        name
      },
      headers: {
        "Content-Type": "application/json",
      }
    })
  }

  async getAdvs() {
    return await axios.get("/get_adv", {
      headers: {
        "Content-Type": "application/json",
      }
    })
  }

  async updateOtherInformation(data) {
    return await axios.post("/update_other_information", data, {
      headers: {
        "Content-Type": "application/json",
      }
    })
  }

  async sendTo1C(data) {
    return axios.post("/send_info_1c", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
       .then(response => {
         console.log(response.data)
         if (response.status !== 200) {
           throw new Error(response.data.message);
         }
       })
       .catch(error => {
         console.log(error.response.data)
         console.log(error);
         throw error;
       });
  }

  signIn(login, password) {
    return axios.post("/sign_in", {login, password}, {
      headers: {
        "Content-Type": "application/json",
      }
    })
  }

}
export default new backendService()