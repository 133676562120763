import { OPEN_WORKER_SETTINGS, CLOSE_WORKER_SETTINGS, CLOSE_ADD_COMPANY, OPEN_ADD_COMPANY } from "../types";

const initialState = {
  isWorkerSettings: false,
  workerSettingsData: {},
  isAddCompanyOpen: false
}

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
      case OPEN_WORKER_SETTINGS:
        return {...state, isWorkerSettings: true, ...action.payload}
      case CLOSE_WORKER_SETTINGS:
        return {...state, isWorkerSettings: false, workerSettingsData: {}}
      case OPEN_ADD_COMPANY:
        return {...state, isAddCompanyOpen: true}
      case CLOSE_ADD_COMPANY:
        return {...state, isAddCompanyOpen: false}
      default: return state
  }
}