import { combineReducers } from "redux"
import { alertReducer } from "./alert/alertReducer"
import { authReducer } from "./auth/authReducer"
import { managerReducer } from './manager/managerReducer'
import { reviseReducer } from "./revise/reviseReducer"
import { settingsReducer } from './settings/settingsReducer'

export const rootReducer = combineReducers({
    auth: authReducer,
    manager: managerReducer,
    alert: alertReducer,
    settings: settingsReducer,
    revise: reviseReducer
})
