import React, { useContext } from 'react'
import { FormContext } from "./context/FormContext";

export default function PopupLoader() {
   const { filesData, countSentFiles } = useContext(FormContext)
  return (
    <div className="fixed w-full h-full bg-gray-900 z-50 bg-opacity-60 top-0 left-0 flex items-center justify-center">
      <div className="rounded-xl w-80 p-4 bg-white flex items-center overflow-hidden">
         <div className="flex-1">
            <p className="text-indigo-500 text-lg font-medium mb-2">
               Пожалуйста, подождите
            </p>
            <p className="text-gray-400 text-xs">
               Идет загрузка документов
            </p>
            { filesData && filesData.length > 0 &&
               <>
                  <progress value={countSentFiles} max={filesData.length}></progress>
                  <p>{countSentFiles} / {filesData.length}</p>
               </>
            }

      </div>
       <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-16 w-16 flex-none"></div>
      </div>
    </div>
    
  )
}
