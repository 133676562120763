import date from 'date-and-time'
import { Form, Formik } from "formik"
import React, { useContext, useEffect, useState } from "react"
import * as Yup from "yup"
import backendService from "../../services/backendService"
import { FormContext } from "../context/FormContext"
import InputGenerator from '../InputGenerator'
import PopupFail from "../PopupFail"
import PopupLoader from "../PopupLoader"
import PopupSuccess from "../PopupSuccess"
import SectionNine from "../SectionNine"
import ErrorPage from "./ErrorPage"
import FormStages from "./FormStages"
import PageLoader from "./PageLoader"
import InfoModalForm from "../InfoModalForm";
import { Loader } from "../Loader";



export default function MainForm({ isMiniForm, formId }) {
  const [initialValues, setInitialValues] = useState()
  const [validation, setValidation] = useState()
  const [showLoaderPopup, setShowLoaderPopup] = useState(false)
  const [showSuccessPopup, setSuccessPopup] = useState(false)
  const [showFailPopup, setShowFailPopup] = useState(false)
  const [failType, setFailType] = useState('form')
  const [checkCountry, setCheckCountry] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const { showPatent, showNone, fetchData, data, country, fileTokens, loading, currentPageIndex, changeStage, changeToBackStage, showSectionNine, onlySchool, didNotWork, showRvp, showMigr, showVng, showStudy, addData, filesData, uploadFile, incCountSentFile, clearFileInData } = useContext(FormContext)

    React.useEffect(async () => {
        await backendService.getConfigs(country)
            .then(data => {
                setCheckCountry(data.data);
                checkForm(data.data);
            });

    }, [country]);

  useEffect(() => {
      checkForm();
  }, [isMiniForm]);

  const checkForm = (country) => {
      if (isMiniForm) {
          fetchData(formId, country);
      } else {
          fetchData("d500760b-20c4-4b34-93b2-2c57545bcfb9", country);
      }
  }

  React.useEffect(() => {
      const findMigr = data.findIndex(i => i.name === "Миграционная карта");
      const newData = [...data];
      if (showPatent || showStudy || showNone) {
          if ((findMigr === -1 && checkCountry?.is_migr) || (findMigr === -1 && checkCountry?.name === "Туркменистан" && checkCountry?.is_migr)) {
              const findIndex = newData.findIndex(i => i.name === "Иные документы")
              newData.splice(findIndex + 1, 0, {
                  name: "Миграционная карта",
                  id: "3",
                  validation: `
                    migrationCardSeries: Yup.number()
                            .typeError("Введите число")
                            .positive("Неправильный формат серии")
                            .integer("Неправильный формат серии")
                            .required("Необходимо заполнить"),
                    migrationCardNumber: Yup.number()
                            .typeError("Введите число")
                            .positive("Неправильный формат серии")
                            .integer("Неправильный формат серии")
                            .required("Необходимо заполнить"),
                    migrationCardDate: Yup.string()
                            .required("Необходимо заполнить")
                            .test("migrationCardDate", "Заполните полностью", value => {
                                if (value !== undefined) {
                                    if (value.indexOf("_") !== -1) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            })
                            .test("migrationCardDate", "Дата не может быть больше текущей", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split(".");
                                    const currentDate = new Date();
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate <= currentDate;
                                }
                            }),
                    fileInputMigration: Yup.array()
                            .required("Необходимо добавить файлы")
                            .test("is-correct-file", "Слишком много весит", files => {
                                let valid = true;
                                if (files) {
                                    files.forEach(file => {
                                        const size = file.size / 1024 / 1024;
                                        if (size > 10) {
                                            valid = false;
                                        }
                                    });
                                }
                                return valid;
                            })
                            .test("is-big-file", "Неправильный формат файла", files => {
                                let valid = true;
                                if (files) {
                                    files.forEach(file => {
                                        if (
                                            ![
                                                "application/pdf",
                                                "image/jpeg",
                                                "image/jpg",
                                                "image/png"
                                            ].includes(file.type)
                                        ) {
                                            valid = false;
                                        }
                                    });
                                }
                                return valid;
                            }),
      `,

                  inputs: [
                      {
                          label: "Серия миграционной карты *",
                          initialValue: "",
                          id: "131",
                          config: {
                              name: "migrationCardSeries",
                              type: "text"
                          }
                      },
                      {
                          label: "Номер миграционной карты *",
                          initialValue: "",
                          id: "132",
                          config: {
                              name: "migrationCardNumber",
                              type: "text"
                          }
                      },
                      {
                          label: "Дата выдачи миграционной карты *",
                          initialValue: "",
                          id: "133",
                          config: {
                              name: "migrationCardDate",
                              type: "date"
                          }
                      },
                      {
                          label: "Прикрепите скан миграционной карты. Лицевую и обратную сторону. *",
                          initialValue: "",
                          id: "134",
                          config: {
                              name: "fileInputMigration",
                              type: "file"
                          }
                      },
                  ],
              })
              addData(newData)
          }
      } else {
          if (findMigr !== -1) {
              newData.splice(findMigr, 1);
              addData(newData)
          }
      }

      if (showPatent && checkCountry?.is_polis) {
          const findIndex = checkCountry?.is_migr ? newData.findIndex(i => i.name === "Миграционная карта") : newData.findIndex(i => i.name === "Иные документы");

          newData.splice(findIndex + 1, 0, {
              name: "Полис ДМС",
              id: "4",
              validation: `
                        fileInputPolice: Yup.array()
                                .required("Необходимо добавить файлы")
                                .test("is-correct-file", "Слишком много весит", files => {
                                    let valid = true;
                                    if (files) {
                                        files.forEach(file => {
                                            const size = file.size / 1024 / 1024;
                                            if (size > 10) {
                                                valid = false;
                                            }
                                        });
                                    }
                                    return valid;
                                })
                                .test("is-big-file", "Неправильный формат файла", files => {
                                    let valid = true;
                                    if (files) {
                                        files.forEach(file => {
                                            if (
                                                ![
                                                    "application/pdf",
                                                    "image/jpeg",
                                                    "image/jpg",
                                                    "image/png"
                                                ].includes(file.type)
                                            ) {
                                                valid = false;
                                            }
                                        });
                                    }
                                    return valid;
                                }),
                        polisNumber: Yup.number()
                                .typeError("Введите число")
                                .positive("Неправильный формат серии")
                                .integer("Неправильный формат серии")
                                .required("Необходимо заполнить"),
                        polisRecieve: Yup.string()
                                .required("Необходимо заполнить"),
                        polisDateStart: Yup.string()
                                .required("Необходимо заполнить"),
          `,

              inputs: [
                  {
                      label: "Серия",
                      initialValue: "",
                      id: "135",
                      config: {
                          name: "polisSeries",
                          type: "text"
                      }
                  },
                  {
                      label: "Номер *",
                      initialValue: "",
                      id: "136",
                      config: {
                          name: "polisNumber",
                          type: "text"
                      }
                  },
                  {
                      label: "Кем выдан *",
                      initialValue: "",
                      id: "137",
                      config: {
                          name: "polisRecieve",
                          type: "text"
                      }
                  },
                  {
                      label: "Дата выдачи *",
                      initialValue: "",
                      id: "138",
                      config: {
                          name: "polisDateStart",
                          type: "date"
                      }
                  },
                  {
                      label: "Прикрепите скан полиса ДМС. *",
                      initialValue: "",
                      id: "140",
                      config: {
                          name: "fileInputPolice",
                          type: "file"
                      }
                  },
              ],
          })
          addData(newData)
      } else {
          const findPolis = newData.findIndex(i => i.name === "Полис ДМС");
          if (findPolis !== -1) {
              newData.splice(findPolis, 1);
              addData(newData)
          }
      }

  }, [showVng, showRvp, showPatent, showStudy])

  useEffect(() => {
    console.log(fileTokens)
  }, [fileTokens])

  useEffect(() => {
    if (data.length > 0) {
      data.forEach(page => {
        page?.inputs.forEach(input => { if (input.initialValue) setInitialValues(prev => ({...prev, [input.config.name]: input.initialValue}))})
      })
      setInitialValues(prev => ({...prev,
        fileInputPatent: undefined,
        fileInputRvp: undefined,
        fileInputVng: undefined,
        fileInputStudy: undefined,
        studyDate: "",
        studyNumber: "",
        rvpVng: "ВНЖ",
        RvpVngStudy: "ВНЖ",
        RvpVngStudyPatent: "ВНЖ",
        patentDate: "",
        patentDateEnd: "",
        patentSeries: "",
        patentNumber: "",
        patentPlace: "",
        patentINN: "",
        criminal_record_extinguished: "",
        advertising_comment: "",
        relationDegreeRelatives: "",
        nameRelatives: "",
        cityRelatives: "",
        phoneRelatives: "",
        jobPlaceRelatives: "",
        jobRelatives: "",
        relationDegreeRelatives2: "",
        nameRelatives2: "",
        cityRelatives2: "",
        phoneRelatives2: "",
        jobPlaceRelatives2: "",
        jobRelatives2: "",
      }))
      // let str = data.map(val => val.validation).join("")
      // setValidation(yup(Yup, `Yup.object({${str}})`))
    }
  }, [data])

    useEffect(() => {
        if (data && data[currentPageIndex]) {
            if ('validation' in data[currentPageIndex]) {
                if (data[currentPageIndex]?.name !== "Иные документы") {
                    let str = data[currentPageIndex].validation
                    setValidation(yup(Yup, `Yup.object({${str}})`))
                }
            }
        }
    }, [currentPageIndex, data])

    useEffect(() => {
        if (validation) {
            if (showSectionNine) {
                setValidation(yup(Yup, `Yup.object({})`))
                if (showRvp) {
                    setValidation(prev => prev.shape({
                        rvpNumber: Yup.number()
                            .typeError('Введите число')
                            .positive("Неправильный формат серии")
                            .integer("Неправильный формат серии")
                            .required("Необходимо заполнить"),
                        rvpDate: Yup.string()
                            .required("Необходимо заполнить")
                            .test("rvpDate", "Заполните полностью", value => {
                                if (value !== undefined) {
                                    if (value.indexOf('_') !== -1) {
                                        return false
                                    } else {
                                        return true
                                    }
                                }
                            })
                            .test("rvpDate", "Дата не может быть больше текущей", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split('.');
                                    const currentDate = new Date();
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate <= currentDate;
                                }
                            })
                            .test("rvpDate", "Срок действия РВП истёк", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split('.');
                                    const currentDate = new Date();
                                    const threeYearsAgo = new Date(currentDate.getFullYear() - 3, currentDate.getMonth(), currentDate.getDate());
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate >= threeYearsAgo;
                                }
                            }),
                        rvpDateEnd: Yup.string()
                            .required("Необходимо заполнить")
                            .test("rvpDateEnd", "Заполните полностью", value => {
                                if (value !== undefined) {
                                    if (value.indexOf('_') !== -1) {
                                        return false
                                    } else {
                                        return true
                                    }
                                }
                            })
                            .test("rvpDateEnd", "Дата не может быть меньше текущей", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split('.');
                                    const currentDate = new Date();
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate >= currentDate;
                                }
                            }),
                        rvpPlace: Yup.string()
                            .required("Необходимо заполнить"),
                        fileInputRvp: Yup.array()
                            .required('Необходимо добавить файлы')
                            .test('is-correct-file', 'Слишком много весит', (files) => {
                                let valid = true
                                if (files) {
                                    files.forEach(file => {
                                        const size = file.size / 1024 / 1024
                                        if (size > 10) {
                                            valid = false
                                        }
                                    })
                                }
                                return valid
                            })
                            .test(
                                'is-big-file',
                                'Неправильный формат файла',
                                (files) => {
                                    let valid = true
                                    if (files) {
                                        files.forEach(file => {
                                            if (!['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
                                                valid = false
                                            }
                                        })
                                    }
                                    return valid
                                }
                            ),
                        fileInputVng: undefined,
                        fileInputStudy: undefined,
                        studyDate: undefined,
                        studyNumber: undefined,
                        vngNumber: undefined,
                        vngDate: undefined,
                        vngDateEnd: undefined,
                        vngPlace: undefined,
                        patentSeries: undefined,
                        patentNumber: undefined,
                        patentDate: undefined,
                        patentDateEnd: undefined,
                        fileInputPatent: undefined,
                        patentPlace: undefined,
                        patentINN: undefined,
                    }))
                } else if (showVng) {
                    setValidation(prev => prev.shape({
                        vngNumber: Yup.number()
                            .typeError('Введите число')
                            .positive("Неправильный формат серии")
                            .integer("Неправильный формат серии")
                            .required("Необходимо заполнить"),
                        vngDate: Yup.string()
                            .required("Необходимо заполнить")
                            .test("vngDate", "Заполните полностью", value => {
                                if (value !== undefined) {
                                    if (value.indexOf('_') !== -1) {
                                        return false
                                    } else {
                                        return true
                                    }
                                }
                            })
                            .test("vngDate", "Дата не может быть больше текущей", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split('.');
                                    const currentDate = new Date();
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate <= currentDate;
                                }
                            })
                            .test("vngDate", "Срок действия ВНЖ истёк", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split('.');
                                    const currentDate = new Date();
                                    const fiveYearsAgo = new Date(currentDate.getFullYear() - 5, currentDate.getMonth(), currentDate.getDate());
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate >= fiveYearsAgo;
                                }
                            }),
                        vngDateEnd: Yup.string()
                           .test("vngDateEnd", "Заполните полностью", value => {
                              if (value !== undefined) {
                                 if (value.indexOf('_') !== -1) {
                                    return false
                                 } else {
                                    return true
                                 }
                              } else {
                                 return true;
                              }
                           })
                           .test("vngDateEnd", "Дата не может быть меньше текущей", value => {
                              if (value !== undefined && value !== '') {
                                 const [day, month, year] = value.split('.');
                                 const currentDate = new Date();
                                 const selectedDate = new Date(year, month - 1, day);
                                 return selectedDate >= currentDate;
                              } else {
                                 return true;
                              }
                           }),
                        vngPlace: Yup.string()
                            .required("Необходимо заполнить"),
                        fileInputVng: Yup.array()
                            .required('Необходимо добавить файлы')
                            .test('is-correct-file', 'Слишком много весит', (files) => {
                                let valid = true
                                if (files) {
                                    files.forEach(file => {
                                        const size = file.size / 1024 / 1024
                                        if (size > 10) {
                                            valid = false
                                        }
                                    })
                                }
                                return valid
                            })
                            .test(
                                'is-big-file',
                                'Неправильный формат файла',
                                (files) => {
                                    let valid = true
                                    if (files) {
                                        files.forEach(file => {
                                            if (!['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
                                                valid = false
                                            }
                                        })
                                    }
                                    return valid
                                }
                            ),
                        fileInputRvp: undefined,
                        fileInputStudy: undefined,
                        studyDate: undefined,
                        studyNumber: undefined,
                        rvpNumber: undefined,
                        rvpDate: undefined,
                        rvpDateEnd: undefined,
                        rvpPlace: undefined,
                        patentSeries: undefined,
                        patentNumber: undefined,
                        patentDate: undefined,
                        patentDateEnd: undefined,
                        fileInputPatent: undefined,
                        patentPlace: undefined,
                        patentINN: undefined,
                    }))
                } else if (showPatent) {
                    setValidation(prev => prev.shape({
                        patentSeries: Yup.number()
                            .typeError('Введите число')
                            .positive("Неправильный формат серии")
                            .integer("Неправильный формат серии")
                            .required("Необходимо заполнить"),
                        patentNumber: Yup.number()
                            .typeError('Введите число')
                            .positive("Неправильный формат серии")
                            .integer("Неправильный формат серии")
                            .required("Необходимо заполнить"),
                        patentDate: Yup.string()
                            .required("Необходимо заполнить")
                            .test("patentDate", "Заполните полностью", value => {
                                if (value !== undefined) {
                                    if (value.indexOf('_') !== -1) {
                                        return false
                                    } else {
                                        return true
                                    }
                                }
                            })
                            .test("patentDate", "Дата не может быть больше текущей", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split('.');
                                    const currentDate = new Date();
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate <= currentDate;
                                }
                            })
                            .test("patentDate", "Срок действия патента истёк", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split('.');
                                    const currentDate = new Date();
                                    const oneYearsAgo = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate >= oneYearsAgo;
                                }
                            }),
                        patentDateEnd: Yup.string()
                            .required("Необходимо заполнить")
                            .test("patentDateEnd", "Заполните полностью", value => {
                                if (value !== undefined) {
                                    if (value.indexOf('_') !== -1) {
                                        return false
                                    } else {
                                        return true
                                    }
                                }
                            })
                            .test("patentDateEnd", "Дата не может быть меньше текущей", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split('.');
                                    const currentDate = new Date();
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate >= currentDate;
                                }
                            }),
                        fileInputPatent: Yup.array()
                            .required('Необходимо добавить файлы')
                            .test('is-correct-file', 'Слишком много весит', (files) => {
                                let valid = true
                                if (files) {
                                    files.forEach(file => {
                                        const size = file.size / 1024 / 1024
                                        if (size > 10) {
                                            valid = false
                                        }
                                    })
                                }
                                return valid
                            })
                            .test(
                                'is-big-file',
                                'Неправильный формат файла',
                                (files) => {
                                    let valid = true
                                    if (files) {
                                        files.forEach(file => {
                                            if (!['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
                                                valid = false
                                            }
                                        })
                                    }
                                    return valid
                                }
                            ),
                        patentPlace: Yup.string()
                            .required("Необходимо заполнить"),
                        patentINN: Yup.string()
                            .required("Необходимо заполнить"),
                        vngNumber: undefined,
                        fileInputStudy: undefined,
                        studyDate: undefined,
                        studyNumber: undefined,
                        vngDate: undefined,
                        vngDateEnd: undefined,
                        vngPlace: undefined,
                        rvpNumber: undefined,
                        rvpDate: undefined,
                        rvpDateEnd: undefined,
                        rvpPlace: undefined,
                        fileInputRvp: undefined,
                        fileInputVng: undefined,
                    }))
                } else if (showStudy) {
                    setValidation(prev => prev.shape({
                        studyNumber: Yup.number()
                            .typeError('Введите число')
                            .positive("Неправильный формат серии")
                            .integer("Неправильный формат серии")
                            .required("Необходимо заполнить"),
                        studyDate: Yup.string()
                            .required("Необходимо заполнить")
                            .test("studyDate", "Заполните полностью", value => {
                                if (value !== undefined) {
                                    if (value.indexOf('_') !== -1) {
                                        return false
                                    } else {
                                        return true
                                    }
                                }
                            })
                            .test("studyDate", "Дата не может быть больше текущей", value => {
                                if (value !== undefined) {
                                    const [day, month, year] = value.split('.');
                                    const currentDate = new Date();
                                    const selectedDate = new Date(year, month - 1, day);
                                    return selectedDate <= currentDate;
                                }
                            }),
                        fileInputStudy: Yup.array()
                            .required('Необходимо добавить файлы')
                            .test('is-correct-file', 'Слишком много весит', (files) => {
                                let valid = true
                                if (files) {
                                    files.forEach(file => {
                                        const size = file.size / 1024 / 1024
                                        if (size > 10) {
                                            valid = false
                                        }
                                    })
                                }
                                return valid
                            })
                            .test(
                                'is-big-file',
                                'Неправильный формат файла',
                                (files) => {
                                    let valid = true
                                    if (files) {
                                        files.forEach(file => {
                                            if (!['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
                                                valid = false
                                            }
                                        })
                                    }
                                    return valid
                                }
                            ),
                        patentSeries: undefined,
                        patentNumber: undefined,
                        patentDate: undefined,
                        patentDateEnd: undefined,
                        fileInputPatent: undefined,
                        patentPlace: undefined,
                        patentINN: undefined,
                        vngNumber: undefined,
                        vngDate: undefined,
                        vngDateEnd: undefined,
                        vngPlace: undefined,
                        rvpNumber: undefined,
                        rvpDate: undefined,
                        rvpDateEnd: undefined,
                        rvpPlace: undefined,
                        fileInputRvp: undefined,
                        fileInputVng: undefined,
                    }))
                } else if (showNone) {
                    setValidation(yup(Yup, `Yup.object({})`))
                } else {
                    setValidation(prev => prev.shape({
                        vngNumber: undefined,
                        vngDate: undefined,
                        vngDateEnd: undefined,
                        vngPlace: undefined,
                        rvpNumber: undefined,
                        rvpDate: undefined,
                        rvpDateEnd: undefined,
                        rvpPlace: undefined,
                        fileInputRvp: undefined,
                        fileInputVng: undefined,
                        patentSeries: undefined,
                        patentNumber: undefined,
                        patentDate: undefined,
                        fileInputPatent: undefined,
                        patentPlace: undefined,
                        patentINN: undefined,
                        fileInputStudy: undefined,
                        studyDate: undefined,
                        studyNumber: undefined,
                    }))
                }
            }
            else {
                setValidation(prev => prev.shape({
                    patentDate: undefined,
                    patentDateEnd: undefined,
                    patentSeries: undefined,
                    patentNumber: undefined,
                    fileInputPatent: undefined,
                    fileInputRvp: undefined,
                    fileInputVng: undefined,
                    patentPlace: undefined,
                    patentINN: undefined,
                    rvpVng: undefined,
                    vngNumber: undefined,
                    vngDate: undefined,
                    vngDateEnd: undefined,
                    vngPlace: undefined,
                    rvpNumber: undefined,
                    rvpDate: undefined,
                    rvpDateEnd: undefined,
                    rvpPlace: undefined,
                    fileInputStudy: undefined,
                    studyDate: undefined,
                    studyNumber: undefined,
                }))
            }
        }
    }, [showPatent, showSectionNine, showRvp, showVng, showStudy, showNone])

useEffect(() => {
  if (validation && data[currentPageIndex]?.name === "Деятельность") {
    if (didNotWork) {
      setValidation(prev => prev.shape({
        workHiringDate1: undefined,
        workQuitDate1: undefined,
        workPlace1: undefined,
        workJob1: undefined,
        workFunctions1: undefined,
        workQuitReason1: undefined,
      }))  
    } else {
      setValidation(prev => prev.shape({
        workHiringDate1: Yup.string()
          .required("Необходимо заполнить"),
        workQuitDate1: Yup.string()
          .required("Необходимо заполнить"),
        workPlace1: Yup.string()
          .required("Необходимо заполнить"),
        workJob1: Yup.string()
          .required("Необходимо заполнить"),
        workFunctions1: Yup.string()
          .required("Необходимо заполнить"),
        workQuitReason1: Yup.string()
          .required("Необходимо заполнить"),
      }))  
    }
  }
}, [didNotWork, currentPageIndex])

  useEffect(() => {
    if (validation && data[currentPageIndex]?.name === "Квалификация") {
        if (onlySchool) {
            setValidation(prev => prev.shape({
                educationLevel: undefined,
                entryYear1: undefined,
                exitYear1: undefined,
                schoolName1: undefined,
                specialty1: undefined,}))
        } else {
            setValidation(prev => prev.shape({
                educationLevel: Yup.number()
                    .oneOf(
                        [2, 3],
                        'Выберите один из вариантов выше'
                    )
                    .required('Необходимо заполнить'),
                entryYear1: Yup.string()
                    .required("Необходимо заполнить"),
                exitYear1: Yup.string()
                    .required("Необходимо заполнить"),
                schoolName1: Yup.string()
                    .required("Необходимо заполнить"),
                specialty1: Yup.string()
                    .required("Необходимо заполнить"),}))
        }
    }
  }, [onlySchool, currentPageIndex])

  const backStageHandler = () => {
    changeToBackStage()
  }
  const nextStageHandler = (validateForm) => {
    validateForm().then((e)=>{
      console.log(e)
      if (Object.keys(e).length > 0) { 
        changeFailType("validation")
        toggleFailPopup()
        setTimeout(toggleFailPopup, 1000)
        setTimeout(()=>changeFailType("form"), 1000)
        return
      }
      changeStage()
      
    })
  }
  const toggleLoaderPopup = () => {
    setShowLoaderPopup(prev => !prev)
  }
  const toggleSuccessPopup = () => {
    setSuccessPopup(prev => !prev)
  }
  const toggleFailPopup = () => {
    setShowFailPopup(prev => !prev)
  }
  const changeFailType = (type) => {
    setFailType(type)
  }

  const yup = new Function('Yup', 'data', 'return eval(data)');

  const widthForm = data.length > 8 ? "form" : "miniForm";
  return (
    <>
    { loading
      ? <PageLoader />
      : initialValues === undefined
      ? <ErrorPage />
      : (<section className="flex h-full justify-center">
        {showLoaderPopup && (<PopupLoader />)}
        {showSuccessPopup && (<PopupSuccess/>)}
        {showFailPopup && (<PopupFail type={failType}/>)}
          <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={ async (values, { setSubmitting }) => {
              console.log(values)
               setDisableButton(true)
               toggleLoaderPopup();
              if (currentPageIndex + 2 > data.length) {
                try {
                  values.registrationAddress = `${values.registration_city}, ${values.registration_street}, ${values.registration_house}, ${values.registration_housing?.length > 0 ? `${values.registration_housing}, ` : ""}${values.registration_room}`
                  values.form_id = isMiniForm ? formId : "d500760b-20c4-4b34-93b2-2c57545bcfb9"
                  // values.passportDate = date.transform(values.passportDate, 'YYYY-MM-DD', 'DD.MM.YYYY');
                  // if (values.passportDateEnd) values.passportDateEnd = date.transform(values.passportDateEnd, 'YYYY-MM-DD', 'DD.MM.YYYY');
                  // if (values.migrationCardDate) values.migrationCardDate = date.transform(values.migrationCardDate, 'YYYY-MM-DD', 'DD.MM.YYYY');
                  // if (values.patentDate) values.patentDate = date.transform(values.patentDate, 'YYYY-MM-DD', 'DD.MM.YYYY');
                  if (values.patronymic === undefined) {
                    values.patronymic = " "
                  }
                  if (values.birthDate) values.birthDate = date.transform(values.birthDate, 'DD.MM.YYYY', 'YYYY-MM-DD')
                  if (values.stayDate) values.stayDate = date.transform(values.stayDate, 'DD.MM.YYYY', 'YYYY-MM-DD')
                  if (values.stayDateEnd) values.stayDateEnd = date.transform(values.stayDateEnd, 'DD.MM.YYYY', 'YYYY-MM-DD')

                   // Передаем какая форма была заполнена
                   values.isMiniForm = isMiniForm;

                  const arrTokens = []
                  for (const file of filesData) {
                     await backendService.uploadFiles(file)
                        .then(res => {
                           arrTokens.push(res.data);
                           incCountSentFile();
                        });
                  }
                   clearFileInData()
                  values.files = arrTokens;

                  const form = JSON.stringify(values)

                  if (failType === "form") {
                    backendService.sendForm(form)
                    .then(response => {
                      if (response.status === 200) {
                        toggleSuccessPopup()
                      } else {
                        toggleFailPopup()
                        if (values.birthDate) values.birthDate = date.transform(values.birthDate, 'YYYY-MM-DD', 'DD.MM.YYYY')
                        if (values.stayDate) values.stayDate = date.transform(values.stayDate, 'YYYY-MM-DD', 'DD.MM.YYYY')
                        if (values.stayDateEnd) values.stayDateEnd = date.transform(values.stayDateEnd, 'YYYY-MM-DD', 'DD.MM.YYYY')
                      }
                      if (response.status === 200) changeStage()
                      response.status === 200 ? setTimeout(toggleSuccessPopup, 2000) : setTimeout(toggleFailPopup, 2000)
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                       setDisableButton(false);
                    })
                  }
                  toggleLoaderPopup()
                  setSubmitting(false)
                } catch(e) {
                  console.log(e)
                  changeFailType("file")
                  // toggleLoaderPopup()
                  toggleFailPopup()
                  setTimeout(toggleFailPopup, 2000)
                }
              }
            }}
          >
            {({ validateForm }) => (
            <Form className={`${widthForm} my-8 bg-white rounded-lg p-4 sm:p-8 flex flex-col w-11/12 z-10 border shadow-2xl relative`}>
              {console.log(validation)}
              {currentPageIndex + 1 > data.length ?
              <h1>Форма отправлена</h1>
              : (
                <>
                  <FormStages />

                    {currentPageIndex === 0 && <InfoModalForm countryData={checkCountry}/>}
                  {data[currentPageIndex]?.inputs.map(input => InputGenerator(input.config, input.id, input.label))}
                  {((currentPageIndex === data.length - 1) || (currentPageIndex === data.length - 1 && isMiniForm)) && (
                    <>
                      <p>Нажатием кнопки «АНКЕТА ЗАПОЛНЕНА», я подтверждаю, что информация, изложенная в моей анкете правдива, правильна и содержит все известные мне сведения. Я понимаю, что если я допустил(а) какие-либо искажения, или отразил(а) неполную информацию в данной анкете, это может послужить поводом к утрате ко мне доверия и прекращения трудовых отношений, если таковые будут установлены.Нажатием кнопки «Анкета заполнена», я даю согласие на сбор и обработку своих персональных данных, предоставленных мною в настоящей анкете, в соответствии с Федеральным Законом РФ "О персональных данных" (152-ФЗ) 2017. Всю информацию в связи со сбором и обработкой моих персональных данных я получил.</p>
                      <hr className="my-2"/>
                      <p>После того, как вы заполнили и проверили данные в анкете, нажмите кнопку «АНКЕТА ЗАПОЛНЕНА»</p>
                    </>
                  )}
                  {data[currentPageIndex]?.name === "Иные документы" && <SectionNine countryData={checkCountry}/>}
                  {(currentPageIndex < data.length - 1 && currentPageIndex !== 0) && <p>* - обязательно для заполнения</p>}
                  {(currentPageIndex > 0 && currentPageIndex < data.length - 1) &&
                    (<div className="flex w-full">
                        <button type="button" onClick={backStageHandler} className="flex-1 mt-4 py-2 px-4 shadow-md rounded-md bg-indigo-600 hover:bg-indigo-800 text-white focus:outline-none">Назад</button>
                        <button type="button" onClick={()=>nextStageHandler(validateForm)} className="flex-1 ml-4 mt-4 py-2 px-4 shadow-md rounded-md bg-indigo-600 hover:bg-indigo-800 text-white focus:outline-none">Далее</button>
                      </div>)
                  }
                  { (currentPageIndex === data.length - 1 || (currentPageIndex === data.length - 1 && isMiniForm)) &&
                    (<div className="flex w-full">
                        <button type="button" disabled={disableButton} onClick={backStageHandler} className={`flex-1 mt-4 py-2 px-4 shadow-md rounded-md ${disableButton ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-800'} text-white focus:outline-none`}>Назад</button>
                       <button type="submit" disabled={disableButton}
                               className={`flex justify-center items-center flex-1 ml-4 mt-4 py-2 px-4 shadow-md rounded-md ${disableButton ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-800'} text-white focus:outline-none`}>АНКЕТА
                          ЗАПОЛНЕНА <span className='ml-2'>{disableButton && <Loader mini={true}/>}</span></button>
                    </div>)
                  }
                   { currentPageIndex === 0 && (
                    <button type="button" onClick={()=>nextStageHandler(validateForm)} className="mt-4 py-2 px-4 shadow-md rounded-md bg-indigo-600 hover:bg-indigo-800 text-white focus:outline-none">Далее</button>
                  )}
                </>
              )
            }
            </Form>
            )}
          </Formik>
        </section>) 
      }
    </>
  )
}
