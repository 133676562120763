import React from 'react'
import { connect } from 'react-redux'
import InputField from './InputField'

function InfoCard({
  title, 
  subtitle, 
  data, 
  contract=false, 
  ...props
}) {
  return (
    <>
      {/* проверка на договор */}
      {contract ? (
        <>
          <div className={`px-4 py-5 sm:px-6 ${contract ? "flex justify-between items-center" : ""}`}>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
            {contract && (
              <a href={`/file?file_token=${data.file}`} className="ml-auto mr-2 md:mr-0 mt-2 md:mt-0 py-1 px-1 flex justify-center items-center hover:bg-gray-100 focus:ring-gray-500 focus:ring-offset-gray-200  text-gray-800 transition ease-in duration-200 text-center text-base font-semibold hover:shadow focus:outline-none focus:ring-2 focus:ring-offset-2 rounded">
                <div className="w-5 h-5">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </a>
            )}
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {subtitle}
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              {data && Object.keys(data).map((field, idx) => {
                  // обработка данных с graphql
                  // отображение только нужных полей
                  if (field === "__typename" || field === "file" || field === "title") return null
                  return (
                    <div key={idx} className={`${idx === 3 ? "bg-gray-50" : "bg-white" } ${idx + 2 === Object.keys(data).length && "rounded-b-md"} px-4 py-5 sm:flex sm:items-center `}>
                      <dt className="text-sm font-medium text-gray-500 sm:flex-1">
                        {props.fields[field]}
                      </dt>
                      <p className="mt-1 sm:flex-1 text-sm text-gray-900 bg-transparent sm:mt-0">{data[field]}</p>
                    </div>
                  )
                }
              )}
            </dl>
          </div>
        </>
      ) : (
        <>
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {subtitle}
            </p>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              {data && Object.keys(data).map((field, idx) => {
                  if (field === "__typename") return null
                  return (
                    <div key={idx} className={`${idx % 2 === 0 ? "bg-gray-50" : "bg-white" } ${idx + 1 === Object.keys(data).length && "rounded-b-md"} px-4 py-5 sm:flex sm:items-center `}>
                      <dt className="text-sm font-medium text-gray-500 sm:flex-1">
                        {props.fields[field]}
                      </dt>
                      <InputField field={field} data={data[field]} manager={false} />
                    </div>
                  )
                }
              )}
            </dl>
          </div>
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  fields: state.manager.fields
})

export default connect(mapStateToProps, null)(InfoCard)
