import React from 'react' 
import { FormState } from '../components/context/FormState'
import MainForm from '../components/Form/MainForm'
import { useLocation } from "react-router-dom";
import axios from "axios";

function Form() {
    const [isMiniForm, setIsMiniForm] = React.useState();
    const [formId, setFormId] = React.useState(null);
    const { pathname, search } = useLocation();
    React.useEffect(() => {
        const query = new URLSearchParams(search).get('id');
        if (!query) return;
        setFormId(query);

        axios.get(`/check_form`, {
            params: {
                id: query
            }
        })
            .then(res => {
                setIsMiniForm(res.data);
            })

    }, [])

  return (
      <FormState>
          {isMiniForm && <MainForm isMiniForm={isMiniForm} formId={formId}/>}
          {!search && <MainForm isMiniForm={false} formId={null}/>}
      </FormState>
  );
}

export default Form
