import React, { useContext, useEffect } from 'react';
import { useFormikContext } from "formik";
import { FormContext } from "./context/FormContext";
import backendService from "../services/backendService";

const InfoModalForm = () => {
   const [mainText, setMainText] = React.useState([]);
   const [secondaryText, setSecondaryText] = React.useState([]);

   const { values } = useFormikContext()
   const {
      changeSectionNine,
      changeRvp,
      changeVng,
      changeStudy,
      changeShowPatent,
      changeMigr,
      setCheckCountry
   } = useContext(FormContext)

   const [country, setCountry] = React.useState(null);

   useEffect(async () => {
      if (values.citizenship) {
         await backendService.getConfigs(values.citizenship)
            .then(data => {
               if (!data.data) {
                  changeSectionNine(true);
                  setCountry(null);
                  setCheckCountry('')
                  return
               }
               setCountry(data.data)
               setCheckCountry(data.data.name)
               values.RvpVngStudyPatent = 'ВНЖ';

               if (data.data.name) {
                  changeSectionNine(true);
               } else {
                  changeSectionNine(false);
               }
            });
         // if (citezenshipDocs[values.citizenship]?.sectionNine) {
         //   changeSectionNine(true)
         // } else {
         //   changeSectionNine(false)
         // }

         if (["Российская Федерация"].includes(values.citizenship)) {
            changeRvp(false)
            changeVng(false)
            changeShowPatent(false)
            changeStudy(false)
            values.RvpVngStudyPatent = "Патент"
            values.vngNumber = ""
            values.vngDate = ""
            values.vngDateEnd = ""
            values.vngPlace = ""
            values.rvpNumber = ""
            values.rvpDate = ""
            values.rvpDateEnd = ""
            values.rvpPlace = ""
            values.patentSeries = ""
            values.patentNumber = ""
            values.patentDate = ""
            values.patentPlace = ""
            values.studyNumber = ''
            values.studyDate = ''
            values.fileInputStudy = undefined
            values.fileInputPatent = undefined
            values.fileInputRvp = undefined
            values.fileInputVng = undefined
         }
      }
   }, [values.citizenship])


   React.useEffect(() => {
      setMainText(['паспорт', 'регистрация']);
      setSecondaryText([]);

      if (country?.is_rvp) {
         setSecondaryText(prev => [...prev, "разрешение на временное проживание (РВП)"]);
      }
      if (country?.is_study) {
         setSecondaryText(prev => [...prev, `учебная справка ${country?.is_migr && country?.name !== "Туркменистан" ? "+ миграционная карта" : ""}`]);
      }
      if (country?.is_vng) {
         setSecondaryText(prev => [...prev, "вид на жительство (ВНЖ)"]);
      }
      if (country?.is_patent) {
         setSecondaryText(prev => [...prev, `патент ${country?.is_migr && country?.name !== "Туркменистан" ? "+ миграционная карта" : ""} ${country?.is_polis ? "+ полис ДМС" : ""}`]);
      }
      if (country?.is_other) {
         setSecondaryText(prev => [...prev, `иное ${country?.is_migr && country?.name !== "Туркменистан" ? "+ миграционная карта" : ""}`]);
      }
      if (country?.name === "Туркменистан" && country?.is_migr) {
         setSecondaryText(prev => [...prev, `миграционная карта`]);
      }
   }, [country])

   return (
      <>
         <div className="border-dashed border-2 border-red-600 p-4 gap-52 infoModal mb-3">
            <p className='text-red-600 text-center'>ПЕРЕД ЗАПОЛНЕНИЕМ АНКЕТЫ НЕОБХОДИМО ОТКЛЮЧИТЬ VPN</p>
         </div>
         <div className="border p-4 flex gap-52 infoModal">
            {country ? (
               <>
                  <div>
                     <p className='font-medium'>Для заполнения анкеты вам необходимо:</p>
                     <ul className='ml-3'>
                        {mainText.map(i => (
                           <li key={i}>- {i}</li>
                        ))}
                     </ul>
                  </div>
                  {secondaryText.length ? (
                     <div>
                        <p className='font-medium'>Также на выбор, нужно иметь:</p>
                        <ul className='ml-3'>
                           {secondaryText.map(i => (
                              <li key={i}>- {i}</li>
                           ))}
                        </ul>
                     </div>
                  ) : ''}
               </>
            ) : (
               <p>Укажите своё гражданство</p>
            )}
         </div>
      </>
   );
};

export default InfoModalForm;