import { useQuery } from '@apollo/client'
import { Listbox, Portal, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import React, { useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { GET_CITIZENSHIP } from '../../graphql/Queries'


export default function CitizenshipInput({value, setValue}) {

  // библиотека popper js для отображения дропдаунов
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  })

  // получение доступных точек
  let { error, data } = useQuery(GET_CITIZENSHIP, {
    variables: { 
      companyId: +localStorage.getItem('company_id'),
     },
  })
  if (error) console.log(error)

  // достаю точки из запроса
  const citizrnships = useMemo(
      () => data ? data?.Citizenship : [],
    [data]
  ) 
  return (
    // компонент из библиотеки headless ui
    <div className="w-full" ref={setReferenceElement}>
      <Listbox value={value} onChange={setValue}>
        <div className="relative">
          <Listbox.Button className="w-full cursor-pointer relative py-2 pl-3 pr-10 h-9 text-left bg-white rounded-lg shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate">{value}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Portal>
            <Transition
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options 
                className="absolute w-52 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {citizrnships.map((citizenship, ttIdx) => (
                  <Listbox.Option
                    key={ttIdx}
                    className={({ active }) =>
                      `${active ? 'text-gray-900 bg-gray-100' : 'text-gray-900'}
                            cursor-pointer select-none relative py-2 pl-10 pr-4`
                    }
                    value={citizenship.name}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? 'font-medium' : 'font-normal'
                          } block truncate`}
                        >
                          {citizenship.name}
                        </span>
                        {selected ? (
                          <span
                            className={`${
                              active ? 'text-gray-600' : 'text-gray-600'
                            }
                                  absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </Portal>
        </div>
      </Listbox>
    </div>
  )
}
