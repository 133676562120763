import { SHOW_ALERT, HIDE_ALERT } from "../types";

const initialState = {
  bottomAlertVisible: false,
  smallAlertVisible: false,
  message: "",
  type: "neutral"
}

export const alertReducer = (state = initialState, action) => {
  switch (action.type) {
      case SHOW_ALERT:
        return {...state, ...action.payload}
      case HIDE_ALERT:
        return {...state, ...action.payload}
      default: return state
  }
}