import {gql} from "@apollo/client"
import {client} from '../App'

export const sendQuery = (query, variables, options) => {
    if (variables) {
        return client.query({
            query,
            variables: variables,
            errorPolicy: "ignore",
            ...options
        })
    }
    return client.query({query})
}

export const CHANGE_COMPANY = gql`
query ChangeCompany($profileId: [ID]!, $date: String!){
    ChangeCompany (
      profile_id: $profileId,
      date: $date,
    )
    {
      status
    }
  }
`

export const GET_COMPANIES = gql`
  query {
    Companies {
      company_id
      name
      logo
    }
  }
`
export const GET_COMPANY = gql`
  query {
    Companies {
      company_id
      name
    }
  }
`
export const UPDATE_COMMENT = gql`
  mutation getComment($profile_id: ID!, $comment: String) {
      updateComment(profile_id: $profile_id, comment: $comment) {
        comment_employee
      }
  }
`

export const GET_WORKPLACES = gql`
  query($company_id: ID!) {
    Workplace (
        company_id: $company_id
    ) {
      workplace_id
      title
    }
  }
`

export const UPDATE_ANY_COMPANY = gql`
  mutation updateAnyCompany($profileId: ID!, $companyId: ID!, $date: String!, $workplaceId: ID!, $companyName: String!){
    updateAnyCompany (
      profile_id: $profileId,
      company_id: $companyId,
      date: $date,
      workplace_id: $workplaceId
      company_name: $companyName
    )    
  }
`


export const GET_MANAGER = gql`
  query {
    Manager {
      name
      passport {
        passportSeries
        passportNumber
        passportDate
        passportRecieve
      }
    }
  }
`

export const UPDATE_SWITCH_COMPANY = gql`
  mutation updateSwitchCompany($profileId: ID!, $companyId: ID!, $workplaceId: ID!) {
    updateSwitchCompany(profile_id: $profileId, company_id: $companyId, workplace_id: $workplaceId)
  }
`

// export const UPDATE_ANY_COMPANY = gql`
// mutation updateAnyCompany($profileIds: [ID]!, $companyId: ID!, $comment: String) {
//   updateAnyCompany(profile_id: $profileId, company_id: $companyId, comment: $comment) {
//     profile_id
//     name
//     comment
//   }
// }
// `

export const GET_STAGES = gql`
  query Stages($companyId: Int!){
    Stages(company_id: $companyId) {
      stage_id
      title
      element_index
      only_view
      profile_count
      statuses {
        status_id
        title
        element_index
        profile_count
      }
    }
  }
`

export const CHANGE_RASHOD = gql`
  query ChangeRashod($id: ID!, $status: String!){
    ChangeRashod(id: $id, status: $status) {
      profile_id
    }
  }`


export const GET_PROFILE = gql`
  query Employee_profile($companyId: ID!, $profileId: ID!){
    Employee_profile(company_id: $companyId, profile_id: $profileId) {
      profile_id
      status_id
      firstName
      lastName
      patronymic
      citizenship
      birthplace
      birthDate
      registrationAddress
      stayDate
      stayDateEnd
      comment_employee
      phoneNumber
      job
      jobAddress
      dismissDate
      dismissComment
      contract {
        date
        file
        number
        title
      }
      contractInfo
      passport {
        passportSeries
        passportNumber
        passportDate
        passportRecieve
        passportDateEnd
      }
      polis {
        polisSeries
        polisNumber
        polisRecieve
        polisDateStart
      }
      rvp {
        rvpNumber
        rvpPlace
        rvpDate
        rvpDateEnd
      } 
      vng {
        vngNumber
        vngPlace
        vngDate
        vngDateEnd
      }
      study {
        studyNumber
        studyDate
      }
      patent {
        patentPlace
        patentNumber
        patentSeries
        patentINN
        patentDate
        patentDateEnd
      }
      migrationCard {
        migrationCardNumber
        migrationCardSeries
        migrationCardDate
      }
      files {
        originalname
        file_token
      }
      patent_files {
        originalname
        file_token
      }
      documents{
        originalname
        file_token
      }
      generators {
        file_name
        action
      }
      status_history {
        title
        name
        comment
        date
      }
      rashod
      is_send_to_1c
      send_to_1c_time
      is_mini_form
      other_information
      dogovorExpired
    }
  }
`
export const GET_EXPIRING_PROFILES = gql`
  query Expiring_profiles($companyId: ID!){
    Expiring_profiles(company_id: $companyId) {
      profile_id
      firstName
      lastName
      patronymic
      stayDateEnd
      registrationExpired
      patentExpired
      passportExpired
      dogovorExpired
      patent {
        patentPlace
        patentNumber
        patentSeries
        patentDate
      }
    }
  }
`
export const GET_COUNTRY = gql`
  query GetCountry($name: String!) {
      Country(name: $name) {
        id    
        name
        is_register
        is_migr
        is_rvp
        is_study
        is_vng
        is_patent
        is_polis
        is_other
      }
}
`
export const GET_DUPLICATED_PROFILES = gql`
  query DuplicatedProfiles($profile_id: ID!){
    DuplicatedProfiles(profile_id: $profile_id) {
      profile_id
      company_id
      company_name
      firstName
      lastName
      patronymic
      birthDate
      status_id
      status_title
    }
  }
`
// export const UPDATE_FORMS_DOCS_STATUS = gql`
//   mutation updateFormsDocsStatus($profileId: ID!) {
//     updateFormsDocsStatus(profileId: $profileId)
//   }
// `
export const MERGE_DUPLICATED_PROFILES = gql`
  mutation mergeDuplicatedProfiles($new_profile_id: ID!, $old_profile_id: ID!){
    mergeDuplicatedProfiles(new_profile_id: $new_profile_id, old_profile_id: $old_profile_id)
  }
`
export const GET_EXPIRING_PATENT_PAYMENT = gql`
  query Expiring_patent($companyId: ID!){
    ExpiringPatentPayment(company_id: $companyId) {
      profile_id
      firstName
      lastName
      patronymic
      patentPaymentData
    }
  }
`
export const GET_REVISES = gql`
query Revises($companyId: ID!){
  Revises(company_id: $companyId) {
      sverka_id
      period
      is_open
      closed_by_os
      closed_by_client
      isMain
    }
  }
`

export const GET_REVISE = gql`
query Revise($companyId: ID!, $reviseId: ID!){
  Revise(company_id: $companyId, revise_id: $reviseId) {
      sverka_id
      period
      is_open
      closed_by_os
      closed_by_client
      closeText
      buttons
      profiles {
        profile_id
        name
        rejected
        accept
        comment
        blocked
        hired
        fired
        title
        legal_entity
      }
      summary {
        legal_entity
        profilesAmount
        priceSum
      }
    }
  }
`

export const CLOSE_REVISE = gql`
mutation closeRevise($companyId: ID!, $reviseId: ID!){
  closeRevise(company_id: $companyId, revise_id: $reviseId) {
      sverka_id
      period
      is_open
      closed_by_os
      closed_by_client
      closeText
      buttons
    }
  }
`

export const GET_TT = gql`
query TT($companyId: ID!){
  TT(company_id: $companyId) {
      workplace_id
      title
      legal_entity
    }
  }
`

export const GET_CITIZENSHIP = gql`
query Citizenship($companyId: ID!){
  Citizenship(company_id: $companyId) {
      name
    }
  }
`
export const GET_PROFESSIONS = gql`
query Profession($companyId: ID!){
  Profession(company_id: $companyId) {
      title
    }
  }
`

export const CREATE_SVERKA = gql`
  query CreateSverka($companyId: ID!){
    CreateSverka(company_id: $companyId) {
      page
      }
    }
`

export const CREATE_GLOBAL_SVERKA = gql`
  query CreateGlobalSverka($companyId: ID!){
    CreateGlobalSverka(company_id: $companyId) {
      page
      }
    }
`

export const GET_TRUSTED_MANAGERS = gql`
query TrustedManagers($companyId: ID!){
  TrustedManagers(company_id: $companyId) {
      id
      short_name
    }
  }
`

export const GET_OMVD = gql`
query Omvds($companyId: ID!){
  Omvds(company_id: $companyId) {
      id
      short_name
    }
  }
`

export const UPDATE_REVISE_STATUS = gql`
mutation updateReviseStatus($profileIds: [ID]!, $sverkaId: ID!, $rejected: Boolean!, $companyId: ID!, $comment: String) {
  updateReviseStatus(profile_ids: $profileIds, sverka_id: $sverkaId, rejected: $rejected, company_id: $companyId, comment: $comment) {
    profile_id
    name
    rejected
    accept
    comment
    blocked
  }
}
`
export const ACCEPT_REVISE_CHANGE = gql`
mutation acceptReviseChange($profileId: ID!, $sverkaId: ID!, $companyId: ID!, $comment: String) {
  acceptReviseChange(profile_id: $profileId, sverka_id: $sverkaId, company_id: $companyId, comment: $comment) {
    profile_id
    name
    rejected
    accept
    comment
    blocked
  }
}
`

export const CANCEL_REVISE_CHANGE = gql`
mutation cancelReviseChange($profileId: ID!, $sverkaId: ID!, $companyId: ID! $comment: String) {
  cancelReviseChange(profile_id: $profileId, sverka_id: $sverkaId, company_id: $companyId, comment: $comment) {
    profile_id
    name
    rejected
    accept
    comment
    blocked
  }
}
`

export const GET_PATENT_PAYMENT_DATA = gql`
    query GetPatentPaymentData($profile_id: ID!) {
        PatentPayment(profile_id: $profile_id) {
            data
        }
    }
`

export const UPDATE_PATENT_PAYMENT = gql`
    mutation UpdatePatentPayment(
        $profile_id: ID!
        $data: String!
    ) {
        updatePatentPayment(
            profile_id: $profile_id
            data: $data
        )
    }
`

export const GET_PROFILES = gql`
  query Status($companyId: Int!, $statusId: Int!){
    Status(company_id: $companyId, status_id: $statusId) {
        status_id
        profiles {
          profile_id
          firstName
          lastName
          patronymic
          job
          birthDate
          passport {
            passportNumber
            passportSeries
          }
          status_comment
          status_date
          jobAddress
        }
    }
  }
`

export const GET_HISTORY = gql`
  query Status_history($companyId: ID!, $dateStart: String, $dateEnd: String) {
  Status_history(company_id: $companyId, date_start: $dateStart, date_end: $dateEnd ) {
    status_id
    profile_id
    manager_id
    comment
    title
    firstName
    lastName
    date
    name
  }
}
`
export const SEARCH_PROFILES = gql`
  query Employee_profiles($companyId: ID!, $filter: String!) {
    Employee_profiles(company_id: $companyId, filter: $filter) {
      profile_id
      firstName
      lastName
      patronymic
      passport {
        passportNumber
      }
      company_id
      company_name
    }
  }
`
export const GET_STATUS = gql`
  query Status($companyId: Int!, $statusId: Int!){
    Status(company_id: $companyId, status_id: $statusId) {
        status_id
        title
        traffic_queue {
          previous_statuses {
            status_id
            title
          }
          next_status {
            status_id
            title
          }
          subsequent_statuses {
            status_id
            title
          }
          next_stage {
            stage_id
            title
          }
          back_stage {
            stage_id
            title
          }
        }
    }
  }
`
export const SET_DISMISSAL_QUEUE = gql`
  mutation setDismissalQueue($profileId: ID!, $companyId: ID!, $date: String!, $comment: String){
    setDismissalQueue (
      profile_id: $profileId,
      company_id: $companyId,
      date: $date,
      comment: $comment
    )    
  }
`
export const UPDATE_DISMISSAL_QUEUE = gql`
  mutation updateDismissalQueue($profileId: ID!, $companyId: ID!, $date: String!, $comment: String){
    updateDismissalQueue (
      profile_id: $profileId,
      company_id: $companyId,
      date: $date,
      comment: $comment
    )    
  }
`

export const UPDATE_STATUS_FOR_PROFILE = gql`
  mutation updateStatusForProfiles($profilesId: [ID]!, $companyId: ID!, $newStatus: ID!, $comment: String){
    updateStatusForProfiles(
      profiles_id: $profilesId,
      company_id: $companyId, 
      new_status: $newStatus, 
      comment: $comment
    ) {
      value
      status
      reason {
        err
      }
    }
  }
`
export const UPDATE_PROFILE_TO_NEXT_STAGE = gql`
  mutation updateProfilesToNextStage($profilesId: [ID]!, $companyId: ID!, $comment: String){
    updateProfilesToNextStage(
      profiles_id: $profilesId,
      company_id: $companyId, 
      comment: $comment
    ) {
      value
      status
      reason {
        err
      }
    }
  }
`
export const UPDATE_PROFILE_TO_BACK_STAGE = gql`
  mutation updateProfilesToBackStage($profilesId: [ID]!, $companyId: ID!, $comment: String){
    updateProfilesToBackStage(
      profiles_id: $profilesId,
      company_id: $companyId, 
      comment: $comment
    ) {
      status
      value
      reason {
        err
      }
    }
  }
`
export const UPDATE_PROFILE_INFO = gql`
  mutation updateProfileField($profileId: ID!, $companyId: ID!, $field: String!, $value: String!){
    updateProfileField (
      profile_id: $profileId,
      company_id: $companyId,
      field: $field,
      value: $value
    ) {
        profile_id      
    }
  }
`
export const UPDATE_MANAGER_FIELD = gql`
  mutation UpdateManagerField($field: String!, $value: String!) {
    updateManagerField(field: $field, value: $value) {
      name
    }
  }
`

export const ADD_PROFILE_DOCUMENT = gql`
  mutation addProfileDocument($profileId: ID!, $companyId: ID!, $fileToken: String!){
    addProfileDocument (
      profile_id: $profileId,
      company_id: $companyId,
      file_token: $fileToken,
    )    
  }
`

export const ADD_PROFILE_FILE = gql`
  mutation addProfileFile($profileId: ID!, $companyId: ID!, $fileToken: String!){
    addProfileFile (
      profile_id: $profileId,
      company_id: $companyId,
      file_token: $fileToken,
    )    
  }
`
export const ADD_PROFILE_FILE_PATENT = gql`
  mutation addProfileFilePatent($profileId: ID!, $companyId: ID!, $fileToken: String!){
    addProfileFilePatent (
      profile_id: $profileId,
      company_id: $companyId,
      file_token: $fileToken,
    )    
  }
`
export const REMOVE_PROFILE_FILE = gql`
  mutation removeProfileFile($profileId: ID!, $companyId: ID!, $fileToken: String!){
    removeProfileFile (
      profile_id: $profileId,
      company_id: $companyId,
      file_token: $fileToken,
    )    
  }
`
export const REMOVE_PROFILE_DOCUMENT = gql`
  mutation removeProfileDocument($profileId: ID!, $companyId: ID!, $fileToken: String!){
    removeProfileDocument (
      profile_id: $profileId,
      company_id: $companyId,
      file_token: $fileToken,
    )    
  }
`
export const REMOVE_DISMISSAL_QUEUE = gql`
  mutation removeDismissalQueue($profileId: ID!, $companyId: ID!){
    removeDismissalQueue (
      profile_id: $profileId,
      company_id: $companyId,
    )    
  }
`