import React, { useMemo } from 'react'


export default function SummaryTable({data}) {

  // подсчет кол-ва
  const amountSum = useMemo(() => {
    if (data) {
      return data.reduce((prev, curr) => {
        return prev + curr.profilesAmount
      }, 0)
    }
  }, [data])

  // подсчет суммы
  const moneySum = useMemo(() => {
    if (data) {
      return data.reduce((prev, curr) => {
        return prev + curr.priceSum
      }, 0)
    }
  }, [data])

  return (
    <div className="z-50 flex flex-col w-full rounded-t-lg border-t border-l border-r overflow-hidden">
      <div className="overflow-x-auto rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 ">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ИП</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">Кол-во анкет</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Сумма</th>
              {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center">
                <PaperClipIcon className="mr-1 flex-shrink-0 h-4 w-4 text-gray-400" aria-hidden="true" />
                Файлы
              </th> */}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 overflow-x-auto">
            {data?.map((row, idx) => (
              <tr key={`summary-row-${idx}`}>
                <td className="px-6 py-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="text-sm font-medium text-gray-900">{row.legal_entity ?? "Не выставлена ТТ в профиле"}</div>
                  </div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{row.profilesAmount}</div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    {row.priceSum} ₽
                  </span>
                </td>
                {/* <td className="px-6 py-2 whitespace-nowrap">
                  <div className="w-0">
                    <div className="flex-shrink-0">
                      <a href="##" className="font-medium text-indigo-600 hover:text-indigo-500 hover:underline">
                        Загрузить
                      </a>
                    </div>
                  </div>
                </td> */}
              </tr>
            ))}
            <tr>
              <td className="px-6 py-3 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="text-sm font-medium text-gray-900">ИТОГО:</div>
                </div>
              </td>
              <td className="px-6 py-3 whitespace-nowrap">
                <div className="text-sm text-gray-900">{amountSum}</div>
              </td>
              <td className="px-6 py-3 whitespace-nowrap">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {moneySum} ₽
                </span>
              </td>
              {/* <td className="px-6 py-2">
              </td> */}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
