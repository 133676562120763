import React from "react";
import { Field } from 'formik'

export default function File(props) {
  return (
    <div className=" mt-4 bg-gray-50 shadow-md rounded-md py-5 pl-6 pr-8 sm:pr-6">
      <div className="flex items-center justify-between border-b sm:border-b-0  pb-4 sm:pb-0">
        <div className="flex w-11/12">
          <svg className="w-5 h-5 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
          </svg>
          <div className="text-sm font-medium truncate ml-2">{props.name}</div>
        </div>
        <Field name="undefined">
          {({ form }) => (
            <div onClick={()=>props.onDelete(props.newName, form)} className="ml-auto text-gray-400 hover:text-gray-800 cursor-pointer">
              <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </div>
          )}
        </Field>
      </div>
    </div>
  );
}
