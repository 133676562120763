import { useQuery, useLazyQuery } from "@apollo/client"
import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { useExpanded, useGlobalFilter, useGroupBy, usePagination, useRowSelect, useSortBy, useTable } from "react-table"
import { CHANGE_COMPANY, GET_PROFILES } from "../../graphql/Queries"
import { selectProfilesId, toggleLoader } from "../../redux/manager/managerActions"
import { Loader } from "../Loader"
import TableHeaderCell from "./TableHeaderCell"


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input className="rounded focus:outline-none focus:ring-0 text-gray-700" type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);


function Table({...props}) {
  let history = useHistory()
  const [filterInput, setFilterInput] = useState("")
  let { error, loading, data, refetch } = useQuery(GET_PROFILES, {
    variables: { 
      companyId: +localStorage.getItem('company_id'),
      statusId: +props.currentStatusId
    },
    fetchPolicy: "cache-and-network",
  })
  if (error) console.log(`Error! ${error}`)

  // if (!loading) {
  //   let tableDate
  //   if (data?.Status.profiles) {
  //     tableDate = [...data.Status.profiles]
  //     console.log(tableDate)
  //     data?.Status.profiles.forEach((profile, idx) => {
  //       tableDate[idx].passportNumber = profile.passport.passportNumber
  //       tableDate[idx].passportSeries = profile.passport.passportSeries
  //     })
  //   }
  //   console.log(tableDate)
  // }

  // const handleFilterChange = e => {
  //   const value = e.target.value || undefined;
  //   setAllFilters([value]);
  //   // setFilterInput(value);
  // };
  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilterInput(value)
    setGlobalFilter(value)
  }

  const [value, setValue] = useState(data)

  useEffect(() => {
    refetch()
  }, [props.rerenderTable])

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "",
      //   accessor: "profileBtn",
        // Cell: ({ row }) => (
        //   <button className=" rounded-2xl bg-indigo-400 text-white hover:bg-indigo-600 shadow-lg py-1 px-4 cursor-pointer focus:outline-none" onClick={()=>rowClickHandler(row.original.profile_id)}>
        //     Профиль
        //   </button>
        // )
      // },
      {
        Header: "Фамилия",
        accessor: "lastName",
      },
      {
        Header: "Имя",
        accessor: "firstName",
      },
      {
        Header: "отчество",
        accessor: "patronymic",
      },
      {
        Header: "Должность",
        accessor: "job",
      },
      {
        Header: "Место работы",
        accessor: "jobAddress",
      },
      {
        Header: "Последний статус",
        accessor: "status_date",
      },
      {
        Header: "Комментарий",
        accessor: "profile_id",
      },
      {
        Header: "Комментарий",
        accessor: "status_comment",
      },
    ],
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page, 
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
    } = useTable({ initialState: {pageSize: 15, pageIndex: +window.sessionStorage.getItem('numberPage') || 0, hiddenColumns: ["profile_id"]}, columns, data: useMemo(
      () => data ? data?.Status.profiles : [],
      [data]
    )}, useGlobalFilter, useGroupBy, useSortBy, useExpanded, usePagination, useRowSelect,  (hooks) => {
      if (!props.viewOnly) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                { row?.original?.profile_id && <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />}  
              </div>
            )
          },
          ...columns
        ]);
      }
    })

    useEffect(() => {
      window.sessionStorage.setItem('numberPage', pageIndex);
    }, [pageIndex])

  useEffect(() => {
    let selectedRows = []
    selectedFlatRows.forEach(row => {
      if (row?.original?.profile_id){
        selectedRows.push(row.original.profile_id)
      }
    })
    props.selectProfilesId(selectedRows)
  }, [selectedFlatRows])

  const rowClickHandler = (profileId) => {
    if (profileId) {
      history.push(`/user/${profileId}`)
    }
  }


  const [ chCompany ] = useLazyQuery(CHANGE_COMPANY, {
    variables: { 
      profileId: localStorage.getItem('profile_id'),
      date: localStorage.getItem('date'),
    },
    fetchPolicy: "cache-and-network",
  })

  return (
    <>
    <div className="flex flex-col sm:flex-row justify-between items-center w-full pt-6 pb-4 sm:px-4 rounded-md">
      <p className="sm:ml-3 sm:w-1/2 text-lg leading-6 font-medium text-gray-900">{props.tableTitle}</p>
      <div className="mt-2 sm:mt-0 w-full flex justify-end px-6">
        <div className="w-full sm:w-64 inline-block relative ">
          <input className="leading-snug border border-gray-300 block w-full appearance-none text-sm text-gray-600 py-1 px-4 pl-8 rounded-lg focus:outline-none" 
            value={filterInput}
            onChange={handleFilterChange} 
            placeholder="Поиск по таблице" 
          />
          <div className="pointer-events-none absolute pl-3 inset-y-0 left-0 flex items-center px-2 text-gray-300">
            <svg className="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999">
              <path d="M508.874 478.708L360.142 329.976c28.21-34.827 45.191-79.103 45.191-127.309C405.333 90.917 314.416 0 202.666 0S0 90.917 0 202.667s90.917 202.667 202.667 202.667c48.206 0 92.482-16.982 127.309-45.191l148.732 148.732c4.167 4.165 10.919 4.165 15.086 0l15.081-15.082c4.165-4.166 4.165-10.92-.001-15.085zM202.667 362.667c-88.229 0-160-71.771-160-160s71.771-160 160-160 160 71.771 160 160-71.771 160-160 160z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    
    {loading ? <Loader /> :
      <div className="overflow-y-auto scroll-hide">
        <table {...getTableProps()} className="table-auto border-collapse w-full mb-32 sm:mb-20">
          <thead className="overflow-y-auto">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th 
                    className={`uppercase px-6 py-6 border-b-2 border-gray-300 text-left leading-4 text-gray-700 hover:text-gray-900 tracking-wider relative`}
                    {...column.getHeaderProps()}
                  >
                      <div className="flex items-center">
                        {/* {column.isSorted ? column.isSortedDesc ? "sort-desc" : "sort-asc" : ""} */}
                        <div className={` ${column.isSorted || column.isSortedDesc ? "mr-1" : ""}`}>
                          {column.isSorted ? column.isSortedDesc ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                            </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                            </svg>
                          ) : null}
                        </div>
                        {column.canGroupBy ? (
                          <span>
                            {column.isGrouped ? (
                              <div onClick={()=>column.toggleGroupBy()} className="text-center h-7 w-7 text-red-600 mr-1 cursor-pointer hover:bg-red-600 hover:text-white transition ease-in duration-200 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                              </div>
                            ) : ''}
                          </span>
                        ) : null}
                        <TableHeaderCell {...column}/>
                      </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="overflow-y-auto">
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className="hover:bg-gray-100 cursor-pointer border-b border-gray-300">
                  {row.cells.map(cell => {
                    return <td 
                              onClick={() => {if (typeof(cell.value) === "string") rowClickHandler(row?.original?.profile_id)}} 
                              className="pl-6 h-12"
                              {...cell.getCellProps()}
                            >
                                {cell.isGrouped ? (
                                  <>
                                  <div className="flex items-center">
                                    <span className="mr-1" {...row.getToggleRowExpandedProps()}>
                                      {row.isExpanded ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 groupArrow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                      ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 groupArrow open" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                                        </svg>
                                      )}
                                    </span>
                                    <p>{cell.render('Cell')} ({row.subRows.length})</p>
                                  </div>
                                  </>
                                ) : cell.isAggregated ? (
                                  cell.render('Aggregated')
                                ) : cell.isPlaceholder ? null : (
                                  cell.render('Cell')
                                )}
                            </td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="pagination absolute bottom-1 w-full flex flex-col-reverse sm:flex-row flex-wrap items-center py-4 px-4 sm:pl-8">
            <div className="flex items-center mr-4">
                <button onClick={() => gotoPage(0)} type="button" className="w-full p-3 border text-base rounded-l-xl text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed" disabled={!canPreviousPage}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                    </svg>
                </button>
                <button onClick={() => previousPage()} type="button" className="w-full border-t p-3 border-b border-r font-semibold text-base text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed" disabled={!canPreviousPage}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                  </svg>
                </button>
                {pageOptions.includes(pageIndex - 2) && (
                  <button onClick={() => gotoPage(pageIndex - 2)} type="button" className={`text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                    {pageIndex - 1}
                  </button>
                )}
                {pageOptions.includes(pageIndex - 1) && (
                  <button onClick={() => gotoPage(pageIndex - 1)} type="button" className={`text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                    {pageIndex}
                  </button>
                )}
                <button type="button" className={`text-indigo-500 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                  {pageIndex + 1}
                </button>
                {pageOptions.includes(pageIndex + 1) && (
                  <button onClick={() => gotoPage(pageIndex + 1)} type="button" className={`text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                    {pageIndex + 2}
                  </button>
                )}
                {pageOptions.includes(pageIndex + 2) && (
                  <button onClick={() => gotoPage(pageIndex + 2)} type="button" className={`text-gray-600 w-full px-4 py-2 border-r border-t border-b text-base bg-white hover:bg-gray-100`}>
                    {pageIndex + 3}
                  </button>
                )}
                <button onClick={() => nextPage()} type="button" className="w-full border-t p-3 border-b border-r font-semibold text-base text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed" disabled={!canNextPage}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                  </svg>
                </button>
                <button onClick={() => gotoPage(pageCount - 1)}type="button" className="w-full p-3 border-t border-b border-r text-base rounded-r-xl text-gray-600 bg-white hover:bg-gray-100 disabled:cursor-not-allowed" disabled={!canNextPage} >
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                  </svg>
                </button>
            </div>

          {/* <span className="ml-1 text-sm leading-5 text-gray-700">
            | Перейти:
            <input
              type="number"
              className="ml-1 w-14 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
            />
          </span> */}
          <select
            value={pageSize}
            className="mb-2 sm:mb-0 w-full sm:w-min sm:ml-1 bg-white rounded-xl border border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 leading-8 transition-colors duration-200 ease-in-out"
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[15, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Показывать {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
      }
    </>
  );
}

const mapDispatchToProps = {
  selectProfilesId, toggleLoader
}

const mapStateToProps = state => ({
  currentStatusId: state.manager.currentStatusId,
  rerenderTable: state.manager.rerenderTable,
  viewOnly: state.manager.viewOnly
})

export default connect(mapStateToProps, mapDispatchToProps)(Table)