import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client"
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { setContext } from '@apollo/client/link/context'
import { onError } from "@apollo/client/link/error"
import React from 'react'
import CompanyPage from './pages/CompanyPage'
import Form from './pages/Form'
import Login from './pages/Login'
import Main from './pages/Main'
import Revise from './pages/Revise'
import ReviseTable from "./pages/ReviseTable"
import UserProfile from './pages/UserProfile'
import UserSettings from './pages/UserSettings'
import { ProtectedRoute } from './services/ProtectedRoute'

// обработка ошибок graphql
const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (networkError) {
    if ( networkError.statusCode === 401 ) {
      window.location.replace('/login')
      localStorage.removeItem('token')
      localStorage.removeItem('role_id')
      localStorage.removeItem('company_name')
      localStorage.removeItem('company_logo')
      localStorage.removeItem('company_id')
      localStorage.removeItem('manager_name')
    }
  }
  if (graphqlErrors) {
    graphqlErrors.forEach(({ message }) => {
      alert(`Graphql error ${message}`);
    });
  }
});

const httpLink = from([
  errorLink,
  new HttpLink({ uri: "/graphql" }),
]);

// добавление в хедерсы токена для всех запросов graphql
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// создание главного объекта для graphql
export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <Route exact path='/login' component={Login}/>
          <ProtectedRoute exact path='/' component={Main}/>
          <Route exact path='/form' component={Form}/>
          <ProtectedRoute exact path='/user/:id' component={UserProfile}/>
          <ProtectedRoute exact path='/settings' component={UserSettings}/>
          <ProtectedRoute exact path='/settings/company/:id' component={CompanyPage}/>
          <ProtectedRoute exact path='/revise_table' component={ReviseTable}/>
          <ProtectedRoute exact path='/revise/:id' component={Revise}/>
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
