import { Popover, Transition } from '@headlessui/react'
import React, { useState } from 'react'
import { usePopper } from 'react-popper'
import { Portal } from '../../services/Portal'


export default function TableHeaderCell(column) {
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  })

  // сортировка из react-table
  const sortHandler = (desc) => {
    if (desc) {
      column.toggleSortBy(true)
    } else {
      column.toggleSortBy(false)
    }
    referenceElement.click()
  }

  // очистка сортировки
  const clearSort = () => {
    column.clearSortBy()
    referenceElement.click()
  }

  // группировка из react-table
  const groupByHandler = () => {
    column.toggleGroupBy()
    referenceElement.click()
  }

  if (column.id === "selection") {
    return (
      <div className="uppercase cursor-pointer hover:underline text-left leading-4 text-gray-700 hover:text-gray-900 font-bold text-md tracking-wider">
        {column.render('Header')}
      </div>
    )
  }
  return (
    <Popover>
      <Popover.Button ref={setReferenceElement} className="focus:outline-none">
        <div className="uppercase cursor-pointer hover:underline text-left leading-4 text-gray-700 hover:text-gray-900 font-bold text-md tracking-wider">
          {column.render('Header')}
        </div>
      </Popover.Button>
      <Portal>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          className="absolute z-50 transform"
        >
          <Popover.Panel 
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div className="mt-2 w-56 rounded-md shadow-2xl border bg-white overflow-hidden z-50 uppercase text-left leading-4 text-gray-700 font-bold text-md tracking-wider">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {!column.isSorted || column.isSortedDesc ? (
                <div onClick={()=>sortHandler(false)} className="block px-4 py-2 text-sm font-semibold hover:bg-gray-100 text-gray-900 cursor-pointer" role="menuitem">
                  <span className="flex items-center">
                    <div className="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
                      </svg>
                    </div>
                    <span>
                      Сортировать по возрастанию
                    </span>
                  </span>
                </div>
              ) : ""}
              {!column.isSorted || !column.isSortedDesc ? (
                <div onClick={()=>sortHandler(true)} className="block px-4 py-2 text-sm font-semibold hover:bg-gray-100 text-gray-900 cursor-pointer" role="menuitem">
                  <span className="flex items-center">
                    <div className="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
                      </svg>
                    </div>
                    <span>
                      Сортировать по убыванию
                    </span>
                  </span>
                </div>
              ) : ""}
              {column.isSorted && (
                <div onClick={clearSort} className="block px-4 py-2 text-sm font-semibold hover:bg-gray-100 text-gray-900 cursor-pointer" role="menuitem">
                  <span className="flex items-center">
                    <div className="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                      </svg>
                    </div>
                    <span>
                      Очистить сортировку
                    </span>
                  </span>
                </div>
              )}

                <div onClick={groupByHandler} className="block px-4 py-2 text-sm font-semibold hover:bg-gray-100 text-gray-900 cursor-pointer" role="menuitem">
                  <span className="flex items-center">
                    <div className="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                      </svg>
                    </div>
                    <span>
                      Групировать
                    </span>
                  </span>
                </div>          
                
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Portal>
    </Popover>
  )
}
