import React, { useState } from 'react'
import Header from '../components/Header'
import { GET_MANAGER } from "../graphql/Queries"
import { useQuery } from "@apollo/client"
import InputField from '../components/Profile/InputField'
import { connect } from 'react-redux'
import { Loader } from "../components/Loader"
import WorkerModal from "../components/Settings/WorkerModal"
import WorkerSettingsModal from '../components/Settings/WorkerSettingsModal'
import AddCompanyModal from '../components/Settings/AddCompanyModal'

function UserSettings(props) {
  const [workerModal, setWorkerModal] = useState(false)
  let { data, loading } = useQuery(GET_MANAGER)
  if (data) console.log(data)

  return (
    <>
      <WorkerModal workerModal={workerModal} setWorkerModal={setWorkerModal}/>
      <WorkerSettingsModal />
      <AddCompanyModal />

      <Header />
      <section className="flex flex-col lg:flex-row xl:mx-24 md:mx-12 sm:mx-4 mx-2 ">
        <div className="flex flex-col flex-1">
          <div className="rounded-lg border bg-white shadow-md z-40">
            {loading ? (
              <Loader full />
            ) : (
            <>
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Данные профиля
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Нажмите два раза на поле, чтобы изменить
                </p>
              </div>
              <div className="border-t border-gray-200">
                {data && Object.keys(data.Manager).map((field, idx) => (
                  <dl key={idx}>
                    {field === "passport" ? (
                      Object.keys(data.Manager[field]).map((passportField, idx) => (
                        <>
                          {passportField !== "__typename" && (
                            <div key={`passport-id-${idx}`} className={`${idx % 2 !== 0 ? "bg-gray-50" : "" } px-4 py-5 sm:flex sm:items-center `}>
                              <dt className="text-sm font-medium text-gray-500 sm:flex-1">
                                {props.fields[passportField]}
                              </dt>
                              <InputField field={passportField} data={data.Manager.passport[passportField]} manager={true}/>
                            </div>
                          )}
                        </>
                      ))
                    ) : (
                      <>
                        {field !== "__typename" && field !== "pas" && (
                          <div className={`${idx % 2 === 0 ? "bg-gray-50" : "" } px-4 py-5 sm:flex sm:items-center `}>
                            <dt className="text-sm font-medium text-gray-500 sm:flex-1">
                              {props.fields[field]}
                            </dt>
                            <InputField field={field} data={data.Manager[field]} manager={true}/>
                          </div>
                        )}
                      </>
                    )}
                  </dl>
                ))}
              </div>
            </>
          )}
          </div>
        </div>
        {/* <div className="flex-1 my-4 lg:my-0 lg:ml-4 lg:mb-4">
          <div className="flex-col">
            <div className="px-4 py-5 sm:px-6 w-full border bg-white shadow-md mb-2 rounded-md">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Сотрудники:
              </h3>
            </div>
            <WorkerTable data={people} />
          </div>
          <Companies setWorkerModal={setWorkerModal} />
        </div> */}
      </section>
    </>
  )
}

const mapStateToProps = state => ({
  fields: state.manager.fields
})

export default connect(mapStateToProps, null)(UserSettings)
